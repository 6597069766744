import React from 'react';
import { BracketColumnProps, MatchPairProps } from '../types';
import { useStyles } from "../styles";
import { observer } from 'mobx-react';

export const BracketColumn: React.FC<BracketColumnProps> = ({
    title,
    children,
    columnIndex,
    totalRounds,
    hideHeader = false,
    isSubBracket = false
}) => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex });
    const isMobile = window.innerWidth < 1000;
    const isFinal = columnIndex + 1 === totalRounds;

    const gap = isMobile ? 20 : (isFinal ? 20 : 20 + (1 << (columnIndex)) * 100 - 100);
    const marginTop = isMobile
        ? 50
        : (columnIndex ? 50 + 100 * (columnIndex * (columnIndex - 1) / 2) : 0);

    return (
        <div className={classes.columnWrapper}>
            {!hideHeader && (
                <div className={classes.columnHeader}>
                    {title} {isSubBracket ? '(нижняя сетка)' : ''}
                </div>
            )}
            <div
                className={classes.column}
                style={{
                    gap: `${gap}px`,
                    marginTop: `${marginTop}px`,
                }}
            >
                {React.Children.map(children, (child, matchIndex) => {
                    if (React.isValidElement<MatchPairProps>(child)) {
                        return React.cloneElement(child, {
                            columnIndex,
                            matchIndex,
                            isSubBracket, // признак подсетки
                        });
                    }
                    return child;
                })}
            </div>
        </div>
    );
};

export default observer(BracketColumn);
