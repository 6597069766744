import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    requestsCount: {
        width: 16,
        height: 16,
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
        fontSize: 12,
        lineHeight: '12px',
        fontWeight: 500,
        background: '#CB1C2B',
        marginLeft: 9,
    },
});
