import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    carouselWrapper: {
        width: '100%',
        position: 'relative',
        padding: '0 0 20px 0',
        overflow: 'hidden',
        backgroundColor: '#151719',
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#151719',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#151719',
        },
    },
    carouselViewport: {
        width: '100%',
        position: 'relative',
        overflow: 'visible',
        maxWidth: '1000px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '800px', 
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '600px',
        },
    },
    carouselTrack: {
        display: 'flex',
        transition: 'transform 0.5s ease-in-out',
        gap: 5,
        [theme.breakpoints.down('md')]: {
            gap: 0,
        },
        [theme.breakpoints.down('sm')]: {
            gap: 0,
        },
    },
    activeSlide: {
        transform: 'scale(1)', 
        zIndex: 10,
    },
    neighborSlide: {
        transform: 'scale(0.8)', 
        zIndex: 5,
    },
    inactiveSlide: {
        transform: 'scale(0.6)', 
        zIndex: 1,
    },
    newsCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: 'rgba(245, 245, 245, 0.05)',
        borderRadius: '12px',
        overflow: 'hidden',
    },
    horizontalLayout: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: '#fff',
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    imageWrapper: {
        width: '300px',
        height: '300px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%', 
            height: '250px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%', 
            height: '300px',
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '8px',
    },
    textContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center', 
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: '12px',
        fontFamily: 'Montserrat',
        color: '#F5F5F5',
        [theme.breakpoints.down('md')]: {
            fontSize: 18, 
            textAlign: 'left',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18, 
        },
    },
    smallTitle: {
        fontSize: '24px',
        fontWeight: 600,
        marginBottom: '12px',
        fontFamily: 'Montserrat',
        color: '#F5F5F5',
    },
    description: {
        fontSize: '16px',
        lineHeight: '25.6px',
        marginBottom: '20px',
        fontFamily: 'Montserrat',
        color: '#F5F5F5',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        wordWrap: 'break-word', 
        wordBreak: 'break-word', 
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: 14, 
            lineHeight: '22px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16, 
            lineHeight: '26px',
            textAlign: 'left',
            display: '-webkit-box',
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            WebkitLineClamp: 5, 
            WebkitBoxOrient: 'vertical', 
        },
    },
    readMoreBtn: {
        padding: '12px 16px',
        backgroundColor: '#00BA6C',
        color: '#11141C',
        fontFamily: 'Montserrat',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: 20,
        fontWeight: 700,
        alignSelf: 'flex-start',
        '&:hover': {
            backgroundColor: '#009956',
        },
        [theme.breakpoints.down('md')]: {
            alignSelf: 'stretch',
            order: 2, 
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'stretch',
            width: '100%',
            fontSize: 20,
            order: 2, 
        },
    },
    dateWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: { 
            flexDirection: 'column', 
            alignItems: 'flex-start',
            gap: '10px', 
        },
        [theme.breakpoints.down('sm')]: { 
            flexDirection: 'column', 
            alignItems: 'flex-start',
            gap: '10px', 
        },
    },
    date: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Montserrat',
        color: '#F5F5F5',
        '& span': {
            fontWeight: 700,
            marginLeft: 5,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            marginBottom: '8px', 
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: '8px', 
        },
    },
    slideWrapper: {
        flex: '0 0 90%', 
        position: 'relative',
        transition: 'transform 0.5s ease-in-out',
        [`@media (max-width: 400px)`]: {
            flex: '0 0 100%',
        },
        [`@media (min-width: 401px) and (max-width: 600px)`]: {
            flex: '0 0 60%',
        },
        [`@media (min-width: 601px) and (max-width: 768px)`]: {
            flex: '0 0 50%',
        },
        [`@media (min-width: 769px) and (max-width: 960px)`]: {
            flex: '0 0 90%',
        },
        [`@media (min-width: 961px) and (max-width: 1200px)`]: {
            flex: '0 0 83%',
        },
        [`@media (min-width: 1201px) and (max-width: 1440px)`]: {
            flex: '0 0 90%',
        },
        [`@media (min-width: 1441px)`]: {
            flex: '0 0 95%',
            margin: '0 -30px', // Отрицательный отступ для компенсации эффекта scale
        },
    },
    activeCard: {
        '&:before': {
            opacity: 0,
        },
    },
    cardContent: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: 15,
        gap: 15,
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '70%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9))',
        zIndex: 2,
    },
    dots: {
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
    },
    dot: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        [theme.breakpoints.down('md')]: {
            width: 12,
            height: 12,
        },
        [theme.breakpoints.down('sm')]: {
            width: 12,
            height: 12,
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
        gap: '1vw',
    },
    arrowButton: {
        background: 'transparent',
        border: 'none',
        '& img': {
            width: 35,
            '&:hover': {
                transform: 'scale(1.1)',
            },
            '&:active': {
                transform: 'scale(0.95)', 
            },
        },
        
    },

    activeDot: {
        backgroundColor: '#fff',
    },
    navigationButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 20,
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
    },
    prevButton: {
        left: 0,
    },
    nextButton: {
        right: 0,
    },
    navigationIcon: {
        width: '24px',
        height: '24px',
        color: '#fff',
    },

    footer: {
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'end',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
    },

    //Одна новость 
    singleNewsViewport: {
        overflow: 'visible',
        maxWidth: '1200px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    },

    singleNewsTrack: {
        transform: 'none !important',
        transition: 'none',
        gap: 0,
    },

    singleNewsSlide: {
        flex: '0 0 100%',
        transform: 'none !important',
        margin: 0,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
        },
    },
}));