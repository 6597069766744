import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

interface MatchPairProps {
    matchIndex: number;
    marginTop: number;
    columnIndex: number;
    isBye?: boolean;
    isFinalColumn?: boolean;
}

export const useStyles = makeStyles(() => ({
    root: {
        minHeight: 630,
        width: "100%",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("sm")]: {
            height: "100vh",
            minHeight: "100vh",
            overflow: "hidden",
        },
    },

    previewTourBox: {
        display: "none",
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: 0,
            right: 0,
            width: "fit-content",
            height: "calc(100dvh - 100px)",

            background: "rgba(245,245,245,0.1)",
            backdropFilter: "blur(4px)",
            borderRadius: "12px",
            padding: "10px",
            border: "1px solid rgba(255,255,255,0.3)",
            transform: "translateX(300%)",
            transition: "ease-in 0.3s all",
            zIndex: 2,
            maxHeight: "1000px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.up(2160)]: {
                maxHeight: "1600px",
            },
            "&.active": {
                transform: "translateX(0%)",
                transition: "ease-in 0.5s all",
            },
        },
    },

    gamePreviewContainer: {
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "block",
            // position: "absolute",
            // top: 0,
            // right: 0,
            height: "inherit",
            aspectRatio: "9/16",

            borderRadius: "12px",
            padding: "10px",

            // transform: "translateX(150%)",
            // transition: "ease-in 0.3s all",
            zIndex: 2,
            maxHeight: "1000px",
            containerType: "size",
            [theme.breakpoints.up(2160)]: {
                maxHeight: "1600px",
            },
            "@media (max-height: 700px)": {
                aspectRatio: "10/14",
            },
            // "&.active": {
            //     transform: "translateX(0%)",
            //     transition: "ease-in 0.5s all",
            // },
        },
    },

    backgroundImage: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    },
    gradientOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        //    background: 'linear-gradient(180deg, rgba(5, 5, 5, 0.25) 0%, rgba(5, 5, 5, 0.98) 42%, #050505 88.12%)',
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 0",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    backButton: {
        background: "none",
        border: "none",
        color: "white",
        cursor: "pointer",
        fontSize: 20,
        display: "flex",
        alignItems: "center",
    },
    titleContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        padding: 14,
        color: "rgba(245, 245, 245, 1)",
        fontFamily: "Montserrat",
        gap: 8,
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontSize: 30,
        fontWeight: 700,
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
        },
    },
    gradientSpan: {
        background:
            "linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)",
        "-webkit-background-clip": "text",
        "background-clip": "text",
        color: "transparent",
        fontWeight: 700,
        marginLeft: 10,
    },
    coin: {
        width: 24,
        height: 24,
        marginLeft: 20,
    },
    titleText: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
    },

    dropdownWrapper: {
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
    },
    arrowDown: {
        width: 16,
        height: 16,
        marginLeft: 10,
        cursor: "pointer",
        transition: "transform 0.3s ease",
    },
    arrowDownRotated: {
        transform: "rotate(180deg)",
    },
    dropdownContent: {
        position: "absolute",
        top: "calc(100% + 10px)",
        right: 0,
        backgroundColor: "rgba(245, 245, 245, 0.1)",
        backdropFilter: "blur(32px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        zIndex: 2000,
        padding: "0 16px",
        fontSize: 16,
        fontWeight: 500,
        width: 270,
        color: "rgba(245, 245, 245, 1)",
        maxHeight: 292,
        overflowY: "scroll",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "& p": {
            padding: "16px 0",
            borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
            "&:last-child": {
                borderBottom: "none",
            },
        },
        [theme.breakpoints.down("sm")]: {
            transform: "translate(30%, 0)",
        },
    },
    placeWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    placeRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        "&:last-child": {
            borderBottom: "none",
        },
    },
    placeText: {
        fontSize: 16,
        color: "rgba(245, 245, 245, 1)",
        fontWeight: 500,
    },
    rewardText: {
        display: "flex",
        alignItems: "center",
        gap: 5,
    },
    coinIcon: {
        width: "20px",
        height: "20px",
    },
    gradientText: {
        background:
            "linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)",
        "-webkit-background-clip": "text",
        "background-clip": "text",
        color: "transparent",
        fontWeight: 700,
    },
    regularText: {
        fontSize: "14px",
        color: "#fff",
    },
    dropdownItem: {
        padding: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
    },
    dropdownItemText: {
        marginLeft: "10px",
    },

    noPrizesText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1vw",
    },

    arrowRight: {
        marginRight: "1vw",
        width: 10,
        [theme.breakpoints.down("sm")]: {
            marginRight: "3vw",
            width: 6,
        },
    },
    dateInfo: {
        fontSize: 15,
        opacity: 0.8,
        "& span": {
            fontWeight: 700,
            margin: "0 0.5vw",
        },
    },
    content: {
        position: "relative",
        zIndex: 1,
        padding: "1vw",
        color: "white",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            height: "100vh",
            overflow: "hidden",
        },
    },
    rounds: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "2vw",
        "& span": {
            fontSize: "1rem",
        },
    },
    bracket: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: 1,
        height: "calc(100vh - 270px)",
        overflowY: "auto",
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        position: "relative",
        transform: "translateZ(0)",
        isolation: "isolate", // новый контекст наложения
        backgroundColor: "transparent",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        minWidth: "150px",
        justifyContent: "flex-start",
        position: "relative",
        zIndex: 2,
        gap: 0,
        marginTop: 0,
        "&:not(:last-child)": {
            paddingRight: "40px",
        },
        [theme.breakpoints.down("sm")]: {
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "0 16px",
            WebkitOverflowScrolling: "touch",
            height: "calc(100vh - 230px)",
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            marginTop: 0,
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    },
    connectingLines: {
        top: 0,
        bottom: 0,
        right: "-8%",
        width: "16%",
        pointerEvents: "none",
    },
    line: {
        borderRight: "2px solid rgba(255, 255, 255, 0.3)",
        borderTop: "2px solid rgba(255, 255, 255, 0.3)",
        borderBottom: "2px solid rgba(255, 255, 255, 0.3)",
        width: "100%",
    },
    columnWrapper: {
        display: "flex",
        flexDirection: "column",
        minWidth: "250px",
        height: "100%",
        position: "relative",
        marginRight: 60,
        // paddingTop: 40,
        zIndex: 2,
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            width: "100%",
            flex: "0 0 100%",
            scrollSnapAlign: "start",
            scrollSnapStop: "always",
            overflow: "hidden",
            marginTop: 0,
        },
    },
    columnHeader: {
        padding: "1vw 0.5vw",
        fontWeight: 300,
        fontSize: 12,
        textAlign: "center",
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // width: '100%',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            padding: "10px 16px",
            textAlign: "center",
            position: "sticky",
            top: 0,
            zIndex: 2,
            flexShrink: 0,
        },
    },
    matchesContainer: {
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "flex-start",
        [theme.breakpoints.down("sm")]: {
            flex: 1,
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "0 16px",
            WebkitOverflowScrolling: "touch",
            height: "calc(100vh - 230px)",
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    },
    singleMatch: {
        marginBottom: "0",
    },
    matchLabel: {
        fontSize: "16px",
        fontWeight: "bold",
        background:
            "linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "background-clip": "text",
        color: "transparent",
        "font-weight": "bold",
        display: "inline-block",
        marginBottom: 5,
        textAlign: "center",
    },

    matchPairContent: {
        position: "relative",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    matchPair: (props: MatchPairProps) => ({
        position: "relative",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: props.isBye ? "20px" : "0px",
        zIndex: 0,
        backdropFilter: "blur(4px)",
        "&:not(:last-child)": {
            marginBottom: props.isBye ? "20px" : "15px",
        },
        [theme.breakpoints.down("sm")]: {
            borderRadius: "4px",
            marginBottom: "8px",
            flexShrink: 0,
        },
    }),

    matchButton: (props: MatchPairProps) => ({
        position: "absolute",
        top: "50%",
        right: "0",
        borderRadius: "4px",
        border: "none",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
        opacity: 1,
        transition: "opacity 0.2s ease",
        zIndex: 10,
        transform: "translate(0%, -50%)",
        [theme.breakpoints.down("sm")]: {
            textAlign: 'right',
        },
    }),

    playButton: {
        backgroundColor: "transparent",
    },

    viewButton: {
        backgroundColor: "transparent",
    },

    viewIcon: {
        width: "20px",
        height: "20px",
        display: "flex",

        "@media (max-height: 992px)": {
            display: "inline-flex",
            marginRight: 3,
            marginTop: 2,
        },
    },

    player: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 12px",
        minHeight: "40px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        transition: "all 0.2s ease",
        backgroundColor: "rgba(32, 32, 32, 0.9)",
        borderRight: "4px solid transparent",

        "&:last-child": {
            borderBottom: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
        },
        "&:first-child": {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        [theme.breakpoints.down("sm")]: {
            padding: "8px 12px",
            flexShrink: 0,
            minHeight: 40,
        },
    },

    playerTop: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    playerBottom: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderBottom: "none",
    },

    playerInfo: {
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        fontWeight: 600,
        color: "rgba(245, 245, 245, 0.6)",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            width: '100%',
        },
    },
    tbdInfo: {
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    avatar: {
        width: 22,
        height: 22,
        borderRadius: "50%",
        marginRight: 10,
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
            width: 20,
            height: 20,
        },
    },
    winner: {
        borderRight: "4px solid #4CAF50",
        color: "rgba(245, 245, 245, 1)",
        fontWeight: 600,
        borderTopRightRadius: 4,
        "&:last-child": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 4,
        },
        "& $score": {
            color: "#4CAF50",
            marginLeft: "1vw",
        },
    },
    winnerName: {
        color: "#FFFFFF",
        fontWeight: 700,
    },
    loserName: {
        color: "#808080",
    },
    winnerScore: {
        color: "#FFFFFF",
        fontWeight: 700,
    },
    loserScore: {
        color: "#808080",
    },
    nameAndScore: {},
    playerScore: {},
    score: {
        fontWeight: 600,
        marginLeft: "1vw",
        fontSize: 12,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            marginLeft: "10px",
        },
    },
    connectingLine: {
        position: "absolute",
        right: "-30px",
        top: "50%",
        width: "30px",
        height: "2px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        transform: "translateY(-50%)",
    },
    errorContainer: {
        color: "red",
    },

    //Carousel styles
    carouselContainer: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            position: "relative",
            overflow: "hidden",
            marginTop: "10px",
        },
    },

    bracketCarousel: {
        display: "flex",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        width: "100%",
        padding: "0",
        gap: "20px",
        marginTop: "2vw",
        height: "calc(100vh - 80px)",
        "-webkit-overflow-scrolling": "touch",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            overflowX: "auto",
            overflowY: "hidden",
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            width: "100%",
            height: "calc(100vh - 200px)",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    },

    carouselButton: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        background: "rgba(0, 0, 0, 0.5)",
        border: "none",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        cursor: "pointer",
        zIndex: 1000,
        [theme.breakpoints.down("sm")]: {
            top: 0,
            display: "flex",
            marginTop: "6vw",
        },
        "&:first-of-type": {
            left: 10,
        },
        "&:last-of-type": {
            right: 10,
        },
    },
    bracketContainer: {
        position: 'relative',
        height: '100%'
    },

    bracketWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },

    fixedHeaders: {
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'sticky',
        top: 0,
        zIndex: 1
    },

    headerColumn: {
        minWidth: '250px',
        marginRight: '60px',
        textAlign: 'center',
        padding: '1vw 0.5vw',
        fontSize: '14px',
    },

    bracketContent: {
        overflow: 'auto'
    },
    carouselButtonLeft: {
        position: 'absolute',
        top: "5%",
        left: 0,           
        transform: "translateY(-50%)",
        background: "rgba(0, 0, 0, 0.5)",
        border: "none",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        cursor: "pointer",
        zIndex: 1000,
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            top: "6vw",
        },
        "& img": {
            width: 30,
            height: 30,
        },
    },

    carouselButtonRight: {
        position: 'absolute',
        top: "5%",
        right: 0,          
        transform: "translateY(-50%)",
        background: "rgba(0, 0, 0, 0.5)",
        border: "none",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        cursor: "pointer",
        zIndex: 1000,
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            top: "6vw",
        },
        "& img": {
            width: 30,
            height: 30,
        },
    },

    //Mobile styles
    headerMobile: {},

    titleMobile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 15,
        fontSize: 24,
        fontWeight: 500,
        textAlign: "center",
        gap: 10,
    },

    prizeInfo: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        marginTop: 4,
    },

    prizeInfoMobile: {
        display: "flex",
        alignItems: "center",
    },

    backButtonMobile: {
        background: "none",
        border: "none",
        color: "white",
        cursor: "pointer",
        fontSize: 16,
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        marginTop: "1vw",
    },

    dateInfoMobile: {
        fontSize: 13,
        opacity: 0.8,
        textAlign: "center",
        marginTop: 8,
        "& span": {
            fontWeight: 700,
            margin: "0",
        },
    },

    //Подсетка
    subBracketWrapper: {
        marginTop: '40px',
        paddingTop: '20px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    },

    subBracketTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#fff',
        marginBottom: '20px',
        padding: '0 20px',
    }
}));
