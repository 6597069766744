import {makeStyles} from '@mui/styles';
import theme from 'src/theme';

import shadowPawn from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowPawn.svg';
import shadowBishop from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowBishop.svg';
import shadowKing from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowKing.svg';
import shadowQween from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowQween.svg';
import shadowRook from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowRook.svg';
import shadowKnight from 'src/assets/icons/chessboardPage/shadowChesboardPieces/shadowKnight.svg';

import {squareShineColors} from 'src/consts/app-config';
const {BACKLIGHT_DEEPBLACK_COLOR} = squareShineColors;

export const useStyles = makeStyles({
    '@global': {
        '@keyframes availableStart': {
            '0%': {
                boxShadow: `0px 0px 0cqh 0cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            },
            '50%': {
                boxShadow: `0px 0px 1cqh 0.5cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            },
            '100%': {
                boxShadow: `0px 0px 0cqh 0cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            },
        },
        '@keyframes isFigure': {
            '0%': {
                borderRadius: '0%',
                opacity: 0.3,
                border: `0px solid ${BACKLIGHT_DEEPBLACK_COLOR}`,
            },
            '50%': {
                borderRadius: '30%',
                opacity: 1,
                border: `10cqh solid ${BACKLIGHT_DEEPBLACK_COLOR}`,
            },
            '100%': {
                borderRadius: '0%',
                opacity: 0.3,
                border: `0px solid ${BACKLIGHT_DEEPBLACK_COLOR}`,
            },
        },
    },

    cellActive: {
        zIndex: 200,
    },
    cell: {
        width: '12.5%',
        height: '100%',
        position: 'relative',
        // fontSize: 24,
        containerType: 'size',
        '&.dark': {
            backgroundColor: '#B88762',
        },
        '&.light': {
            backgroundColor: '#EDD6B0',
        },
        '&.availableStart': {
            '-webkit-box-shadow': `0px 0px 1cqh 0.5cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            '-moz-box-shadow': `0px 0px 1cqh 0.5cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            boxShadow: `0px 0px 1cqh 0.5cqh ${BACKLIGHT_DEEPBLACK_COLOR} inset`,
            // animationName: "availableStart",
            animationIterationCount: 'infinite',
            animationDuration: '2000ms',
        },
        '&.picked': {
            // backgroundColor: 'rgb(0, 186, 108, 1)',
            backgroundColor: BACKLIGHT_DEEPBLACK_COLOR,
            transition: 'all 0.2s ease-in',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
        '&.runHover': {
            border: `4px solid ${BACKLIGHT_DEEPBLACK_COLOR}`,
        },
    },

    availableEndCircle: {
        display: 'block',
        opacity: '0',
        position: 'absolute',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        height: '40%',
        width: '40%',
        zIndex: -1,
        // marginTop: '-1vw',
        // marginLeft: '-1vw',
        // backgroundColor: 'rgb(0, 186, 108, 1)',
        transform: 'translate(-50%, -50%)',
        '&.show': {
            zIndex: 0,
            display: 'block',
            opacity: 1,
            transition: 'all 0.3s ease-in',
            backgroundColor: BACKLIGHT_DEEPBLACK_COLOR,
        },
        '&.isFigure': {
            zIndex: 0,
            display: 'block',
            height: '100%',
            width: '100%',
            borderRadius: '0%',
            background: 'none',
            opacity: 0.3,
            border: `0px solid ${BACKLIGHT_DEEPBLACK_COLOR}`,
            animationName: 'isFigure',
            animationIterationCount: 'infinite',
            animationFillMode: 'forwards',
            animationDuration: '2000ms',
        },
        '&.showShadowPiece': {
            opacity: 1,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '85% 85%',
            backgroundPosition: 'center center',
            width: '100%',
            height: '100%',
            zIndex: 1,
            '&.p': {
                opacity: 1,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '85% 85%',
                backgroundPosition: 'center center',
                backgroundImage: `url(${shadowPawn})`,
            },
            '&.r': {
                backgroundImage: `url(${shadowRook})`,
            },
            '&.h': {
                backgroundImage: `url(${shadowKnight})`,
            },
            '&.b': {
                backgroundImage: `url(${shadowBishop})`,
            },
            '&.k': {
                backgroundImage: `url(${shadowKing})`,
            },
            '&.q': {
                backgroundImage: `url(${shadowQween})`,
            },
        },
    },
    lastMove: {
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    keyNotation: {
        position: 'absolute',
        left: '0px',
        top: '0px',
        fontWeight: 600,
        fontSize: '20cqh',
        '&.dark': {
            color: '#EDD6B0',
        },
        '&.light': {
            color: '#B88762',
        },
    },
    figure: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
    figureMove: {
        padding: '50vw',
        marginTop: '-50vw',
        marginLeft: '-50vw',
        boxSizing: 'content-box',
        zIndex: 200,
    },
    labelNotation: {
        position: 'absolute',
        right: '0.5px',
        bottom: '0px',
        fontWeight: 600,
        fontSize: '20cqh',
        '&.dark': {
            color: '#EDD6B0',
        },
        '&.light': {
            color: '#B88762',
        },
    },
    row: {
        display: 'flex',
        width: '100%',
        height: '12.5%',
        flexGrow: 1,
    },
    table: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});
