import axios from 'axios';

import endpoints from 'src/core/endpoints';
import { IGetNewsRequest, IGetNewsResponse, INewsItem } from './requestResponses';

export const getNews = (params: IGetNewsRequest) => {
    return axios.get<IGetNewsResponse>(endpoints.getNews, { params });
};

export const getNewsById = (id: string) => {
    const url = endpoints.getNewsById.replace(':id', id);
    return axios.get<INewsItem>(url);
};