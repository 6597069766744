import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import cn from 'classnames';

import { useLocale } from 'src/providers/LocaleProvider';

import { useModalStyles } from '../styles';

import ItemAvatar from './ItemAvatar';
import { useStyles } from './styles';
import { ShowHideUpload, IItemAvatarContainer, Avatars } from './avatarTypes';
import { MainActionButton } from "src/components/buttons/newDesignButtons/mainActionButton";
import { getAvatarList } from '../../../service/api/profile';

function ItemAvatarContainer({
    nonGrid,
    isCustom,
    getAvatarId,
    choosenAvatarId,
    handleUpdateAvatar,
    showUpload,
}: IItemAvatarContainer) {
    const localStyles = useStyles();
    const {
        profile: {
            changeAvatar: { changeAvatarTitle, uploadAvatarTitle },
        },
    } = useLocale();
    const [avatars, setAvatars] = useState<Avatars[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAvatarId, setSelectedAvatarId] = useState<string | null>(null);

    useEffect(() => {
        const fetchAvatars = async () => {
            try {
                setIsLoading(true);
                const response = await getAvatarList();
                setAvatars(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching avatars:', err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAvatars();
    }, []);

    useEffect(() => {
        if (typeof choosenAvatarId === 'string') {
            setSelectedAvatarId(choosenAvatarId);
        }
    }, [choosenAvatarId]);

    const handleAvatarClick = (avatarId: string) => {
        setSelectedAvatarId(avatarId);
        getAvatarId(avatarId);
    };

    return (
        <Box className={cn(localStyles.flexBlockWrapper, { nonGrid })}>
            <Box className={localStyles.flexContainer}>
                {avatars.map((item) => (
                    <ItemAvatar
                        key={item._id}
                        item={{
                            _id: item._id,
                            url: item.url
                        }}
                        isSelected={selectedAvatarId === item._id}
                        onSelect={handleAvatarClick}
                    />
                ))}
            </Box>
            {avatars.length > 0 && (
                <Box className={localStyles.buttonBoxLoaded}>
                    <MainActionButton
                        appearanceTheme="primary"
                        text={changeAvatarTitle('Change Avatar')}
                        className={localStyles.changeButton}
                        onClick={() => {
                            if (selectedAvatarId) {
                                handleUpdateAvatar(selectedAvatarId);
                            }
                        }}
                    />
                    <MainActionButton
                        appearanceTheme="primary"
                        text={uploadAvatarTitle('Upload from Gallery')}
                        className={localStyles.uploadButton}
                        onClick={() => showUpload(ShowHideUpload.show)}
                    />
                </Box>
            )}
        </Box>
    );
}

export default ItemAvatarContainer;