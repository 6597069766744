import cn from "classnames";

import { useStyles } from "./styles";
interface IAvatarProps {
    src: string;

    wrapperClassName?: string;

    width?: number;
    height?: number;
}

export const Avatar = ({
    src,
    wrapperClassName,
    width,
    height,
}: IAvatarProps) => {
    const classes = useStyles();
    return (
        <div
            className={cn(classes.wrapper, wrapperClassName, {
                [classes.defaultWrapper]: !wrapperClassName,
            })}
            style={{
                width,
                height,
            }}
        >
            <img src={src} className={classes.image} />
        </div>
    );
};
