import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';
import { media } from 'src/utils/media';
import { React_Brackets_WIDTH_CONDITION } from 'src/consts/window-size-params';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(1920)]: {
            width: '100%',
        },
        position: 'relative',
        animationName: '$friendsWrapper',
        animationDelay: '0s',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '800ms',
    },

    '@keyframes friendsWrapper': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },

    title: {
        fontSize: 24,
        fontWeight: 700,
        color: appColors.white,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.info': {
            fontSize: '18px',
            fontWeight: 400,
            color: appColors.white,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.6,
            transition: 'all 0.5s',
            cursor: 'pointer',
            '&:hover': {
                transition: 'all 0.5s',
                cursor: 'pointer',
                opacity: 1,
            },
        },
    },
    waitingForOpponent: {
        position: 'absolute',
        left: '50%',
        bottom: 0,
    },

    returnToBox: {
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            width: 'fit-content',
            position: 'absolute',
            left: '0%',
            bottom: '30px',
            transform: 'translate(0%, 0%)',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&.isMobileFriends': {
                position: 'static',
                background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                margin: 0,
                fontSize: '16px',
                height: 17,
                fontWeight: 600,
            },
        },
    },
});
