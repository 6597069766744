import { action, makeAutoObservable, computed, reaction, runInAction, observable, toJS } from "mobx";
import moment from "moment";
import _keyBy from "lodash/keyBy";

import {
    ISeedBracket,
    ITournamentModalInfo,
    GameStatus,
    ISeedBracketMobile,
    StageClass,
    ITournaments,
    ITopPlayers,
    ITournamentBracket,
    IPrizeTournamentData,
    IGame,
    ITournament,
    ETournamentStatus,
    ITournamentsNew
} from "src/store/models";
import { useLocale } from "src/providers/LocaleProvider";
import { roundIndex } from "src/consts/tournamentsRound";
import { bracketPlayerTranslate } from "src/utils/bracketPlayerTranslate";
import { rootStore } from "src/hooks/useStores";
import {
    getTournaments,
    getCurrentTournament,
    createTournaments,
    deleteAllTournaments,
    joinTournaments,
    cancelJoinTournaments,
} from "src/service/api/tournaments";
import {
    ITournamentCreateRequest,
    ITournamentJoinRequest,
    TournamentAlertType,
} from "src/service/api/tournaments/requestResponses";
import { LocaleHelper } from "src/shared/helpers/LocaleHelper";
import { emitter } from "src/shared/emitter";
import { ETournamentType, IDetailedTournament } from "src/entities/tournament";
import { Nullable } from "src/shared/types";

import { ITournamentAlertsState } from "./gameStore/types";

import { RootStore } from "./index";
import { AxiosResponse } from "axios";
import { getTimeAndIncrementFilter } from "./libs";
import { Socket } from "socket.io-client";

export enum SortOrder {
    desc = 0,
    asc = 1,
}

interface ISortOrderFilters {
    typeGameSortOrder: SortOrder;
    prizeGameSortOrder: SortOrder;
    playerGameSortOrder: SortOrder;
    buyInGameSortOrder: SortOrder;
    timeGameSortOrder: SortOrder;
}

export enum SortTournamentsType {
    type = "type",
    player = "player",
    prize = "prize",
    buyIn = "buyIn",
    time = "time",
}

export enum GameTime {
    "3m" = "3 + 3",
    "5m" = "5 + 5",
    "10m" = "10 + 0",
}

export enum GameType {
    classic = "classic",
    doubling = "doubling",
}

export enum FilterTournamentsForms {
    type = "type",
    player = "player",
    prize = "prize",
    buyIn = "buyIn",
}

interface ITournamentFilters {
    time: { [key: string]: boolean };
    mode: { [key: string]: boolean };
    type: { [key: string]: boolean };
}

export interface ILocaleError {
    defaultValue: string;
    label: string;
}

const calcStages = (prop) => {
    let sizeArray = Object.keys(prop)
        .map(Number)
        .filter((item) => item > 0)
        .reverse();
    return sizeArray;
};

export class TournamentsStore {
    rootStore: RootStore;

    myGames: { [tournamentId: string]: IGame } = {};
    //selectedTournament: Nullable<IDetailedTournament> = null;

    // TODO: remove null and replace it with empty array, we will get rid of multiple conditions
    //tournaments: Array<ITournaments> | null = null;
    tournaments: Array<ITournament> = [];
    isLoaded: boolean = false;
    currentTournamentGame: IGame | null = null;
    cleanRenderBracket: {
        backetId: string;
        bracketArray: Array<ISeedBracketMobile>;
        stagesArray: Array<number>;
        stagesValue: string | undefined;
    };
    errorOccured: boolean = false;
    sortOrderType: SortTournamentsType = SortTournamentsType.time;
    sortOrderFilters: ISortOrderFilters = {
        playerGameSortOrder: SortOrder.desc,
        prizeGameSortOrder: SortOrder.desc,
        buyInGameSortOrder: SortOrder.desc,
        typeGameSortOrder: SortOrder.desc,
        timeGameSortOrder: SortOrder.desc,
    };
    tournamentBracket: ITournamentBracket | null = null;
    tournamentRenderBracket: Array<ISeedBracket> | null = null;
    // TODO: fix typo
    bracketScoreUpdateIvent: string | number | null = null;
    tournamentInfoMessages: {
        prepareToJackpot: boolean;
        prepareThirdOrFourth: boolean;
    };
    tournamentModalId: string | null = null;
    tournamentModalInfo: ITournamentModalInfo | null = null;
    tournamentWinnerAlert: IPrizeTournamentData | null = null;
    tournamnetSeedsLocal: any;
    tournamentAlertsState: ITournamentAlertsState = {
        quotesModalState: true,
    };
    isHideChat: boolean;

    selectedTournament: ITournament | null = null;
    selectedTournamentBracket: ITournamentBracket | null = null;
    //
    allTournamentsList: ITournamentsNew[];
    finishedAlertTournamentGame: {
      finishedTourId: string,
      time: number
    }[] = []

    @observable
    tournamentFilters: ITournamentFilters = this.getDefaultFilters();

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.resetTournamentFilters(); 
        reaction(
            () => this?.tournamentModalInfo?.participating,
            (now, prev) => {
            //    this.getTournaments();
            }
        );

        reaction(
            () => [
                this.tournamentBracket,
                this.bracketScoreUpdateIvent,
                this.rootStore.authStore.selectedLanguage,
            ],
            (
                [tournamentBracket, bracketScoreUpdateIvent, selectedLanguage],
                [
                    prevtournamentBracket,
                    prevbracketScoreUpdateIvent,
                    prevselectedLanguage,
                ]
            ) => {
                if (tournamentBracket) {
                    this.createTryBracket();
                }
            }
        );

        reaction(
            () => this.tournamentBracket,
            (now, prev) => {
                if (now && now !== prev) {
                    this.handleBracketMobileStages(calcStages(now.stages));
                }
            }
        );
        reaction(
            () => this.tournamnetSeedsLocal,
            (now, prev) => {
                if (now) {
                    this.handleBracketMobileArray(
                        this.tournamentBracket?.id,
                        this.tournamnetSeedsLocal
                    );
                }
            }
        );
        reaction(
            () => this.rootStore.authStore.isVisibleWebApp,
            (now, prev) => {
                // const isTournamentPage = location.pathname.includes(
                //     `${paths.tournaments}`
                // );
                // if (
                //     !prev &&
                //     now &&
                //     this.currentTournamentGame &&
                //     isTournamentPage
                // ) {
                //     window.location.reload();
                // }
            }
        );

    }

    @observable socket: Socket | null = null;

    @action
    setSocket(socket: Socket | null) {
        this.socket = socket;
    }

    //-----------
    @action
    handleTournamentsFromLobby(rawTournaments: any[]) {
        if (!Array.isArray(rawTournaments)) {
            this.tournaments = [];
            return;
        }

        try {
            this.setLoadedState(false);
            const mappedTournaments: ITournament[] = rawTournaments.map(tournament => ({
                ...tournament,
            }));

            runInAction(() => {
                this.tournaments = mappedTournaments;
            });

        } catch (error) {
            console.error('Error processing tournaments:', error);
            this.tournaments = [];
        } finally {
            this.setLoadedState(true);
        }
    }
    @action
    setAllTournaments(tournaments: ITournamentsNew[]) {
        this.allTournamentsList = tournaments;
    }

  @action
  setFinishedGameForAlert(tournament: string, delayTime) {  
      const isExistTour =  this.finishedAlertTournamentGame.length && 
        this.finishedAlertTournamentGame.some(item => 
        item.finishedTourId === tournament)

      // console.debug("isExistTour", isExistTour)
      const existTimeUpdated = [...this.finishedAlertTournamentGame].map((item) => {
          if (item.finishedTourId === tournament) {
            return ({
              finishedTourId: tournament,
              time: Date.now() + delayTime
            })
          }
          return item
      })
      const newTour = [...this.finishedAlertTournamentGame,
            {
                finishedTourId: tournament,
                time: Date.now() + delayTime
            },
        ];

        // console.debug("isExistTour", !!isExistTour, "existTimeUpdated:", existTimeUpdated, "newTour", newTour)
      this.finishedAlertTournamentGame = !!isExistTour ? existTimeUpdated : newTour
  }

  @action
  resetFinishedGameForAlert(tournament: string | null) {
    // console.debug("resetFinishedGameForAlert")
    if (!tournament) return this.finishedAlertTournamentGame = [];
    const filtredRest = [...this.finishedAlertTournamentGame]
    .filter(item => item.finishedTourId !== tournament )
    this.finishedAlertTournamentGame = filtredRest
  }

    @action
    updateTournamentPlayers(players: any) {
        const tournament = this.tournaments.find(t => t._id === players.tournamentId);
        if (tournament) {
            tournament.players = players;
        }
    }


    @computed
    get activeTournaments() {
        return this.tournaments.filter(t =>
            t.status === ETournamentStatus.REGISTERING ||
            t.status === ETournamentStatus.RUNNING
        );
    }

    @computed
    get upcomingTournaments() {
        const now = new Date();
        return this.tournaments?.filter(t => {
            const tournamentStartAt = new Date(t.startAt);
            return tournamentStartAt > now && t.status === ETournamentStatus.REGISTERING;
        }) || [];
    }

    @computed
    get finishedTournaments() {
        return this.tournaments.filter(t =>
            t.status === ETournamentStatus.FINISHED ||
            t.status === ETournamentStatus.COMPLETED
        );
    }

    //----------



    @action createTryBracket() {
        if (this.tournamentBracket) {
            const round: Array<ISeedBracket> = [];
            const roundThird: Array<ISeedBracket> = [];
            let counterStages = 0;
            let counterKeyStages = 1;

            console.log("tournamentBracket");
            //Get SizeOf Stages tour
            let size = Object.keys(this.tournamentBracket.stages)
                .map(Number)
                .filter((item) => item > 0).length;
            // Created while loop for each subArray for adapting our data from back to data which approach for react-bracket npm
            while (counterStages < size) {
                counterStages++;
                const seeds = [];
                this.tournamentBracket.stages[counterKeyStages]?.games.forEach(
                    (element, index) => {
                        // Get players from first level of tournamentBracket and find users in deep of tournamentBracket(i mean tournamentBracket > stages > games)
                        const firstPlayer =
                            this.tournamentBracket?.playersMap?.[
                                element.player1
                            ];
                        const secondPlayer =
                            this.tournamentBracket?.playersMap?.[
                                element.player2
                            ];
                        //Its conditions for current Final Game
                        //Check Final Stage which include 3rd/4th pace game - if we will have second elem in this array, we will having been geme for 3rd-4th place by the end of tour
                        if (counterStages === 1) {
                            const newseeds = {
                                date: moment(
                                    this.tournamentBracket?.created
                                ).format("DD.MM.YYYY HH:mm"),
                                coupleCoords: `coupleCoords_Tab-${counterStages}_Elem-${index}`,
                                isThirdPlaceGame:
                                    this.tournamentBracket?.stages[1]?.games
                                        .length,
                                teams: [
                                    {
                                        _id: firstPlayer?._id,
                                        avatar: firstPlayer?.avatar,
                                        isSystemAvatar:
                                            firstPlayer?.isSystemAvatar,
                                        // Condition for show How None Player if Player didnt reg in tour (for example 6 of 8 players) on First Tournament round
                                        nickname:
                                            counterStages === size &&
                                            !firstPlayer
                                                ? // TODO: move this render function into component and remove bracketPlayerTranslate
                                                  bracketPlayerTranslate(
                                                      this.rootStore?.authStore
                                                          ?.selectedLanguage
                                                  )
                                                : firstPlayer?.nickname,
                                        rating: firstPlayer?.rating,
                                        stats: firstPlayer?.stats,
                                        score: element.score.player1,
                                    },
                                    {
                                        _id: secondPlayer?._id,
                                        avatar: secondPlayer?.avatar,
                                        isSystemAvatar:
                                            secondPlayer?.isSystemAvatar,
                                        nickname:
                                            counterStages === size &&
                                            !secondPlayer
                                                ? // TODO: move this render function into component and remove bracketPlayerTranslate
                                                  bracketPlayerTranslate(
                                                      this.rootStore?.authStore
                                                          ?.selectedLanguage
                                                  )
                                                : secondPlayer?.nickname,
                                        rating: secondPlayer?.rating,
                                        stats: secondPlayer?.stats,
                                        score: element.score.player2,
                                    },
                                ],
                                ...element,
                                seedWidth: size,
                                // seedWidth: Object.keys(
                                //     this.tournamentBracket?.stages
                                // )?.length,
                            };
                            //@ts-ignore
                            seeds.push(newseeds);
                        }
                        if (counterStages !== 1) {
                            const newseeds = {
                                date: moment(
                                    this.tournamentBracket?.created
                                ).format("DD.MM.YYYY HH:mm"),
                                coupleCoords: `coupleCoords_Tab-${counterStages}_Elem-${index}`,
                                teams: [
                                    {
                                        _id: firstPlayer?._id,
                                        avatar: firstPlayer?.avatar,
                                        isSystemAvatar:
                                            firstPlayer?.isSystemAvatar,
                                        // Condition for show How None Player if Player didnt reg in tour (for example 6 of 8 players) on First Tournament round
                                        nickname:
                                            counterStages === size &&
                                            !firstPlayer
                                                ? // TODO: move this render function into component and remove bracketPlayerTranslate
                                                  bracketPlayerTranslate(
                                                      this.rootStore.authStore
                                                          .selectedLanguage
                                                  )
                                                : firstPlayer?.nickname,
                                        rating: firstPlayer?.rating,
                                        stats: firstPlayer?.stats,
                                        score: element.score.player1,
                                    },
                                    {
                                        _id: secondPlayer?._id,
                                        avatar: secondPlayer?.avatar,
                                        isSystemAvatar:
                                            secondPlayer?.isSystemAvatar,
                                        nickname:
                                            counterStages === size &&
                                            !secondPlayer
                                                ? // TODO: move this render function into component and remove bracketPlayerTranslate
                                                  bracketPlayerTranslate(
                                                      this.rootStore.authStore
                                                          .selectedLanguage
                                                  )
                                                : secondPlayer?.nickname,
                                        rating: secondPlayer?.rating,
                                        stats: secondPlayer?.stats,
                                        score: element.score.player2,
                                    },
                                ],
                                ...element,
                                seedWidth: size,
                            };
                            //@ts-ignore
                            seeds.push(newseeds);
                        }
                    }
                );
                const titleObj = {
                    seeds: [...seeds],
                    title: roundIndex[counterStages],
                    // ...tournamentBracket,
                };
                round.push(titleObj);
                counterKeyStages *= 2;
            }
            // Spread main data (stages, games, infos) and same data but only for 3rd/4th position
            // After we will see in bracket 3rd/4th-position container beside/next to Final game container
            this.handleTournamentRenderBracket([
                ...round.reverse(),
                ...roundThird,
            ]);
        }
    }

    @action regularGameChatToggler(status: boolean) {
        this.isHideChat = status;
    }

    @action hideTournamnetMatchChat() {
        if (
            this.currentTournamentGame?.tournament?.gameSeries &&
            Array.isArray(this.currentTournamentGame?.players)
        ) {
            if (
                this.currentTournamentGame?.tournament?.gameSeries -
                    this.currentTournamentGame?.players?.[0]?.score ===
                    0 ||
                this.currentTournamentGame?.tournament?.gameSeries -
                    (this.currentTournamentGame?.players?.[1]?.score || 0) ===
                    0
            ) {
                this.isHideChat = false;
            }
        }
    }

    @action
    handleSeedsLocal(seeds) {
        this.tournamnetSeedsLocal = seeds;
    }

    @action
    handleBracketMobileValue(stagesValue) {
        const spread = { ...this.cleanRenderBracket };
        this.cleanRenderBracket = {
            ...spread,
            stagesValue,
        };
    }

    @action
    handleBracketMobileStages(stagesArray) {
        const spread = { ...this.cleanRenderBracket };
        this.cleanRenderBracket = {
            ...spread,
            stagesArray,
        };
    }

    @action
    handleBracketMobileArray(backetId, bracketArray) {
        const spread = { ...this.cleanRenderBracket };
        this.cleanRenderBracket = {
            ...spread,
            backetId,
            bracketArray,
        };
    }

    @action
    handleTournamentRenderBracket(rebuildArray: ISeedBracket[] | null) {
        this.tournamentRenderBracket = rebuildArray;
    }

    // TODO: get rid of this method and tournamentInfoMessages
    @action
    setErrorNull() {
        const oldStat = this.tournamentInfoMessages;
        this.tournamentInfoMessages = {
            ...oldStat,
            prepareToJackpot: false,
        };
    }

    //@action
    //async getTournaments() {
    //    this.setErrorNull();
    //    try {
    //        this.setLoadedState(false);
    //        this.setIsErrorOccured(false);

    //        const tournaments = await getTournaments();

    //        runInAction(() => {
    //            this.tournaments = Array.isArray(tournaments)
    //                ? tournaments.map((tournament) => ({
    //                    ...tournament,
    //                    players: Array.isArray(tournament.players) ? tournament.players : [],
    //                }))
    //                : [];
    //        });
    //    } catch (e) {
    //        console.error("Ошибка при загрузке турниров:", e);
    //        runInAction(() => {
    //            this.setIsErrorOccured(true);
    //            this.tournaments = [];
    //        });
    //    } finally {
    //        this.setLoadedState(true);
    //    }
    //}



    @action
    async getTournamentDetails(id: string): Promise<AxiosResponse<ITournament> | undefined> {
        if (!id || typeof id !== "string") {
            console.error("Invalid tournament ID provided:", id);
            return;
        }

        try {
            const response = await getCurrentTournament(id);

            runInAction(() => {
                if (response?.data) {
                    const index = this.tournaments.findIndex(t => t._id === id);
                    if (index !== -1) {
                        this.tournaments[index] = {
                            ...this.tournaments[index],
                            ...response.data
                        };
                    }

                    this.selectedTournament = response.data;

                    if (response.data.bracket) {
                        this.setTournamentBracket(response.data.bracket);
                    }
                }
            });

            return response;
        } catch (error) {
            console.error("Error fetching tournament:", error);
            throw error;
        }
    }

    //async createTournament(params: ITournamentCreateRequest) {
    //    try {
    //        this.setLoadedState(false);
    //        const response = await createTournaments(params);
    //        if (response.status === 200) {
    //            await this.getTournaments();
    //            this.setLoadedState(true);
    //        }
    //    } catch (error) {
    //        console.log(error);
    //        this.setIsErrorOccured(true);
    //    }
    //}

    async joinTournament(params: ITournamentJoinRequest) {
        this.setErrorNull();
        try {
            this.setLoadedState(false);
            const response = await joinTournaments(params);

            if (response.status === 200 && this.tournamentModalInfo) {
                this.tournamentModalInfo.participating = true;
                if (params.alertType === TournamentAlertType.jackpot) {
                    emitter.emit("locale:info", {
                        label: "tournaments.infos.successfulJackpotRegistration",
                        defaultValue: "Registration in Jackpot",
                    });
                } else {
                    emitter.emit("locale:info", {
                        label: "tournaments.infos.successfulRegistration",
                        defaultValue: "Registration successful",
                    });
                }
            }
        } catch (e) {
            const error = LocaleHelper.extractFromError(e);
            if (error) emitter.emit("locale:error", error);
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async cancelJoinTournament(tournamentId: string) {
        // this.setErrorNull();
        try {
            // this.setLoadedState(false);
            const response = await cancelJoinTournaments({ id: tournamentId });
            if (response.status === 200 && this.tournamentModalInfo) {
                // this.tournamentModalInfo.participating = false;
                // emitter.emit("locale:info", {
                //     label: "tournaments.infos.cancelRegistrationMess",
                //     defaultValue: "Registration canceled",
                // });
            }
        } catch (e) {
            // const error = LocaleHelper.extractFromError(e);
            // if (error) emitter.emit("locale:error", error);
        } finally {
            // this.setLoadedState(true);
        }
    }

    //async deleteTournaments() {
    //    this.setErrorNull();
    //    try {
    //        this.setLoadedState(false);
    //        const response = await deleteAllTournaments();
    //        if (response.status === 200) {
    //            await this.getTournaments();
    //        }
    //    } catch (error) {
    //        this.setIsErrorOccured(true);
    //    } finally {
    //        this.setLoadedState(true);
    //    }
    //}

    //@action
    //tournamentStatus(tournamentStatus: {
    //    id: string;
    //    status: TournamentStatus;
    //    winner: ITopPlayers | null;
    //}) {
    //    if (!this.tournaments) return;
    //    this.tournaments = this.tournaments.map((tournament, i) => {
    //        if (tournament.id === tournamentStatus.id) {
    //            return {
    //                ...tournament,
    //                status: tournamentStatus.status,
    //                winner: tournamentStatus.winner,
    //            };
    //        }
    //        return tournament;
    //    });
    //}
    @action
    tournamentStatus(tournamentStatus: {
        id: string;
        status: ETournamentStatus;
        winner: ITopPlayers | null;
    }) {
        this.tournaments = this.tournaments.map(tournament => {
            if (tournament._id === tournamentStatus.id) {
                return {
                    ...tournament,
                    status: tournamentStatus.status,
                    winner: tournamentStatus.winner
                };
            }
            return tournament;
        });
    }

    //@action
    //tournamentJackpotStatus(id: string, status: TournamentStatus | string) {
    //    if (!this.tournaments) return;
    //    this.tournamentInfoMessages.prepareToJackpot = true;
    //    this.tournaments = this.tournaments.map((tournament, i) => {
    //        if (tournament.id === id) {
    //            return {
    //                ...tournament,
    //                status: status,
    //            };
    //        }
    //        return tournament;
    //    });
    //}
    @action
    tournamentJackpotStatus(id: string, status: ETournamentStatus | string) {
        this.tournamentInfoMessages.prepareToJackpot = true;
        this.tournaments = this.tournaments.map(tournament => {
            if (tournament._id === id) {
                return {
                    ...tournament,
                    status: status as ETournamentStatus
                };
            }
            return tournament;
        });
    }


    @action
    updateModalJackpotStatus(id: string, status: ETournamentStatus | string) {
        if (!this.tournamentModalInfo || !status) return;
        return (this.tournamentModalInfo.status = status);
    }

    @action
    updateThirdOrFourth(player1: string | null, player2: string | null) {
        if (
            rootStore.authStore.currentUser?._id === player1 ||
            rootStore.authStore.currentUser?._id === player2
        ) {
            this.tournamentInfoMessages.prepareThirdOrFourth = true;
        }
        if (!player1 && !player2) {
            this.tournamentInfoMessages.prepareThirdOrFourth = false;
        }
    }

    @action
    setTournamentWinnerAlert(tournamentStatus: IPrizeTournamentData | null) {
        this.tournamentWinnerAlert = tournamentStatus;
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    //@action
    //setTournaments(tournaments: ITournaments[]) {
    //    this.tournaments = tournaments;
    //    this.isLoaded = true;
    //}

    
    @action
    setTournaments(tournaments: ITournament[]) {
        this.tournaments = tournaments.slice(); // Преобразуем данные в обычный массив сразу
        this.isLoaded = true;
    }

    @action
    setTournamentBracket(tournament: Nullable<ITournamentBracket>) {
        // TODO: refactor
        if (!tournament) {
            this.tournamentBracket = null;
            return;
        }
        this.tournamentBracket = {
            ...tournament,
            playersMap: _keyBy(tournament.playersInfo, "user"),
        };
        this.isLoaded = true;
    }

    @action
    updateTournamentScore(scoreData: StageClass) {
        if (
            !scoreData ||
            typeof scoreData.stage !== "number" ||
            !this.tournamentBracket
        )
            return;
        const updatedGames =
            this.tournamentBracket.stages[scoreData?.stage].games;
        const updatedScore = updatedGames.map((item) => {
            if (item.id === scoreData.gameUid) {
                const matches = item.matches;
                matches.push({
                    gameId: scoreData.gameId,
                    winner: null,
                });
                return { ...item, matches, score: scoreData.score };
            }
            return item;
        });
        this.tournamentBracket.stages[scoreData?.stage].games = updatedScore;
        this.bracketScoreUpdateIvent = scoreData.gameId;
    }

    @action
    setIsErrorOccured(isLoaded: boolean) {
        this.errorOccured = isLoaded;
    }

    @action
    toggleGameJackpotSortOrder() {
        this.sortOrderType = SortTournamentsType.type;
        this.sortOrderFilters.typeGameSortOrder =
            this.sortOrderFilters.typeGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
    }

    @action
    toggleGameTimeSortOrder() {
        this.sortOrderType = SortTournamentsType.time;
        this.sortOrderFilters.timeGameSortOrder =
            this.sortOrderFilters.timeGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
    }

    @action
    toggleGamePlayerSortOrder() {
        this.sortOrderType = SortTournamentsType.player;
        this.sortOrderFilters.playerGameSortOrder =
            this.sortOrderFilters.playerGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
    }

    @action
    toggleGamePrizeSortOrder() {
        this.sortOrderType = SortTournamentsType.prize;
        this.sortOrderFilters.prizeGameSortOrder =
            this.sortOrderFilters.prizeGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
    }

    @action
    toggleGameBuyInSortOrder() {
        this.sortOrderType = SortTournamentsType.buyIn;
        this.sortOrderFilters.buyInGameSortOrder =
            this.sortOrderFilters.buyInGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
    }

    @action
    joinTournamnetHandlerModal(id: string | null) {
        this.setLoadedState(false);
        if (id === null) return (this.tournamentModalId = id);
        this.tournamentModalId = id;
        this.setLoadedState(true);
    }

    @action
    setTournamnetDataToModal(tournament: ITournamentModalInfo | null) {
        console.log("Setting tournament modal info:", tournament);
        this.setLoadedState(false);
        if (!tournament) {
            this.tournamentModalInfo = null;
            console.log("Modal info cleared.");
            return;
        }
        this.tournamentModalInfo = tournament;
        console.log("Updated modal info in store:", this.tournamentModalInfo);
        this.setLoadedState(true);
    }


    //@action
    //updateNumberOfPlayersOnTournament(id: string, numberOfPlayers: number) {
    //    if (!Array.isArray(this.tournaments) || !id) return;
    //    this.tournaments = this.tournaments.map((tournament, i) => {
    //        if (tournament.id === id) {
    //            return {
    //                ...tournament,
    //                players: numberOfPlayers,
    //            };
    //        }
    //        return tournament;
    //    });
    //}
    @action
    updateNumberOfPlayersOnTournament(id: string, numberOfPlayers: number) {
        if (!id) return;
        this.tournaments = this.tournaments.map(tournament => {
            if (tournament._id === id) {
                return {
                    ...tournament,
                    players: tournament.players.length ? tournament.players : [] // Сохраняем массив players
                };
            }
            return tournament;
        });
    }


    @action
    updatePlayersOnModal(players: ITopPlayers[]) {
        if (!this.tournamentModalInfo || !players) return;
        return (this.tournamentModalInfo.playersInfo = players);
    }

    @action
    updateStopRegistration(
        days: number,
        hours: number,
        minutes: number,
        borderTime: number
    ) {
        if (!this.tournamentModalInfo) return;
        if (minutes - borderTime <= 0 && days === 0 && hours === 0) {
            return (this.tournamentModalInfo.stopRegistration = true);
        }
        return (this.tournamentModalInfo.stopRegistration = false);
    }

    //@computed
    //get filteredTournaments() {
    //    if (!Array.isArray(this.tournaments)) return;
    //    return this.tournaments
    //        .filter((item) => {
    //            if (!item.status || item.status === TournamentStatus.CANCELLED)
    //                return false;
    //            return true;
    //        })

    //        .sort((a, b) => {
    //            {
    //                switch (this.sortOrderType) {
    //                    case SortTournamentsType.time:
    //                        return this.sortOrderFilters.timeGameSortOrder ===
    //                            SortOrder.desc
    //                            ? Date.parse(a.startDate) -
    //                                  Date.parse(b.startDate)
    //                            : Date.parse(b.startDate) -
    //                                  Date.parse(a.startDate);
    //                    case SortTournamentsType.prize:
    //                        return this.sortOrderFilters.prizeGameSortOrder ===
    //                            SortOrder.desc
    //                            ? b.prize - a.prize
    //                            : a.prize - b.prize;
    //                    case SortTournamentsType.player:
    //                        return this.sortOrderFilters.playerGameSortOrder ===
    //                            SortOrder.desc
    //                            ? b.players - a.players
    //                            : a.players - b.players;
    //                    case SortTournamentsType.buyIn:
    //                        return this.sortOrderFilters.buyInGameSortOrder ===
    //                            SortOrder.desc
    //                            ? b.buyIn - a.buyIn
    //                            : a.buyIn - b.buyIn;
    //                }
    //            }
    //            return 0;
    //        });
    //}

    @computed
    get filteredTournaments() {

        return this.tournaments
            .slice()
            .filter(item => (
                item.status &&
                item.status !== ETournamentStatus.CANCELLED
            ))
            .sort((a, b) => {
                switch (this.sortOrderType) {
                    case SortTournamentsType.time:
                        return this.sortOrderFilters.timeGameSortOrder === SortOrder.desc
                            ? Date.parse(a.startDate) - Date.parse(b.startDate)
                            : Date.parse(b.startDate) - Date.parse(a.startDate);
                    case SortTournamentsType.prize:
                        return this.sortOrderFilters.prizeGameSortOrder === SortOrder.desc
                            ? b.prize - a.prize
                            : a.prize - b.prize;
                    case SortTournamentsType.player:
                        const aPlayerCount = Array.isArray(a.players) ? a.players.length : 0;
                        const bPlayerCount = Array.isArray(b.players) ? b.players.length : 0;
                        return this.sortOrderFilters.playerGameSortOrder === SortOrder.desc
                            ? bPlayerCount - aPlayerCount
                            : aPlayerCount - bPlayerCount;
                    case SortTournamentsType.buyIn:
                        return this.sortOrderFilters.buyInGameSortOrder === SortOrder.desc
                            ? b.buyIn - a.buyIn
                            : a.buyIn - b.buyIn;
                }
                return 0;
            });
    }

    @action
    changeTournamentAlertsState(status: boolean, alertType: string) {
        return (this.tournamentAlertsState[alertType] = status);
    }

    @action
    upTournamentAlertsState(state: string | boolean) {
        const semiData = { ...this.tournamentAlertsState };
        Object.keys(semiData).forEach((element) => {
            Object.defineProperty(semiData, element, {
                value: state,
            });
        });
        return (this.tournamentAlertsState = semiData);
    }

    @action
    resetTournamentAlertsState() {
        this.tournamentAlertsState = {
            quotesModalState: true,
        };
    }

    // TODO: fix typing
    @action
    setCurrentTournamentGame(tournament: IGame | null) {
        this.currentTournamentGame = tournament;
    }

    //@action
    //resetTournaments() {
    //    this.tournaments = null;
    //    this.currentTournamentGame = null;
    //    this.tournamentBracket = null;
    //    this.tournamentModalId = null;
    //    this.tournamentModalInfo = null;
    //}
    @action
    resetTournaments() {
        this.tournaments = [];
        this.currentTournamentGame = null;
        this.tournamentBracket = null;
        this.tournamentModalId = null;
        this.tournamentModalInfo = null;
    }

    addMyGamesToList(games: IGame[]) {
        games.filter((game) => {
            if (!game.participating) return false;
            if (game.status === GameStatus.FINISHED) {
                const previousGame = this.myGames[game.tournament?.id!];
                if (previousGame?.id !== game.id) return;
                delete this.myGames[game.tournament?.id!];
                return;
            }
            this.myGames[game.tournament?.id!] = game;

            return true;
        });
    }

    @computed
    get myGamesList() {
        return Object.values(this.myGames);
    }

    @computed
    get hasActiveGames() {
        return this.myGamesList.length > 0;
    }

    getGameByTournamentId(tournamentId: string) {
        return this.myGames[tournamentId];
    }

    //Новые методы


    @action
    clearSelectedTournament() {
        this.selectedTournament = null;
        this.selectedTournamentBracket = null;
    }

    // Update the existing getTournamentById method to be more robust
    @action
    getTournamentById(id: string): ITournament | undefined {
        return this.tournaments.find(tournament => tournament._id === id);
    }

    //

    private getDefaultFilters(): ITournamentFilters {
        return {
            time: {
                "3m": true,
                "5m": true,
                "10m": true,
                "20m": true
            },
            mode: {
                1: true, 
                2: true, 
                3: true   
            },
            type: {
                "CLASSIC": true,
                "960": true
            }
        };
    }

    private timeToFilterKey(time: number): string {
        return `${time}m`;
    }

    private fenTypeToString(fenType: number): string {
        return fenType === 1 ? "CLASSIC" : "960";
    }

    @computed
    get filteredTournamentsList(): ITournament[] {
        return this.tournaments.filter(tournament => {
            if (!tournament.status || tournament.status === ETournamentStatus.CANCELLED) {
                return false;
            }

            const activeTimeFilters = Object.entries(this.tournamentFilters.time)
                .filter(([_, isActive]) => isActive)
                .map(([key]) => key);

            const activeModeFilters = Object.entries(this.tournamentFilters.mode)
                .filter(([_, isActive]) => isActive)
                .map(([key]) => Number(key));

            const activeTypeFilters = Object.entries(this.tournamentFilters.type)
                .filter(([_, isActive]) => isActive)
                .map(([key]) => key);

            const tournamentTimeKey = this.timeToFilterKey(tournament.gameSettings.time);
            const tournamentTypeKey = this.fenTypeToString(tournament.gameSettings.fenType);

            const timeMatch = activeTimeFilters.length === 0 ||
                activeTimeFilters.includes(tournamentTimeKey);

            const modeMatch = activeModeFilters.length === 0 ||
                activeModeFilters.includes(tournament.type);

            const typeMatch = activeTypeFilters.length === 0 ||
                activeTypeFilters.includes(tournamentTypeKey);
           
            return timeMatch && modeMatch && typeMatch;
        });
    }

    @action
    setTournamentFilterTime(value: { [s: string]: boolean }) {
        const key = Object.keys(value)[0];
        runInAction(() => {
            this.tournamentFilters.time[key] = !this.tournamentFilters.time[key];
        });
    }

    @action
    setTournamentFilterMode(value: { [s: string]: boolean }) {
        const key = Object.keys(value)[0];
        runInAction(() => {
            this.tournamentFilters.mode[key] = !this.tournamentFilters.mode[key];
        });
    }

    @action
    setTournamentFilterType(value: { [s: string]: boolean }) {
        const key = Object.keys(value)[0];
        runInAction(() => {
            this.tournamentFilters.type[key] = !this.tournamentFilters.type[key];
        });
    }


    @action
    resetTournamentFilters() {
        runInAction(() => {
            // Создаем новый объект фильтров
            this.tournamentFilters = this.getDefaultFilters();
        });
    }

    @action
    initializeFilters() {
        this.resetTournamentFilters();
    }
}

export default TournamentsStore;
