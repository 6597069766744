import React, { useCallback, useEffect, useRef, useState } from 'react';
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import TournamentLines from '../components/tournamentLines';
import BracketColumn from '../components/bracketColumn';
import MatchPair from '../components/matchPair';

interface DesktopBracketProps {
    bracket: any[];
    classes: any;
    sub?: boolean;
}

const DesktopBracket: React.FC<DesktopBracketProps> = ({ bracket, classes, sub = false }) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    //const bracketsRef = useRef<HTMLDivElement | null>(null);
    //const headersRef = useRef<HTMLDivElement | null>(null);
    const mainBracketsRef = useRef<HTMLDivElement | null>(null);
    const subBracketsRef = useRef<HTMLDivElement | null>(null);
    const mainHeadersRef = useRef<HTMLDivElement | null>(null);
    const subHeadersRef = useRef<HTMLDivElement | null>(null);

    const updateArrowVisibility = useCallback(() => {
        const bracketElement = mainBracketsRef.current;
        if (bracketElement) {
            const { scrollLeft, scrollWidth, clientWidth } = bracketElement;

            const maxScroll = scrollWidth - clientWidth;
            const shouldShowLeft = scrollLeft > 1;
            const shouldShowRight = scrollLeft < maxScroll - 1;

            setCanScrollLeft(shouldShowLeft);
            setCanScrollRight(shouldShowRight);
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            updateArrowVisibility();
        }, 300);

        return () => clearTimeout(timer);
    }, [bracket]); 

    const handleScroll = useCallback(() => {
        const bracketElement = mainBracketsRef.current;
        const headerElement = mainHeadersRef.current;

        if (bracketElement && headerElement) {
            headerElement.scrollLeft = bracketElement.scrollLeft;
            updateArrowVisibility();
        }
    }, [updateArrowVisibility]);

    useEffect(() => {
        const bracketElement = mainBracketsRef.current;
        if (bracketElement) {
            bracketElement.addEventListener("scroll", handleScroll);

            const resizeObserver = new ResizeObserver(() => {
                updateArrowVisibility();
            });

            resizeObserver.observe(bracketElement);

            return () => {
                bracketElement.removeEventListener("scroll", handleScroll);
                resizeObserver.disconnect();
            };
        }
    }, [handleScroll, updateArrowVisibility]);

    useEffect(() => {
        updateArrowVisibility();
    }, [bracket]);

    const scrollLeft = () => {
        const bracketElement = mainBracketsRef.current;
        const headerElement = mainHeadersRef.current;
        if (bracketElement && headerElement) {
            const scrollAmount = -300; 
            bracketElement.scrollBy({ left: scrollAmount, behavior: "smooth" });
            headerElement.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        const bracketElement = mainBracketsRef.current;
        const headerElement = mainHeadersRef.current;
        if (bracketElement && headerElement) {
            const scrollAmount = 300; 
            bracketElement.scrollBy({ left: scrollAmount, behavior: "smooth" });
            headerElement.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    //console.log('DesktopBracket props:', {
    //    bracketLength: bracket.length,
    //    hasSub: sub,
    //    matchesTotal: bracket.reduce((acc, round) => acc + round.matches.length, 0)
    //});

    const mainBracket = bracket.map(round => ({
        ...round,
        matches: round.matches.filter(match => !match.sub)
    })).filter(round => round.matches.length > 0);

    const subBracket = bracket.map(round => ({
        ...round,
        matches: round.matches.filter(match => match.sub)
    })).filter(round => round.matches.length > 0);

    //console.log('Brackets split:', {
    //    mainBracketRounds: mainBracket.length,
    //    mainBracketMatches: mainBracket.reduce((acc, round) => acc + round.matches.length, 0),
    //    subBracketRounds: subBracket.length,
    //    subBracketMatches: subBracket.reduce((acc, round) => acc + round.matches.length, 0)
    //});

    const hasSubBracket = sub && subBracket.length > 0;
    //console.log('Will render sub bracket:', hasSubBracket);

    return (
        <div className={classes.bracketContainer}>
            {canScrollLeft && (
                <button className={classes.carouselButtonLeft} onClick={scrollLeft}>
                    <img src={rightArrowIcon} alt="Scroll Left" />
                </button>
            )}

            {/* Основная сетка */}
            <div className={classes.bracketWrapper}>
                <div ref={mainHeadersRef} className={classes.fixedHeaders}>
                    {mainBracket.map((round) => (
                        <div key={`header-${round.title}`} className={classes.headerColumn}>
                            {round.title}
                        </div>
                    ))}
                </div>

                <div className={`${classes.bracket} ${classes.bracketContent}`} ref={mainBracketsRef}>
                    <TournamentLines bracket={mainBracket} />
                    {mainBracket.map((round, roundIndex) => (
                        <BracketColumn
                            key={round.title}
                            title={round.title}
                            columnIndex={roundIndex}
                            totalRounds={mainBracket.length}
                            matchesInRound={round.matches.length}
                            hideHeader={true}
                        >
                            {round.matches.map((match, matchIndex) => (
                                <div key={matchIndex} className={classes.singleMatch}>
                                    <MatchPair
                                        {...match}
                                        firstWins={match.firstWins ?? 0}
                                        secondWins={match.secondWins ?? 0}
                                        columnIndex={roundIndex}
                                        matchIndex={matchIndex}
                                        totalColumns={mainBracket.length}
                                    />
                                </div>
                            ))}
                        </BracketColumn>
                    ))}
                </div>
            </div>

            {canScrollRight && (
                <button className={classes.carouselButtonRight} onClick={scrollRight}>
                    <img
                        src={rightArrowIcon}
                        style={{ transform: "rotate(180deg)" }}
                        alt="Scroll Right"
                    />
                </button>
            )}

            {/* Подсетка (отображается только если есть матчи в подсетке) */}
            {hasSubBracket && (
                <div className={`${classes.bracketWrapper} ${classes.subBracketWrapper}`}>
                    <div className={classes.subBracketTitle}>Нижняя сетка</div>
                    <div ref={subHeadersRef} className={classes.fixedHeaders}>
                        {subBracket.map((round) => (
                            <div key={`sub-header-${round.title}`} className={classes.headerColumn}>
                                {round.title}
                            </div>
                        ))}
                    </div>

                    <div className={`${classes.bracket} ${classes.bracketContent}`} ref={subBracketsRef}>
                        <TournamentLines bracket={subBracket} isSubBracket={true} />
                        {subBracket.map((round, roundIndex) => (
                            <BracketColumn
                                key={`sub-${round.title}`}
                                title={round.title}
                                columnIndex={roundIndex}
                                totalRounds={subBracket.length}
                                matchesInRound={round.matches.length}
                                hideHeader={true}
                                isSubBracket={true}
                            >
                                {round.matches.map((match, matchIndex) => (
                                    <div key={matchIndex} className={classes.singleMatch}>
                                        <MatchPair
                                            {...match}
                                            firstWins={match.firstWins ?? 0}
                                            secondWins={match.secondWins ?? 0}
                                            columnIndex={roundIndex}
                                            matchIndex={matchIndex}
                                            totalColumns={subBracket.length}
                                            isSubBracket={true}
                                        />
                                    </div>
                                ))}
                            </BracketColumn>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DesktopBracket;