import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

interface IAvatars {
    height: number;
}

export const useStyles = makeStyles({
    stepAreaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        maxWidth: 630,
        background: '#1e1b22',
        opacity: '0.97',
        borderRadius: 16,
        paddingTop: '1vw',
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100vw - 6vw)',
            height: 'calc(100vh - 6vw)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 6vw)',
            height: 'calc(100vh - 6vw)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 6vw)',
            height: 'calc(100vh - 6vw)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
    },

    gridAvatarContainer: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 270px)',
        margin: '0 auto',
        width: '100%',
        // maxHeight: 'calc(90vh - 450px)',
        background: 'rgba(245, 245, 245, 0.1)',
        alignContent: 'start',
        borderRadius: 10,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        'scrollbar-width': 'none',
        [theme.breakpoints.up('xs')]: {
            height: 'calc(100vh - 280px)',
            '&.isCustom': {
                height: 'calc(100vh - 280px)',
            },
        },
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 270px)',
            '&.isCustom': {
                height: 'calc(100vh - 270px)',
                animationName: '$gridAvatarContainer',
                animationDelay: '0.01s',
                animationIterationCount: 1,
                animationFillMode: 'forwards',
                animationDuration: '1000ms',
            },
        },
    },

    gridBlockWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            '&.nonGrid': {
                display: 'none',
            },
        },
    },

    currentStepWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 1200px)': {
            position: 'fixed',
            top: '13%',
            left: 0,
            width: '100%',
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,
        },
        '@media (max-width: 768px)': {
            position: 'fixed',
            top: '12%',
            left: 0,
            width: '100%',
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,
        },
        '@media (max-width: 560px)': {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '96vw',
            height: '98vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,

        },
        '@media (max-width: 376px)': {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '96vw',
            height: '98vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,

        },
        '@media (max-width: 330px)': {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '96vw',
            height: '98vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,

        },
    },

    gridAvatarItem: {
        width: '100%',
        aspectRatio: '1 / 1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },

    gridAvatarImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },


    gridAvatarFastImg: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        filter: 'blur(5px)',
        zIndex: 2,
    },

    '@keyframes isAppear': {
        '0%': {
            opacity: 0.2,
        },
        '100%': {
            opacity: 1,
        },
    },

    '@keyframes isStepHide': {
        '0%': {
            opacity: 0.8,
            boxShadow: '0px 0px 18px 15px rgba(78, 93, 180, 0.1)',
        },
        '60%': {
            boxShadow: '0px 0px 28px 20px rgba(78, 93, 180, 0.9)',
        },
        '100%': {
            opacity: 1,
            boxShadow: '0px 0px 22px 15px rgba(78, 93, 180, 0.7)',
        },
    },

    buttonBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0 auto',
        marginTop: '1vw',
        marginBottom: '1vw',
        width: 'auto',
        alignItems: 'center',
        gap: '1vw',
        '@media (max-width: 1200px)': {
            flexDirection: 'row',
            width: '100%',
            gap: '1.5vw',
            marginTop: '1vw',
        },
        '@media (max-width: 768px)': {
            flexDirection: 'row',
            width: '100%',
            gap: '1.5vw',
            marginTop: '2vw',
        },
        '@media (max-width: 560px)': {
            flexDirection: 'column',
            width: '100%',
            gap: '2vw',
            marginTop: '3vw',

        },
        '@media (max-width: 376px)': {
            flexDirection: 'column',
            width: '100%',
            gap: '3vw',
            marginTop: '3vw',

        },
        '@media (max-width: 330px)': {
            flexDirection: 'column',
            width: '100%',
            gap: '3vw',
            marginTop: '3vw',

        },
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    wrapperFormik: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            minHeight: 300,
            '&.isCustom': {},
        },
    },

    wrapperFormikBox: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },
    },

    form: {
        gridRowGap: 8,
    },

    buttonBoxLoaded: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0 auto',
        marginTop: '1vw',
        width: 'auto',
        alignItems: 'center',
        gap: '1vw',
        '@media (max-width: 1200px)': {
            flexDirection: 'row',
            width: '100%',
            gap: '1.5vw',
            marginTop: '1vw',
        },
        '@media (max-width: 768px)': {
            flexDirection: 'row',
            width: '100%', 
            gap: '1.5vw',
            marginTop: '2vw',
        },
        '@media (max-width: 560px)': {
            flexDirection: 'column',
            width: '100%', 
            gap: '2vw',
            marginTop: '3vw',

        },
        '@media (max-width: 376px)': {
            flexDirection: 'column',
            width: '100%', 
            gap: '3vw',
            marginTop: '3vw',

        },
        '@media (max-width: 330px)': {
            flexDirection: 'column',
            width: '100%', 
            gap: '3vw',
            marginTop: '3vw',

        },
    },
    saveButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        width: "16vw",
        height: "2.5vw",
        color: "#11141C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        '@media (max-width: 3000px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "8vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 2600px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "11vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 2250px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "13vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 1200px)': {
            fontSize: "1.5vw",
            height: "4vw",
            width: "20vw",
            borderRadius: 4,
        },
        '@media (max-width: 768px)': {
            fontSize: "3vw",
            height: "7vw",
            width: '100%',
            borderRadius: 4,
        },
        '@media (max-width: 560px)': {
            fontSize: "5.5vw",
            height: "11vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 376px)': {
            fontSize: "6vw",
            height: "12vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 330px)': {
            fontSize: "7vw",
            height: "15vw",
            width: '100%',
            borderRadius: 4,

        },
    },

    changeButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        width: "16vw",  
        height: "2.5vw", 
        color: "#11141C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        '@media (max-width: 1200px)': {
            fontSize: "1.5vw",
            height: "4vw",
            width: "20vw",  
            borderRadius: 4,
        },
        '@media (max-width: 768px)': {
            fontSize: "3vw", 
            height: "7vw",
            width: '100%',
            borderRadius: 4,
        },
        '@media (max-width: 560px)': {
            fontSize: "5.5vw", 
            height: "11vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 376px)': {
            fontSize: "6vw", 
            height: "12vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 330px)': {
            fontSize: "7vw", 
            height: "15vw",
            width: '100%',
            borderRadius: 4,

        },
    },
    uploadButton: {
        borderRadius: "0.2vw",
        border: "1px solid #00BA6C",
        background: "transparent",
        fontSize: "1vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        width: "16vw",
        height: "2.5vw",
        color: "#00BA6C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        '@media (max-width: 1200px)': {
            fontSize: "1.5vw",
            height: "4vw",
            width: "20vw",
            borderRadius: 4,
        },
        '@media (max-width: 768px)': {
            fontSize: "3vw",
            height: "7vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 560px)': {
            fontSize: "5.5vw", 
            height: "11vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 376px)': {
            fontSize: "6vw", 
            height: "12vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 330px)': {
            fontSize: "7vw", 
            height: "15vw",
            width: '100%',
            borderRadius: 4,

        },
    },
    clearButton: {
        borderRadius: "0.2vw",
        border: "1px solid #EB5757",
        background: "transparent",
        fontSize: "1vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        width: "16vw",
        height: "2.5vw",
        color: "#EB5757",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        '@media (max-width: 3000px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "8vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 2600px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "11vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 2250px)': {
            fontSize: "1vw",
            height: "2vw",
            width: "13vw",
            borderRadius: "0.15vw",
        },
        '@media (max-width: 1200px)': {
            fontSize: "1.5vw",
            height: "4vw",
            width: "20vw",
            borderRadius: 4,
        },
        '@media (max-width: 768px)': {
            fontSize: "3vw",
            height: "7vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 560px)': {
            fontSize: "5.5vw",
            height: "11vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 376px)': {
            fontSize: "6vw",
            height: "12vw",
            width: '100%',
            borderRadius: 4,

        },
        '@media (max-width: 330px)': {
            fontSize: "7vw",
            height: "15vw",
            width: '100%',
            borderRadius: 4,

        },
    },

    flexBlockWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',

        },
    },
    flexContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(10, 1fr)', 
        gap: '10px', 
        justifyContent: 'center',
        padding: '20px',
        background: '#F5F5F51A',
        borderRadius: 16,
        maxHeight: '70vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent',
        },
        '@media (max-width: 768px)': {
            gridTemplateColumns: 'repeat(6, 1fr)', 
        },
        '@media (max-width: 560px)': {
            gridTemplateColumns: 'repeat(3, 1fr)', 
        },
        '@media (max-width: 376px)': {
            gridTemplateColumns: 'repeat(3, 1fr)', 
        },
        '@media (max-width: 330px)': {
            gridTemplateColumns: 'repeat(3, 1fr)', 
        },
    },
    avatarItem: {
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
    },

    selectedAvatarImg: {
        border: '4px solid #99FFD5', 
        boxShadow: '0px 0px 24px 0px #00EE8A, 0px 0px 32px 0px #00EE8A', 
        transform: 'scale(1.05)', 
        transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
    },

    gridAvatarItemSelected: {
        '& $gridAvatarImg': {
            boxShadow: '0 0 0 3px #00BA6C, 0 0 10px 5px rgba(0, 186, 108, 0.5)',
            transform: 'scale(1.05)',
        },
    },

    isLoaded: {
        filter: 'blur(4px)',
        borderRadius: '50%',
    },
});
