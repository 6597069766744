import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getNewsById } from 'src/service/api/news';
import { fileHelper } from '../../../shared/helpers/FileHelper';
import { INewsItem } from '../../../service/api/news/requestResponses';
import { useStyles } from "./styles";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import { observer } from 'mobx-react';
import { useLocale } from '../../../providers/LocaleProvider';

interface NewsParams {
    id: string;
}

const NewsDetailsPage = observer(() => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<NewsParams>();
    const [newsData, setNewsData] = useState<INewsItem | null>(null);
    const [loading, setLoading] = useState(true);
    const {
        news: {
            backBtn,
        },
    } = useLocale();
    useEffect(() => {
        const fetchNewsDetails = async () => {
            try {
                const response = await getNewsById(id);
                setNewsData(response.data); 
            } catch (error) {
                console.error('Error fetching news details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsDetails();
    }, [id]);


    if (!newsData) {
        return <div>News not found</div>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                <img
                    src={fileHelper.getMediaFileURL(newsData.imageURL) || ""}
                    alt={newsData.title}
                    className={classes.backgroundImage}
                />
                <div className={classes.gradient} />
            </div>
            <div className={classes.content}>
                <h1 className={classes.title}>{newsData.title}</h1>
                <div className={classes.date}>
                    {moment(newsData.createdAt).format('DD.MM.YYYY')}
                </div>
                <div
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: newsData.text }}
                />
                <div
                    className={classes.backButton}
                    onClick={() => history.push('/news')}
                >
                    <img src={arrowIcon} className={classes.arrowIcon} />
                    <span className={classes.backText}>{backBtn('Back to news')}</span>
                </div>
            </div>
        </div>
    );
});

export default NewsDetailsPage;