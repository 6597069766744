import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabContext from "@mui/lab/TabContext";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import PeopleIcon from "@mui/icons-material/People";

import { prizeModalGold } from "src/consts/app-config";
import TopPlayers from "src/components/TopPlayers";
import TournamentTopPlayersIcon from "src/assets/icons/tournaments/TournamentTopPlayersIcon";
import useChangeTheme from "src/hooks/useChangeTheme";
import useStores from "src/hooks/useStores";

import { GameStatus } from "../../../../store/models";
import FriendsView from "../../../lobby/components/FriendsContainer/Friends";

import { useStyles } from "./styles";
import GameInProgressPreview from "./GameInProgressPreview";
import { GameInProgressPreviewContainer } from "./GameInProgressPreview";
import { PlayerInfoExistPosition } from "src/store/models";

interface IGamePreview {
    isPreviewGame?: boolean | undefined;
    isFriends?: boolean | undefined;
    showFriends: (status: boolean) => void;
    showPreview: (status: boolean) => void;
    togglePreview: any;
    isFullScreen?: any;
    isOpenPreview?: boolean | null;
    componentPlace?: PlayerInfoExistPosition;
}

enum EPreviewBlock {
    friends = "friends",
    rating = "rating",
}

const GamePreview = ({
    showPreview,
    isFriends,
    showFriends,
    togglePreview,
    isFullScreen,
    isOpenPreview,
    componentPlace,
}: IGamePreview) => {
    const { lobbyStore, authStore } = useStores();
    const appearanceTheme = useChangeTheme();
    const classes = useStyles();
    const isVisible = authStore.isVisibleWebApp;
    const game = lobbyStore.selectedGame;
    const [currentTab, setCurrentTab] = useState(EPreviewBlock.friends);

    useEffect(() => {
        showPreview?.(true);
    }, [isVisible]);

    useEffect(() => {
        if (authStore.isAuthorized) setCurrentTab(EPreviewBlock.friends);
        else setCurrentTab(EPreviewBlock.rating);
    }, [authStore.isAuthorized]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue as EPreviewBlock);
    };

    return (
        <GameInProgressPreviewContainer
            showFriends={showFriends}
            showPreview={showPreview}
            togglePreview={togglePreview}
            isFullScreen={isFullScreen}
            isOpenPreview={isOpenPreview}
            componentPlace={componentPlace}
        />
    );

    return null;

    /////
    if (isFriends) {
        return (
            <div
                className={cn(classes.previewGame, appearanceTheme, {
                    isFriends: isFriends,
                })}
            >
                <TabContext value={currentTab}>
                    <Tabs
                        onChange={handleTabChange}
                        value={currentTab}
                        className={cn(
                            classes.chatElementsWrapper,
                            appearanceTheme
                        )}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0)",
                            },
                        }}
                    >
                        {authStore.isAuthorized && (
                            <Tab
                                icon={<PeopleIcon fontSize="large" />}
                                key={EPreviewBlock.friends}
                                value={EPreviewBlock.friends}
                                aria-label={EPreviewBlock.friends}
                            />
                        )}

                        <Tab
                            icon={
                                <TournamentTopPlayersIcon
                                    fillColor={prizeModalGold}
                                    width={34}
                                    height={26}
                                />
                            }
                            value={EPreviewBlock.rating}
                            key={EPreviewBlock.rating}
                            aria-label={EPreviewBlock.rating}
                        />
                    </Tabs>

                    {authStore.isAuthorized && (
                        <TabPanel
                            value={EPreviewBlock.friends}
                            style={{
                                height: "calc(100% - 45px)",
                                padding: "0px 10px 10px 10px",
                            }}
                        >
                            <div
                                className={cn(
                                    classes.previewGameContent,
                                    appearanceTheme,
                                    {
                                        isFriends: isFriends,
                                    }
                                )}
                            >
                                <FriendsView
                                    showFriends={showFriends}
                                    showPreview={showPreview}
                                />
                            </div>
                        </TabPanel>
                    )}
                    <TabPanel
                        value={EPreviewBlock.rating}
                        style={{
                            height: "calc(100% - 45px)",
                            padding: "0px 10px 10px 10px",
                        }}
                    >
                        <div
                            className={cn(
                                classes.previewGameContent,
                                appearanceTheme,
                                {
                                    isFriends: isFriends,
                                }
                            )}
                        >
                            <TopPlayers
                                isLobbyPage
                                paginationLimit={20}
                                maxCountOfPaganation={4}
                                topCountTitle={100}
                            />
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        );
    } else if (
        game?.status === GameStatus.IN_PROGRESS ||
        game?.status === GameStatus.FINISHED ||
        game?.status === GameStatus.ABOUT_TO_START ||
        lobbyStore.isRematchGame
    ) {
        return (
            <GameInProgressPreview
                showFriends={showFriends}
                showPreview={showPreview}
            />
        );
    }
    return null;
};

export default observer(GamePreview);
