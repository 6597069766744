import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
const clientWidth = window.innerWidth;
const clientHeight = window.innerHeight;
import theme from "src/theme";
import { DARKGREEN_ALERTS, MIN_XL_TABLET } from "src/pages/game-new2/constants";

export const useStyles = makeStyles({
    watchingUsersIcon: {},

    watchingUsersCount: {
        fontWeight: 700,
    },

    noWatchersTitle: {
        fontSize: "30px",
        display: "inline-block",
        marginTop: "0%",
        color: "#ffffff8c",
        [theme.breakpoints.down("lg")]: {
            marginTop: "1%",
        },
        [theme.breakpoints.up(1920)]: {
            fontSize: "38px",
        },
        [theme.breakpoints.up(2560)]: {
            fontSize: "42px",
        },
    },

    watchingListShow: {
        position: "absolute",
        width: "100%",
        height: "200px",
        opacity: 1,
        background: "#353A57",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        bottom: 0,
        borderRadius: "24px",
        animationName: "$watchingListShow",
        animationDelay: "0s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "300ms",
        "&.darkBlue": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%)",
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            backdropFilter: "blur(12px)",
            background:
                "linear-gradient(0deg, rgba(18, 18, 18, 0.8), rgba(18, 18, 18, 0.8)), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
        },
    },

    watchingListHide: {
        position: "absolute",
        width: "100%",
        height: "50px",
        background: "#353A57",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        bottom: "calc(clientHeight - 150px)",
        borderRadius: "24px",
        animationName: "$watchingListHide",
        animationDelay: "0s",
        transform: `translateY(-200%)`,
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "200ms",
        opacity: 0,
        zIndex: 2,
        "&.darkBlue": {
            background: backgroundColors.darkBlue,
            border: "1px solid #353A57",
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            background: backgroundColors.darkBlue,
            border: "1px solid #353A57",
        },
    },

    "@keyframes watchingListShow": {
        "0%": {
            transform: "translateY(150px)",
            zIndex: 2,
        },
        "100%": {
            transform: "translateY(0)",
            zIndex: 2,
        },
    },

    "@keyframes watchingListHide": {
        "0%": {
            transform: `translateY(clientHeight)`,
        },
        "100%": {
            transform: "translateY(150px)",
            zIndex: 0,
        },
    },

    watchingListReload: {
        position: "absolute",
        width: "100%",
        height: "0px",
        background: "#353A57",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        bottom: 0,
        borderRadius: "24px",
        transform: `translateY(${150}px)`,
        opacity: 0,
        "&.darkBlue": {
            background: backgroundColors.darkBlue,
            border: "1px solid #353A57",
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            background: backgroundColors.darkBlue,
            border: "1px solid #353A57",
        },
    },

    watchingListContainerDesktop: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: 0,
        position: "relative",
        "&.deepBlack": {},
    },

    watchingMainGrid: {
        maxWidth: "100%",

        overflowY: "scroll",
        margin: "0 !important",

        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        height: "100%",
        maxHeight: "100%",
        padding: "0px",
        marginBottom: "0px",
        border: `1px solid ${appColors.semiBlack}`,
        "&.deepBlack": {
            height: "100%",
            maxHeight: "100%",
            padding: "0px",
            marginBottom: "0px",
            border: `1px solid ${appColors.semiBlack}`,
        },
    },

    watchingMainContainer: {
        height: "100%",
        maxHeight: "100%",
        padding: "0px",
        marginBottom: "0px",
        maxWidth: "100%",
        overflowY: "scroll",
        margin: "0 !important",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        // paddingLeft: "20px",
        // paddingRight: "5px",
        [theme.breakpoints.down("lg")]: {
            borderRadius: "12px",
            borderTop: "1px solid rgba(100,100,100)",
        },
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            padding: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        "&.inGame": {
            padding: "24px",
            [theme.breakpoints.down("lg")]: {
                paddingTop: "56px",
            },
            [theme.breakpoints.up("lg")]: {
                paddingTop: "12px",
                padding: "0px",
            },
        },
        "&.inProgressPreview": {
            padding: "12px",
            [theme.breakpoints.down("lg")]: {
                paddingTop: "56px",
            },
        },
        "&.inProgressPreviewFull": {
            [theme.breakpoints.up("lg")]: {
                paddingTop: "12px",
                padding: "0px",
                position: "static",
                // height: "calc(100% - 12px)",
                display: "flex",
                flexDirection: "column",
            },
        },
    },

    watchingBox: {
        dispay: "flex",
        flexDirection: "column",
        overflow: "scroll",
        width: "100%",
        height: "100%",
        padding: "0px 5%",
        alignItems: "center",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        [theme.breakpoints.down("lg")]: {
            padding: "0px 5%",
            borderRadius: "12px",
            border: "1px solid rgba(110,110,110)",
        },
        "&.inProgressPreviewFull": {
            [theme.breakpoints.up("lg")]: {
                height: "98%",
                padding: "0px 3%",
                borderRadius: "12px",
                border: "1px solid rgba(110,110,110)",
            },
        },
    },

    watchingUserBox: {
        width: "100%",
        height: "fit-content",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        justifyItems: "center",
        alignItems: "center",
        alignSelf: "center",
        fontWeight: 500,
        fontSize: "11px",
        color: appColors.white,
        lineHeight: "0px",
        oveflow: "hidden",
        gridGap: 15,
        margin: "8px 0px",
        padding: 5,
        borderBottom: `1px solid #B6B6B673`,
        "& > p": {
            width: "100%",
            maskImage: "linear-gradient(to left, transparent 1%, black 25%)",
            margin: 0,
            marginTop: 5,
            textAlign: "center",
        },
        "&.deepBlack": {
            width: "100%",
            height: "fit-content",
            flexDirection: "row",
            justifyContent: "flex-start",
            gridGap: 15,
            margin: "8px 0px",
            padding: 5,
            borderBottom: `1px solid #B6B6B673`,
        },
    },

    watchingUserAvatar: {
        height: "100%",
        borderRadius: "50%",
        // marginBottom: "16px",
        width: "14.5%",
        aspectRatio: "1 / 1",
        border: `2px solid ${appColors.white}`,
        minWidth: "60px",
        maxWidth: "90px",
        [theme.breakpoints.down("xl")]: {
            width: "14.5%",
            aspectRatio: "1 / 1",
        },
        "&.deepBlack": {
            width: "14.5%",
            aspectRatio: "1 / 1",
            border: `2px solid ${appColors.white}`,
            [theme.breakpoints.down("xl")]: {
                minWidth: "50px",
                width: "25%",
                aspectRatio: "1 / 1",
            },
        },
        [theme.breakpoints.down("lg")]: {
            width: "25%",
        },
    },

    watchingUserTextBox: {
        display: "flex",
        width: "100%",
        marginBottom: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "4px",
        },
    },

    watchingUserNick: {
        height: "100%",
        margin: 0,
        marginTop: 5,
        marginBottom: 6,
        padding: 0,
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "left",
        [theme.breakpoints.down("xl")]: {
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
        },
        [theme.breakpoints.up(2400)]: {
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "28px",
        },
        [theme.breakpoints.up(3000)]: {
            fontSize: "28px",
            fontWeight: 700,
            lineHeight: "32px",
        },
        "&.inBracketPreview": {
            marginTop: 0,
            marginBottom: 0,
            color: "rgba(255, 255, 255, 0.65)",
        },
    },

    watchingUserSpanRank: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gridGap: "4px",
        "&.inBracketPreview": {
            color: "rgba(255, 255, 255, 0.65)",
        },
    },

    watchingUserRank: {
        margin: 0,
        padding: 0,
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "24px",
        "&.rank": {
            color: `${appColors.white}`,
            "&.inBracketPreview": {
              color: "rgba(255, 255, 255, 0.65)",
          },
        },
        "&.rankPoint": {
            color: "#F5F5F599",
        },
        "&.rankPointNumber": {
            fontWeight: 600,
            color: "rgba(245, 245, 245, 0.9)",
        },
        [theme.breakpoints.down("xl")]: {
            fontSize: "16px",
            lineHeight: "20px",
            "&.rankPoint": {
                textALign: "left",
            },
        },
    },

    watchingToggler: {
        padding: "2px 10px",
        fontSize: 12,
    },
});
