import { Dialog } from "@mui/material";
import React, { useState } from "react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import { ButtonType, SteelButton } from "src/components/buttons/SteelButton";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    errorService,
    infoService,
    notificationService,
} from "src/service/services";
import okIcon from "src/assets/icons/ok.svg";

import { useModalControls } from "../../hooks/useModalControls";

import { useStyles } from "./styles";

const DeleteFriendModal = () => {
    const classes = useStyles();
    const { friendsStore } = useStores();
    const { changeModalState } = useModalControls();
    const appearanceTheme = useChangeTheme();
    const {
        friends: {
            deleteFriend: {
                successfullyDeleted,
                deleteFriendWarning,
                deleteFriendTitle,
                deleteButton,
                cancelButton,
            },
        },
    } = useLocale();
    const [isDisabled, setIsDisabled] = useState(false);

    const selectedFriend = friendsStore.selectedFriend;

    if (!selectedFriend) return null;

    const handleCloseModal = () => {
        changeModalState("deleteFriend", false);
    };

    const deleteFriend = async () => {
        setIsDisabled(true);
        try {
            await friendsStore.removeFriend({ playerTo: selectedFriend.id });
            await friendsStore.getFriends();
            notificationService.sendNotification(
                successfullyDeleted.compile(
                    { nickname: selectedFriend.name },
                    `{{nickname}} is removed`
                ),
                okIcon
            );
        } catch (e) {
            errorService.sendError("Some Error Happened");
        } finally {
            setIsDisabled(false);
            handleCloseModal();
        }
    };
    return (
        <Dialog
            open={true}
            maxWidth={false}
            className={classes.dialog}
            onClose={handleCloseModal}
            PaperProps={{
                className: cn(classes.dialogPaper, appearanceTheme),
            }}
        >
            <h1 className={classes.title}>
                {deleteFriendTitle("Delete a friend")}
            </h1>
            <p className={classes.text}>
                {deleteFriendWarning("Are you sure you want to remove")}
                <span> {selectedFriend.name}?</span>
            </p>
            <div className={classes.buttonGroup}>
                <SteelButton
                    bgColor={ButtonType.primary}
                    text={cancelButton("Cancel")}
                    className={classes.button}
                    fontSize={16}
                    onClick={handleCloseModal}
                    disabled={isDisabled}
                />
                <SteelButton
                    bgColor={ButtonType.negative}
                    text={deleteButton("Delete")}
                    className={classes.button}
                    fontSize={16}
                    onClick={deleteFriend}
                    loading={isDisabled}
                />
            </div>
        </Dialog>
    );
};

export default DeleteFriendModal;
