import React, { useCallback, useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import cx from "classnames";
import _get from "lodash/get";
import Countdown, { zeroPad } from "react-countdown";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import deleteIcon from "src/assets/icons/close.svg";

import useStores from "src/hooks/useStores";
import { errorService, infoService } from "src/service/services";

import errorIcon from "../assets/icons/error.svg";
import { useModalStyles } from "../styles";
import TextInput from "../TextInput";
import { MainActionButton } from "../../buttons/newDesignButtons/mainActionButton";

type ChangeNicknameModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const useStyles = makeStyles({
    buttonBox: {
        justifySelf: "flex-start",
        marginTop: 8,
        width: '100%',
    },
    form: {
        gridRowGap: 8,
    },
    currentNickname: {
        fontSize: 16,
        fontWeight: 400,
        color: '#e1e1e1',
        marginBottom: 16,
        fontFamily: 'Montserrat',
        "& a": {
            cursor: "pointer",
            position: "relative",
            fontWeight: 700,
            color: '#e1e1e1',
            "&:before": {
                content: '""',
                width: "100%",
                height: 0.5,
                background: 'transparent',
                position: "absolute",
                left: 0,
                bottom: 0,
            },
        },
    },
    errorBox: {
        fontSize: 14,
        fontWeight: 400,
        color: "#eb5757",
        display: "flex",
        alignItems: "center",
    },
    errorIcon: {
        width: 16,
        height: 16,
        objectFit: "contain",
        marginRight: 4,
    },
    disabledButton: {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
    timer: {
        marginTop: 10,
        color: "#ffffff",
        fontSize: 14,
        marginBottom: 16,
    },
    timerText: {
        marginTop: 8,
        fontSize: 12,
        color: "#ffffff",
    },
});

type FormValuesType = {
    nickname: string;
};

const initialValues: FormValuesType = {
    nickname: "",
};

const ChangeNicknameModal = ({ isOpen, onClose }: ChangeNicknameModalProps) => {
    const { authStore } = useStores();
    const monthTime = 2592000000;
    const [isTimePassed, setIsTimePassed] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        profile: {
            nickname: {
                changeNickname,
                yourNickname,
                form: { placeNewNickname, errors },
                saveButton,
                successFullyChanged,
                aviliableIn,
            },
        },
    } = useLocale();
    const localStyles = useStyles();
    const styles = useModalStyles();

    const checkTimeRemaining = useCallback(() => {
        const lastNicknameChange = authStore?.currentUser?.nicknameChangedAt;
        if (!lastNicknameChange) {
            setIsTimePassed(true);
            setTimeRemaining(null);
            return;
        }
        const timeElapsed = Date.now() - Date.parse(lastNicknameChange);
        if (timeElapsed >= monthTime) {
            setIsTimePassed(true);
            setTimeRemaining(null);
        } else {
            setIsTimePassed(false);
            setTimeRemaining(monthTime - timeElapsed);
        }
    }, [authStore?.currentUser?.nicknameChangedAt]);

    useEffect(() => {
        checkTimeRemaining();
        const interval = setInterval(checkTimeRemaining, 1000);
        return () => clearInterval(interval);
    }, [checkTimeRemaining]);

    const handleSubmitNick = async (values: FormValuesType, actions: any) => {
        if (isSubmitting) return; 

        try {
            setIsSubmitting(true);
            await authStore.changeNickname(values.nickname);
            infoService.sendInfo(successFullyChanged("Your nickname has been successfully changed"));
            onClose();
            setError(null);
            setTimeRemaining(null);
        } catch (e) {
            if (e.response?.data?.error === "change_nickname_cooldown") {
                const cooldownEndTime = new Date(authStore.currentUser?.nicknameChangedAt!).getTime() + monthTime;
                const currentTime = Date.now();
                const remainingTime = cooldownEndTime - currentTime;
                setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
                setIsTimePassed(false);
                setError("You cannot change your nickname yet. Please wait until the cooldown period ends.");
            } else {
                setError("An error occurred while changing your nickname. Please try again.");
                setIsTimePassed(true);
                setTimeRemaining(null);
            }
        } finally {
            setIsSubmitting(false);
            actions.setSubmitting(false);
        }
    };

    const timerRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            checkTimeRemaining();
            return null;
        }
        return (
            <span className={localStyles.timerText}>
                {aviliableIn("Available in")}: {days}d {hours}h {minutes}m {seconds}s
            </span>
        );
    };

    return (
        <Modal open={isOpen} onClose={onClose} className={styles.modalWrapper}>
            <div className={cx(styles.modalNickname, "changeNicknameModal")}>
                <div className={styles.modalTopBox}>
                    <p className={styles.titleNickname}>
                        {changeNickname("Change nickname")}
                    </p>
                    <img onClick={onClose} src={deleteIcon} className={styles.closeIcon} alt="close" />
                </div>
                <div className={styles.modalContent}>
                    <p className={localStyles.currentNickname}>
                        {yourNickname("Your current nickname is")}:{" "}
                        <a>{authStore.currentUser?.nickname}</a>
                    </p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={yup.object({
                            nickname: yup
                                .string()
                                .required(errors.required("Nickname is required"))
                                .matches(
                                    /^[a-zA-Z0-9_-]+$/,
                                    errors.notCorrectFormat('Please use only English letters, numbers, "_" and "-"')
                                )
                                .max(25, errors.tooLong("Nickname is too long"))
                                .min(2, errors.tooShort("Nickname is too short")),
                        })}
                        onSubmit={handleSubmitNick}
                    >
                        {(formikProps) => (
                            <form className={cx(styles.form, localStyles.form)} onSubmit={formikProps.handleSubmit}>
                                <TextInput
                                    name="nickname"
                                    label={placeNewNickname("New Nickname")}
                                    disabled={!isTimePassed || isSubmitting}
                                />
                                {(formikProps.errors.nickname || error) && (
                                    <div className={localStyles.errorBox}>
                                        <div>
                                            <img src={errorIcon} className={localStyles.errorIcon} alt="error" />{" "}
                                            {formikProps.errors.nickname || error}
                                        </div>
                                        {!isTimePassed && timeRemaining !== null && (
                                            <Countdown
                                                date={Date.now() + timeRemaining}
                                                renderer={timerRenderer}
                                                onComplete={() => setIsTimePassed(true)}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className={localStyles.buttonBox}>
                                    <MainActionButton
                                        appearanceTheme="primary"
                                        text={saveButton("Save")}
                                        className={cx(styles.changeButton, {
                                            [localStyles.disabledButton]: !isTimePassed || isSubmitting
                                        })}
                                        loading={isSubmitting}
                                        disabled={!isTimePassed || isSubmitting}
                                        buttonType="submit"
                                    />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export default observer(ChangeNicknameModal);