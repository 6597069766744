import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useField, useFormikContext } from "formik";
import Cropper, { Area, Point } from "react-easy-crop";

import { useLocale } from "src/providers/LocaleProvider";
import { appColors } from "src/consts/app-config";
import { createImage, getCroppedImg } from "src/utils/get-cropped-image";
import { errorService } from "src/service/services";

import { MainActionButton } from "src/components/buttons/newDesignButtons/mainActionButton";

import { useStyles as useModalStyles } from "./styles";

const useStyles = makeStyles({
    imageWrapper: {
        width: 300,
        height: 300,
        borderRadius: 8,
        position: "relative",
    },
    imagePreview: {
        width: "100%",
        objectFit: "contain",
    },
    containerCropAvatar: {
        width: "100%",
        maxHeight: 360,
        "&.darkBlue": {
            background: appColors.bg800,
        },
        "&.darkGreen": {
            background:
                "linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)",
        },
    },
    mediaClassName: {},
    cropAreaClassName: {
        boxShadow: "none!important",
        borderRadius: "50%",
        border: "2px dashed rgb(89, 104, 143)!important",
    },
});

const ImageCrop = ({ goPrevStep }: any) => {
    const styles = useStyles();
    const {
        profile: {
            changeAvatar: { saveButton, backButton, unknownError },
        },
    } = useLocale();
    const { submitForm, isSubmitting } = useFormikContext();

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState<any>({});

    const imageRef = useRef<HTMLImageElement | null>(null);
    const changeAvatarModalStyles = useModalStyles();
    const [croppedImageMeta, , croppedImageHelpers] = useField("croppedImage");
    const [avatarMeta] = useField("avatar");
    const [base64AvatarMeta] = useField("base64Avatar");

    useEffect(() => {
        (async () => {
            try {
                const image = await createImage(base64AvatarMeta.value);
                imageRef.current = image;
            } catch (e) {
                errorService.sendError(unknownError("Неизвестная ошибка"));
            }
        })();
    }, []);

    const handleSubmit = async () => {
        if (imageRef.current && croppedArea.width && croppedArea.height) {
            try {
                const croppedAvatar = await getCroppedImg(
                    imageRef.current as HTMLImageElement,
                    avatarMeta.value,
                    croppedArea
                );
                if (!croppedAvatar) return null;
                croppedImageHelpers.setValue(croppedAvatar.blob);
            } catch (e) {
                errorService.sendError(unknownError("Неизвестная ошибка"));
            }
        }
    };

    useEffect(() => {
        if (!croppedImageMeta.value) return;
        submitForm();
    }, [croppedImageMeta.value]);

    const onCropComplete = (_, croppedAreaPixels: Area) => {
        const pixelRatio = window.devicePixelRatio;
        const scaleX =
            (imageRef?.current?.naturalWidth || 1) /
            (imageRef?.current?.width || 1);
        const scaleY =
            (imageRef?.current?.naturalHeight || 1) /
            (imageRef?.current?.height || 1);
        const canvasWidth = croppedAreaPixels.width * pixelRatio * scaleX;
        const canvasHeight = croppedAreaPixels.height * pixelRatio * scaleY;

        let cropWidth = croppedAreaPixels.width;
        let cropHeight = croppedAreaPixels.height;
        if (canvasWidth > 4096 || canvasHeight > 4096) {
            cropWidth = 4096 / (pixelRatio * scaleX);
            cropHeight = 4096 / (pixelRatio * scaleY);
        }

        setCroppedArea({
            ...croppedAreaPixels,
            width: cropWidth,
            height: cropHeight,
        });
    };

    return (
        <Box className={changeAvatarModalStyles.stepAreaWrapper}>
            <Box className={styles.imageWrapper}>
                <Cropper
                    image={base64AvatarMeta.value}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    classes={{
                        containerClassName: styles.containerCropAvatar,
                        mediaClassName: styles.mediaClassName,
                        cropAreaClassName: styles.cropAreaClassName,
                    }}
                />
            </Box>
            <div className={changeAvatarModalStyles.buttonBox}>
                <MainActionButton
                    appearanceTheme="primary"
                    text={saveButton("Save")}
                    className={changeAvatarModalStyles.saveButton}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                />
                <MainActionButton
                    appearanceTheme="primary"
                    text={backButton("Back")}
                    className={changeAvatarModalStyles.clearButton}
                    onClick={goPrevStep}
                />
            </div>
        </Box>
    );
};

export default ImageCrop;
