import React from 'react';
import { useStyles } from './styles';

type RequestsCountBadgeProps = {
    count: number;
};

const RequestsCountBadge = ({ count }: RequestsCountBadgeProps) => {
    const styles = useStyles();
    return <div className={styles.requestsCount}>{count}</div>;
};

export default RequestsCountBadge;
