import { useMemo } from "react";

import useStores from "src/hooks/useStores";

export const usePlayerColor = (userId?: string | undefined) => {
    const { achievementsStatStore } = useStores();
    const existPlayerSettings =
        achievementsStatStore.getMyCurrentOpponent(userId);
    const boxShadowColor = useMemo(() => {
        if (!userId) return "transparent";
        if (existPlayerSettings.color) {
            return existPlayerSettings.color;
        }
        return "transparent";
    }, [userId, existPlayerSettings.color]);
    return boxShadowColor;
};
