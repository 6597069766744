import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import _get from "lodash/get";
import cn from "classnames";
import { observer } from "mobx-react";
import { Box, useMediaQuery } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";

import MobileWatchingList from "src/components/WatchingPlayers/components/WatchingList/MobileList/mobileList";
import useStores from "src/hooks/useStores";
import { getBeatCounter } from "src/utils/getBeatCounter";
import useWindowSize from "src/hooks/useWindowSize";
import useChangeTheme from "src/hooks/useChangeTheme";
import BoardV2 from "src/components/BoardV2";
import { Spinner } from "src/components/spinner";
import GameScoreRedesign from "src/components/gameScoreRedesign";
import ViewModeModals from "src/pages/game-new2/components/modals/index";

import {
  WatchingType,
  WatchersStatusType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import {
  GameModeType,
  GameModeTypeNew,
  ColorVariant,
  ChessboardType,
  ScreenType,
} from "src/store/models";

import PlayerInfo from "src/components/PlayerInfoNew";
import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";
import Chessboard from "src/pages/game-new2/layouts/components/center/chessboard-custom";
import shortColor from "src/utils/shortColor";
import MobilePotView from "src/pages/lobby/components/GamePreview/GameInProgressPreview/components/betAndTime";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";
import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/styles";
import { PlayerInfoExistPosition } from "src/store/models";
import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
  MIN_CHANGE_SCREEN_WIDTH,
  MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import ActionAndReturn from "src/pages/game-new2/components/actionAndReturn";

import { EColor } from "src/components/types";
import RiseAndCoinAnimation from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation";

interface IViewChessboardProps {
  players: any;
  playersOnline: any;
  rndPrefix: number;
  diceClickRnd: number;
  elemHeight: number;
  piecesSize: number;
  mobileTabletLayoutContainer: React.RefObject<HTMLDivElement>;
}

const ViewChessTableMobile = (props: any) => {
  const {
    rndPrefix,
    diceClickRnd,
    mobileTabletLayoutContainer,
  }: IViewChessboardProps = props;
  const { gameStore } = useStores();

  const gameContainerWidth =
    mobileTabletLayoutContainer?.current?.offsetWidth;
  const gameContainerHeight =
    mobileTabletLayoutContainer?.current?.offsetHeight;

  const refBoardView = useRef<HTMLInputElement>(null);

  const showWatchersBlock = gameStore.getShowWatchersBlock;
  // const gameType = gameStore.currentGame?.type;
  const gameType = gameStore?.currentGameNew?.type;
  const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
  const isTournamentGame = gameType === GameModeTypeNew.TOURNAMENT;
  const diceWrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const pairs = gameStore.currentGameNew?.tournament?.pairs;
  const { chessBoardWidth, isMultiTable } = useChessBoardProps();
  const windowSize = useWindowSize();
  const [elemHeight, setHeight] = useState<any>();
  // const [piecesSize, setPiecesSize] = useState<any>();

  const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
  const actualWidth = window.innerWidth;
  const appearanceTheme = useChangeTheme();
  const changeScreenStyles = useMediaQuery(`(max-width: ${MAX_XL_TABLET}px)`);

  //=======
  const resign = gameStore?.gameState?.gameResultData?.cause;
  const winner = gameStore?.currentGameNew?.winner;
  const extra = gameStore?.currentGameNew?.extra;
  const isLeftBet = !!extra && !!extra.leftBet;
  const isRightBet = !!extra && !!extra.rightBet;
  const nextMatch = gameStore?.currentGameNew?.nextMatch;
  // const game = lobbyStore.selectedGame;
  const game = gameStore.currentGameNew;
  const watchers = gameStore.currentGameNew?.viewers || [];
  const ref = useRef<HTMLDivElement>(null);
  const playerInfoRef = useRef<HTMLDivElement>(null);
  const chessBoardRef = useRef<HTMLDivElement>(null);

  const activeClock = gameStore?.gameState?.activeClockView;
  const pWhitePieces = gameStore?.currentGameNew?.players[0];
  const pBlackPieces = gameStore?.currentGameNew?.players[1];

  const classes = useStyles({ elemHeight, resign });

  const killedPieces = useMemo(() => {
    return gameStore.gameState.killedPiecesNew;
  }, [gameStore.stateNew.movesHistory]);

  const piecesBeatCounter = useMemo(() => {
    const data = getBeatCounter(killedPieces);
    return data;
  }, [killedPieces]);

  const initTimeWhitePieces = useMemo(() => {
    if (!gameStore?.rightBlockCollector.clockData.length) return 0;
    const getOpponentTime = 0;
    const timeLeftMemo =
      gameStore?.rightBlockCollector.clockData[getOpponentTime];
    return timeLeftMemo || 0;
  }, [
    gameStore?.rightBlockCollector.clockData,
    gameStore.gameId,
    activeClock,
  ]);
  const initTimeBlackPieces = useMemo(() => {
    if (!gameStore?.rightBlockCollector.clockData.length) return 0;
    const getOpponentTime = 1;
    const timeLeftMemo =
      gameStore?.rightBlockCollector.clockData[getOpponentTime];
    return timeLeftMemo || 0;
  }, [
    gameStore?.rightBlockCollector.clockData,
    gameStore.gameId,
    activeClock,
  ]);

  // useViewPiecesAndBoardSize(
  //     gameType,
  //     currentChessBorardHeight,
  //     setHeight,
  //     chessBoardRef,
  //     chessBoardWidth,
  //     windowSize,
  //     orientation
  // );

  useEffect(() => {
    if (nextMatch) {
      gameStore.resetGame();
      gameStore.clearSocket();
      history.push(`/game/${nextMatch}`);
    }
  }, [nextMatch]);

  const viewMode = gameStore.isViewMode();

  const fen = gameStore.gameState.fen;

  const setOpenUseCallback = useCallback(
    (properties) => setOpen(properties),
    []
  );

  useEffect(() => {
    if (isOpen === WatchersStatusType.hide) {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  }, [isOpen]);

  const getCenterSize = <
    T extends number | undefined,
    U extends number | undefined
  >(
    gameContainerWidth: T,
    gameContainerHeight: U
  ): string => {
    if (gameContainerWidth && gameContainerHeight) {
      let calcSize = Math.min(gameContainerWidth, gameContainerHeight);
      //@ts-ignore
      if (gameContainerHeight - gameContainerWidth < 290) {
        //@ts-ignore
        return `${gameContainerHeight - 290}px`;
      }

      return `${calcSize}px`;
    }
    return "calc((100dvh - 80px) / 3)";
  };

  const getTopBottomSize = <
    T extends number | undefined,
    U extends number | undefined
  >(
    gameContainerWidth: T,
    gameContainerHeight: U
  ): string | undefined => {
    if (gameContainerWidth && gameContainerHeight) {
      //@ts-ignore
      if (gameContainerHeight / 3 >= gameContainerWidth) {
        return `${gameContainerWidth}px`;
      }
    }
    return;
  };

  const calcDiceSize = <
    T extends number | undefined,
    U extends number | undefined
  >(
    diceWrapperWidthParams: T,
    diceWrapperHeightParams: U
  ): number => {
    if (diceWrapperWidthParams && diceWrapperHeightParams) {
      return (
        //@ts-ignore
        Math.min(diceWrapperWidthParams / 3, diceWrapperHeightParams) *
        0.7
      );
    }
    return 50;
  };

  const centerSize = useMemo(
    () => getCenterSize(gameContainerWidth, gameContainerHeight),
    [gameContainerWidth, gameContainerHeight]
  );

  const topBottomSize = useMemo(
    () => getTopBottomSize(gameContainerWidth, gameContainerHeight),
    [gameContainerWidth, gameContainerHeight]
  );

  useEffect(() => {
    let actualSize = calcDiceSize(
      gameContainerWidth,
      diceWrapperRef?.current?.offsetHeight
    );
    gameStore.setPiecesSize(actualSize, ScreenType.mobile);
  }, [
    gameContainerWidth,
    gameContainerHeight,
    diceWrapperRef?.current?.offsetWidth,
    diceWrapperRef?.current?.offsetHeight,
  ]);

  const stage = useMemo(() => {
    if (
      isTournamentGame &&
      pairs
  ) {
      const avangardData = pairs.reduce(
          (c, pair) => c + (pair.step === 1 ? 1 : 0),
          0
      );

      const arergardData =
          1 <<
          ((pairs.find(
                  (pair) => pair.gameId == gameStore.currentGameNew?._id
              )?.step || 1) -
              1);

      const stageCalc = avangardData / arergardData;
      return stageCalc;
  }
  return null;
  }, [gameStore.currentGameNew?._id, pairs, isTournamentGame])

  if (!gameStore.isLoadedPreview) {
    return (
      <Spinner />
      // <div className={cn(classes.previewGame, appearanceTheme)}>
      //     <div className={classes.gameNotSelect}>
      //         <Spinner />
      //     </div>
      // </div>
    );
  }

  if (!pBlackPieces || !pWhitePieces) return null;

  return (
    <>
      <div
        className={classes.mainMobileGameContainerNew}
        style={{ gridTemplateRows: `1fr ${centerSize} 1fr` }}
      >
        <div
          className={classes.mobileTabletTopBox}
          style={{ height: topBottomSize }}
        >
          <div
            className={cn(
              classes.playerInfoWrapperNew,
              "top",
              classes.opponentInfoWrapperNew,
              "isViewMode"
            )}
          >
            <Box
              component="div"
              className={cn(
                classes.presentAndNavigationBox,
                "top",
                {
                  isTournamentGame: isTournamentGame,
                  isMatchGame: isMatchGame,
                }
              )}
            >
            {(gameStore.currentGame?.settings?.rounds! > 1 ||
                gameType === GameModeTypeNew.MATCH) && (
                <GameScoreRedesign
                    winner={winner}
                    stage={
                        gameStore?.currentGame?.tournament?.stage
                    }
                    // @ts-ignore
                    title={
                        gameStore?.currentGame?.tournament?.title
                    }
                />
            )}
            {isTournamentGame && (
                <GameScoreRedesign
                    winner={winner}
                    stage={
                      stage !== null ? stage : 1
                    }
                    title={
                        gameStore?.currentGameNew?.tournament.title
                    }
                    isView={true}
                />
            )}
            {
              !isTournamentGame && gameType !== GameModeTypeNew.MATCH && (<div
                className={cn(
                  classes.bankWrapperNew,
                  "isViewMode",
                  {
                    activeInit: isLeftBet,
                    isWinnerWithReject:
                      isLeftBet && !isRightBet && winner,
                    acceptedAndFinished:
                      isLeftBet && isRightBet && !winner,
                    isWinnerAndHideBank:
                      winner && isRightBet,
                    solidWinner:
                      !isRightBet && !isLeftBet && winner,
                  }
                )}
              >
                <RiseAndCoinAnimation
                  chessboardType={ChessboardType.desktopGame}
                  view
                />
              </div>)
            }
            </Box>
            <Box
              component="div"
              className={cn(classes.playerInfoInnerWrapperNew, {
                viewMode,
                isMultiTable,
              })}
            >
              {viewMode && (
                <div
                  className={cn(
                    classes.dicesAndAlertHolderNew,
                    {}
                  )}
                >
                  <Box
                    component="div"
                    className={classes.dicesAlertsBoxNew}
                  ></Box>
                  <div
                    ref={diceWrapperRef}
                    className={cn(classes.dicesWrapperNew, {
                      // isTournamentGame:
                      //     isMultiRoundGame,
                      // isShowWinOrLostDialog:
                      //     isShowWinOrLostDialog &&
                      //     isMultiRoundGame,
                      // doubleThinking:
                      //     doubleThinking,
                    })}
                  >
                    <DicesWrapper
                      rndPrefix={props.rndPrefix}
                      diceClickRnd={props.diceClickRnd}
                      standOffColor={props.standOffColor}
                      myColor={ColorVariant.white}
                      isMultiTable={isMultiTable}
                      viewMode={true}
                      opponentColor={ColorVariant.white}
                    />
                  </div>
                </div>
              )}
              <div
                className={cn(classes.playerInfoBoxNew, {
                  [classes.viewModePlayerInfoBoxNew]:
                    viewMode,
                  isMultiTable,
                })}
                style={{
                  // width: centerSize,
                  width: "100%",
                  minWidth: 320,
                }}
              >
                <div
                  className={cn(
                    classes.viewModeModalsWrapper,
                    PlayerInfoExistPosition.inLobbyPreview,
                    "top"
                  )}
                >
                  <ViewModeModals player={pWhitePieces} />
                </div>
                <PlayerInfoRedesign
                  resign={resign}
                  winner={winner}
                  noTimerSound
                  avatar={pBlackPieces.avatar}
                  name={pBlackPieces.nickname || "Unknown"}
                  initTime={initTimeBlackPieces}
                  activeClock={activeClock}
                  active={gameStore.isActiveViewPlayer(
                    pBlackPieces?._id
                  )}
                  id={
                    pBlackPieces._id
                  }
                  killedPieces={
                    killedPieces[ColorVariant.white]
                  }
                  color={ColorVariant.black}
                  isSystemAvatar={pBlackPieces.isSystemAvatar}
                  piecesBeatCounter={
                    piecesBeatCounter[ColorVariant.black]
                  }
                  elementPlace={
                    PlayerInfoExistPosition.inLobbyPreview
                  }
                  gameId={gameStore?.currentGameNew?._id}
                />
              </div>
            </Box>
          </div>
        </div>

        <Box
          component="div"
          className={classes.mobileTabletCenterBox}
          // style={
          //   winner ? { opacity: 0.99, zIndex: -10 } : { opacity: 1 }
          // }
        >
          {/* <Chessboard
            marginTop={0}
            marginBottom={0}
            border
            fen={fen.split(" ")[0]}
            myColor={ColorVariant.white}
            viewMode={viewMode}
            isMultiTable={false}
          /> */}
          <BoardV2 />
        </Box>

        <Box
          className={classes.mobileTabletBottomBox}
          style={{ height: topBottomSize }}
        >
          <div
            className={classes.playerInfoWrapperNew}
            style={{
              zIndex: 6,
            }}
          >
            <div
              className={cn(classes.playerInfoInnerWrapperNew, {
                viewMode,
                isMultiTable,
              })}
            >
              <div
                className={cn(classes.playerInfoBoxNew, {
                  [classes.viewModePlayerInfoBoxNew]:
                    viewMode,
                  isMultiTable,
                })}
              >
                <div
                  className={cn(
                    classes.viewModeModalsWrapper,
                    PlayerInfoExistPosition.inLobbyPreview,
                    "bottom"
                  )}
                >
                  <ViewModeModals player={pBlackPieces} />
                </div>
                <PlayerInfoRedesign
                  resign={resign}
                  winner={winner}
                  noTimerSound={false}
                  avatar={
                    //@ts-ignore
                    pWhitePieces.avatar
                  }
                  name={
                    //@ts-ignore
                    pWhitePieces.nickname || "Unknown"
                  }
                  initTime={initTimeWhitePieces}
                  activeClock={activeClock}
                  active={gameStore.isActiveViewPlayer(
                    //@ts-ignore
                    pWhitePieces._id
                  )}
                  id={
                    pWhitePieces._id
                  }
                  killedPieces={
                    killedPieces[ColorVariant.black]
                  }
                  color={ColorVariant.white}
                  reverse
                  isSystemAvatar={
                    //@ts-ignore
                    pWhitePieces.isSystemAvatar
                  }
                  piecesBeatCounter={
                    piecesBeatCounter[ColorVariant.white]
                  }
                  elementPlace={
                    PlayerInfoExistPosition.inLobbyPreview
                  }
                  gameId={gameStore?.currentGameNew?._id}
                />
              </div>
              <div
                className={cn(classes.alertHolder, {
                  showWatchersBlock: showWatchersBlock,
                })}
              ></div>
              <Box
                component="div"
                className={cn(
                  classes.presentAndNavigationBox,
                  "bottom"
                )}
              >
                {actualWidth < MIN_CHANGE_SCREEN_WIDTH &&
                  watchers && (
                    <ActionAndReturn
                      setOpen={setOpenUseCallback}
                      openStatus={WatchersStatusType.show}
                      isOpen={isOpen}
                      watchingArray={watchers}
                      isMobileView
                      isMobileGamePage
                      elementPlace={
                        PlayerInfoExistPosition.inProgressPreview
                      }
                    />
                  )}
              </Box>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default observer(ViewChessTableMobile);
