import cn from "classnames";
import {useLayoutEffect} from 'react';
import {useHistory} from 'react-router-dom';
import { MatchPairProps } from '../types';
import { useStyles } from "../styles";
import tbdIcon from 'src/assets/icons/UserCircle.svg';
import { observer } from "mobx-react";
import useStores from "../../../../../hooks/useStores";
import videoCameraIcon from "src/assets/icons/VideoCamera.svg";
import playIcon from "src/assets/icons/Play.svg";
import { Avatar } from "../../../../../shared/ui/Avatar";
import endpoints from "../../../../../core/endpoints";
import defaultAvatar from "src/assets/images/defaultPlaceholder.png";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";
import paths from "src/consts/paths";

const MatchPair: React.FC<MatchPairProps> = ({
    player1,
    player2,
    gameId,
    columnIndex,
    matchIndex,
    totalColumns,
    firstWins, 
    secondWins, 
    isFinished,
}) => {

    const isFinalColumn = columnIndex === totalColumns - 1;

    const classes = useStyles({
        matchIndex,
        marginTop: 0,
        columnIndex,
        isFinalColumn,
    });

    const { authStore, viewHistoryGameStore } = useStores();
        const history = useHistory();
    const currentUserId = authStore.currentUser?._id;

    // для проверки TBD игрока
    const isTBDPlayer = (player: any): boolean =>
        player && player._id.startsWith("TBD_");

    const isByePlayer = (player: any): boolean =>
        player && player._id.startsWith("BYE_");

    const isCurrentUserMatch =
        (player1?._id === currentUserId || player2?._id === currentUserId) &&
        !isTBDPlayer(player1) &&
        !isTBDPlayer(player2);

    const getAvatarURL = (avatarPath: string | null): string => {
        return avatarPath
            ? `${endpoints.avatarPath}/${avatarPath}`
            : defaultAvatar;
    };

    const isFinal = columnIndex === totalColumns - 1 && matchIndex === 0;
    const isThirdPlace = columnIndex === totalColumns - 1 && matchIndex === 1;

    const renderPlayer = (
        player: { name: string; avatar?: string; _id: string } | null,
        position: "top" | "bottom"
    ) => {
        if (isTBDPlayer(player) || isByePlayer(player)) {
            return (
                <div className={cn(
                    classes.player,
                    position === "top" ? classes.playerTop : classes.playerBottom
                )}>
                    <div className={classes.tbdInfo}>
                        <img src={tbdIcon} className={classes.avatar} alt="TBD" />
                        <span>{player?.name || "TBD"}</span>
                    </div>
                </div>
            );
        }

        const playerData = player as {
            name: string;
            avatar?: string;
            _id: string;
        };

        const playerWins = position === "top" ? firstWins : secondWins;
        const opponentWins = position === "top" ? secondWins : firstWins;
        const isWinner = (isFinished && playerWins > opponentWins) ||
            (position === "top" && isByePlayer(player2)) ||
            (position === "bottom" && isByePlayer(player1));

        return (
            <div
                className={cn(
                    classes.player,
                    position === "top" ? classes.playerTop : classes.playerBottom,
                    { [classes.winner]: isWinner }
                )}
            >
                <div className={classes.playerInfo}>
                    <Avatar
                        src={getAvatarURL(playerData.avatar || null)}
                        wrapperClassName={classes.avatar}
                    />
                    <span
                        className={cn({
                            [classes.winnerName]: isWinner,
                            [classes.loserName]: !isWinner,
                        })}
                    >
                        {playerData.name}
                    </span>
                </div>
                <span
                    className={cn(classes.score, {
                        [classes.winnerScore]: isWinner,
                        [classes.loserScore]: !isWinner,
                    })}
                >
                    {playerWins}
                </span>
            </div>
        );
    };

    const handleMatchAction = () => {
        if (isCurrentUserMatch) {
            if (gameId) {
                window.location.href = `/game/${gameId}`;
            } else {
                console.error("Game ID is missing for your match.");
            }
        } else {
            if (gameId) {
                // window.location.href = `/game/${gameId}`;
            } else {
                console.error("Game ID is missing for this match.");
            }
        }
    };

    const shouldDisplayActionButtons = !!gameId && !isFinished;

    return (
        <div className={classes.matchPair} data-id={`${columnIndex}-${matchIndex}`}
       
        >
            {(isFinal || isThirdPlace) && (
                <div className={classes.matchLabel}>
                    {isFinal ? "1st Place Decider" : "3rd Place Decider"}
                </div>
            )}

            <div className={classes.matchPairContent}>

            {renderPlayer(player1, "top")}
            {renderPlayer(player2, "bottom")}

            {shouldDisplayActionButtons && (
                <button
                    className={cn(classes.matchButton, {
                        [classes.playButton]: isCurrentUserMatch,
                        [classes.viewButton]: !isCurrentUserMatch,
                    })}
                    onClick={handleMatchAction}
                >
                    {isCurrentUserMatch ? (
                        <img src={playIcon} alt="Play match" className={classes.viewIcon} />
                    ) : (
                        <img src={videoCameraIcon} alt="View match" className={classes.viewIcon}
                        onClick={() => {
                          viewHistoryGameStore.reset()
                            if (window.innerWidth < MAX_XL_TABLET) {
                              history.push(`${paths.game}/${gameId}`);
                            } else {
                                viewHistoryGameStore.reset();

                                let it = setTimeout(() => {
                                    viewHistoryGameStore.setGameIdNewView(
                                        gameId
                                    );
                                    clearTimeout(it);
                                }, 200);

                            }
                                
                                  }}
                        />
                    )}
                </button>
            )}

            </div>
        </div>
    );
};

export default observer(MatchPair);