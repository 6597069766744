import React, { CSSProperties, useEffect, useRef, useState } from "react";
import cn from "classnames";
import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import { usePlayerColor } from "src/hooks/usePlayerColorMarker";

import { useStyles } from "./style";
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string;
    errorImg?: string;
    alt?: string;
    id?: string;
    setLoaded: (status: boolean) => void;
    wrapperClassName?: string;
}
const LazyImages = ({
    src,
    placeholderImg,
    errorImg,
    alt,
    id,
    setLoaded,
    wrapperClassName,
    ...props
}: ImageProps) => {
    const classes = useStyles();
    const [status, setStatus] = useState<"loading" | "error" | "loaded">(
        "loading"
    );

    const ref = useRef<HTMLImageElement>(null);
    const boxShadowColor = usePlayerColor(id);

    const onLoad = () => {
        setStatus("loaded");
        setLoaded(true);
    };

    const onError = () => {
        setStatus("error");
        setLoaded(true);
    };

    useEffect(() => {
        if (ref.current?.complete) setStatus("loaded");
        else setStatus("loading");
    }, [src]);

    return (
        <>
            <div className={cn(classes.lazyImageWrapper, wrapperClassName)}>
                <img
                    {...props}
                    decoding="async"
                    ref={ref}
                    onLoad={onLoad}
                    onError={onError}
                    src={src}
                    alt={alt}
                    id={id}
                    className={cn(props.className, classes.mainImg)}
                    style={{
                        display:
                            status === "error" || status === "loading"
                                ? "none"
                                : "block",
                        backgroundImage: `url(${placeholderImg})`,
                        visibility: status !== "loaded" ? "hidden" : "visible",
                        border: `2px solid ${boxShadowColor}`,
                    }}
                />
                <img
                    {...props}
                    decoding="async"
                    alt={alt}
                    src={placeholderImg || errorImg}
                    id={id}
                    // className={cn(classes.image, props.className)}
                    className={cn(props.className, classes.mainImg)}
                    style={{
                        display: !(status === "error" || status === "loading")
                            ? "none"
                            : undefined,
                        border: `2px solid ${boxShadowColor}`,
                    }}
                />
            </div>
        </>
    );
};

export default LazyImages;
