import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import {
    ETournamentStatus,
    GameModeType,
    GameStatus,
    GameType,
    UserBalanceType,
    ITournament
} from "src/store/models";
import blurOnHover from "src/pages/lobbyNew/assets/backgrounds/backgroundBlurWild.svg";
import gameStore from "../../../../../store/gameStore";
import trophyIcon from "src/assets/icons/trophyGreen.svg";
import blueTrophyIcon from "src/assets/icons/blueTrophy.svg";

const DesktopNav = ({ styles, isLanding }) => {
    const {
        header: { cashBattle, lobbyFreePlay, tournaments, allSlots, allDealers, Soon },
        tournaments: {
          bracket: {
            stagesTitles: { final, semiFinal, thrdPlaceMatch }
        },
      },
    } = useLocale();
    const { generalStore, tournamentsStore, lobbyStore, gamesStore, gameStore, authStore } = useStores();
    const {
        diceChessLobby,
        lobbyFreePlay: lobbyFreePlayPath,
        tournaments: tournamentPath,
        algaltente,
        liveDealer: liveDealerPath,
    } = paths;
    const currentLocation = useLocation();
    const activeLobbyGames = lobbyStore.getActiveGamesDot();

    const showReturnToTournament = tournamentsStore.hasActiveGames;


    // const isCashBattle = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.play
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isFreePlay = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.coins
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isCashBattleDot = useMemo(() => {
    //     if (!isCashBattle) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isCashBattle.length &&
    //         !currentLocation.pathname.includes(isCashBattle[0].id) &&
    //         !currentLocation.pathname.includes(paths.diceChessLobby)
    //     );
    // }, [activeLobbyGames, isCashBattle]);

    const isCashBattle = useMemo(() => {
        return !!activeLobbyGames.length;
    }, [activeLobbyGames]);

    const isCashBattleDot = useMemo(() => {
        if (!isCashBattle) return false;
        return (
            isCashBattle &&
            activeLobbyGames &&
            activeLobbyGames[0]?._id &&
            !currentLocation.pathname.includes(activeLobbyGames[0]?._id) &&
            !currentLocation.pathname.includes(paths.diceChessLobby)
        );
    }, [activeLobbyGames, isCashBattle]);

    // const isFreePlayDot = useMemo(() => {
    //     if (!isFreePlay) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isFreePlay.length &&
    //         !currentLocation.pathname.includes(isFreePlay[0].id) &&
    //         !currentLocation.pathname.includes(paths.lobbyFreePlay)
    //     );
    // }, [activeLobbyGames, isFreePlay]);

    const isFreePlayDot = useMemo(() => {
        return false;
    }, [activeLobbyGames]);

    const isTournamentDot = useMemo(() => {
        const isNotInGamePage = tournamentsStore.myGamesList.every(
            (g) => !currentLocation.pathname.includes(g?.id)
        );
        return (
            showReturnToTournament &&
            !isNotInGamePage &&
            !currentLocation.pathname.includes(paths.tournaments)
        );
    }, [showReturnToTournament, tournamentsStore.myGamesList]);

    const links: React.ReactNode[] = [];
    const isHalyk = generalStore.isHalyk;

    // для поиска текущей игры пользователя
    const findCurrentUserGame = (tournamentsStore, gamesStore, authStore) => {
        const userId = authStore.currentUser?._id;
        if (!userId) return null;

        // Найти активный турнир, в котором участвует пользователь
        const activeTournament = tournamentsStore.tournaments?.find(
            (tournament) =>
                tournament.status === ETournamentStatus.RUNNING &&
                tournament.players.some((player) => player._id === userId)
        );

        if (!activeTournament) {
            return null;
        }


        // Проверить, есть ли пользователь в незавершённых парах
        const activePair = activeTournament.pairs?.find(
            (pair) =>
                !pair.isFinished &&
                (pair.player1?._id === userId || pair.player2?._id === userId)
        );

        if (!activePair) {
            return null;
        }

        // Проверить статус игры
        const game = gamesStore.getGameStoreById(activePair.gameId);
        if (!game) {
            return null;
        }


        if (game.status === GameStatus.FINISHED) {
            return null;
        }

        return activePair.gameId;
    };




    const currentUserGameId = useMemo(
        () => findCurrentUserGame(tournamentsStore, gamesStore, authStore),
        [tournamentsStore.tournaments, authStore.currentUser]
    );


    if (generalStore.gameType === GameType.dicechess && !isLanding) {
        if (!isHalyk) {
            links.push(
                <Box
                    key="tournaments"
                    component="div"
                    className={cn(styles.navMenuItemBox)}
                >
                    <NavLink
                        to={tournamentPath}
                        className={cn(
                            styles.navMenuItem,
                            currentLocation.pathname === tournamentPath && styles.navMenuActive
                        )}
                    >
                        {tournaments("Tournaments")}
                    </NavLink>
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isTournamentDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );

            links.push(
                <Box
                    key="cashBattle"
                    component="div"
                    className={cn(styles.navMenuItemBox, {
                        gameInfoDot: isCashBattleDot,
                    })}
                >
                    <NavLink
                        to={diceChessLobby}
                        className={cn(styles.navMenuItem)}
                        activeClassName={cn(styles.navMenuActive)}
                    >
                        {cashBattle("Cash Battle")}
                    </NavLink>
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isCashBattleDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );            


            const loc = location.href;
            const isGamePage = loc.indexOf('/game/') >= 0;
            const gameId = isGamePage ? location.href.split('/').slice(-1)[0] : null;
            const gameStore = gameId ? gamesStore?.getGameStoreById(gameId) : null;
            const tournamentData = gameStore?.currentGameNew;
            const userTournament = tournamentsStore?.tournaments?.find(t => t.players.some(p => p._id === authStore.currentUser?._id));

            const getCurrentRound = (tournament?: ITournament): string => {
                if (!tournament || !tournament?.pairs || tournament.pairs.length === 0) return '';
                const maxStep = Math.max(...tournament.pairs.map(p => p.step));
                const playersInRound = tournament.pairs.filter(p => p.step === maxStep).length;
                const titleStage = new Map([
                  [-1, thrdPlaceMatch("3rd place match")],
                  [1, final("Final")],
                  [2, semiFinal("Semi-final")]
                ])

                return titleStage.has(+playersInRound) ? titleStage.get(+playersInRound) : `1/${playersInRound/2}`
            }

            const rountTitle = getCurrentRound(userTournament)

            links.push(
                <Box
                    component="div"
                    className={cn(styles.navMenuItemBox)}
                >
                    
                    {/*{isGamePage && tournamentData?.tournament?.title ? (*/}
                    {/*    <NavLink*/}
                    {/*        to={`/tournaments/${tournamentData.tournament._id}`}*/}
                    {/*        className={cn(*/}
                    {/*            styles.navMenuItem,*/}
                    {/*            (location.pathname.includes(`/tournaments/${tournamentData.tournament._id}`) ||*/}
                    {/*                location.pathname.includes(`/game/`)) && styles.navMenuActive*/}
                    {/*        )}*/}
                    {/*    >*/}
                    {/*        {tournamentData.tournament.title}*/}
                    {/*    </NavLink>*/}
                    {/*) : currentUserGameId ? (*/}
                    {/*    <NavLink*/}
                    {/*        to={`/game/${currentUserGameId}`}*/}
                    {/*        className={cn(*/}
                    {/*            styles.navMenuItem,*/}
                    {/*            (location.pathname.includes(`/game/${currentUserGameId}`) ||*/}
                    {/*                location.pathname.includes(`/tournaments/`)) && styles.navMenuActive*/}
                    {/*        )}*/}
                    {/*    >*/}
                    {/*        <img src={trophyIcon} alt="Trophy" className={styles.iconActive} />*/}
                    {/*        <span>{getCurrentRound(userTournament)}</span>*/}
                    {/*    </NavLink>*/}
                    {/*) : null}*/}
                    

                    {/*Дубликат блока без активного/неактивного состояний*/}
                    {isGamePage && tournamentData?.tournament?.title ? (
                        <NavLink
                            to={`/tournaments/${tournamentData.tournament._id}`}
                            className={styles.navMenuItem}
                            style={{ color: '#83B5FF' }} 
                        >
                            {tournamentData.tournament.title}
                        </NavLink>
                    ) : currentUserGameId ? (
                        <NavLink
                            to={`/game/${currentUserGameId}`}
                            className={styles.navMenuItem}
                            style={rountTitle.length > 6 ? 
                              { color: '#83B5FF', fontSize: '14px', letterSpacing: '-0.5px'} : 
                              { color: '#83B5FF'}
                            }  
                        >
                            <img src={blueTrophyIcon} alt="Trophy" className={styles.iconActive} />
                            <span>{rountTitle}</span>
                        </NavLink>
                    ) : null}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isTournamentDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );
        }
    } else if (generalStore.gameType === GameType.slots) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={algaltente}
                    className={cn(styles.navMenuItem, {})}
                    activeClassName={cn(styles.navMenuActive)}
                >
                    {allSlots("All slots")}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                </NavLink>
            </Box>
        );
    } else if (generalStore.gameType === GameType.liveDealer) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={liveDealerPath}
                    className={cn(styles.navMenuItem, {})}
                    activeClassName={cn(styles.navMenuActive)}
                >
                    {allDealers("All dealers")}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                </NavLink>
            </Box>
        );
    }

    return <div className={styles.navMenu}>{links}</div>;
};

export default observer(DesktopNav);