import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import Badge from "@mui/material/Badge";

import { appColors, backgroundColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { EyeHidden } from "../icons/eye-hidden.icon";
import { Eye } from "../icons/eye.icon";
import { useStyles } from "../styles";
import CloseIcon from "../../../../../assets/icons/tournaments/closeIcon";
import { reaction, runInAction, toJS } from "mobx";

interface IBadgeDeskMobile {
    currentTab: string;
    showHideOpponent: boolean;
    isChatBlocked?: boolean; 
}

const BadgeDesktop: React.FC<IBadgeDeskMobile> = observer(
    ({ currentTab, showHideOpponent, isChatBlocked }) => {
        const { gameStore, authStore } = useStores();
        const [lastUnreadMessage, setLastUnreadMessage] = useState<string | null>(null);
        const classes = useStyles();

        useEffect(() => {
            const disposer = reaction(
                () => toJS(gameStore.communication.messages),
                (messages) => {
                    //if (currentTab === 'chat') {
                    //    setLastUnreadMessage(null);
                    //    return;
                    //}

                    const lastMessage = messages[messages.length - 1];
                    if (lastMessage?.owner._id === authStore.currentUser?._id) {
                        setLastUnreadMessage(null);
                        return;
                    }
                    if (lastMessage) {
                        setLastUnreadMessage(lastMessage.time);
                    }
                }
            );
            return () => disposer();
        }, [gameStore.communication.messages, authStore.currentUser?._id, currentTab]);

        useEffect(() => {
            if (currentTab === 'chat') {
                setLastUnreadMessage(null);
            }
        }, [currentTab]);

        useEffect(() => {
            const handleClick = (e: MouseEvent) => {
                const target = e.target as HTMLElement;

                if (target.closest('.input') || target.tagName === 'INPUT') {
                    setLastUnreadMessage(null);
                    return;
                }

                if (target.closest('.messageBackgroundBox')) {
                    setLastUnreadMessage(null);
                }
            };

            document.addEventListener('click', handleClick);
            return () => document.removeEventListener('click', handleClick);
        }, []);

        const displayDot = useMemo(() => !!lastUnreadMessage, [lastUnreadMessage]);

        return (
            <div className={classes.badgeContainer}>
                {!isChatBlocked && displayDot && <span className={classes.badgeDot} />}
            </div>
        );
    }
);

export default BadgeDesktop;

