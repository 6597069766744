import React, { useEffect, useState, useLayoutEffect, useRef, useCallback } from "react";
import { Box, useMediaQuery, Theme } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";

import { useStyles } from "./styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import coinIcon from "src/assets/icons/coin.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import arrowRIcon from "src/assets/icons/RA.svg";
import { GameModeTypeNew, ITournament, PlayerInfoExistPosition, ChatExistPosition } from "../../../../store/models";
import useStores from "../../../../hooks/useStores";
import { BracketsContainerProps, LocationState, Player } from "./types";
import generateTournamentBracket from "./generateTournamentBracket";
import TournamentLines from "./components/tournamentLines";
import BracketColumn from "./components/bracketColumn";
import MatchPair from "./components/matchPair";
import BracketCarousel from "./components/bracketCarousel";
import { fileHelper } from "../../../../shared/helpers/FileHelper";
import { useLocale } from "src/providers/LocaleProvider";
import GamePreview from "src/pages/lobby/components/GamePreview";
import DesktopWatchers from "src/pages/game-new2/components/desktopWatchers";
import freeCoinIcon from "src/assets/icons/bonusCoin.svg";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import DesktopBracket from "./components/desktopBracket";


const BracketsContainerNew: React.FC<BracketsContainerProps> = observer(() => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex: 0 });
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { tournamentsStore, generalStore, viewHistoryGameStore, gamesStore } = useStores();
    const [tournament, setTournament] = useState<ITournament | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const bracketsRef = useRef<HTMLDivElement | null>(null);


    const {
        lobby: {
            tournaments: {
                wins, 
                back, 
                upTitle,
                ratTitle,
                final,  
                semifinal,
                expected,
                bye,
                placeTitle,
                noPrize,
            },
        },
    } = useLocale();
    const [currentStep, setCurrentStep] = useState(0); // Текущий шаг

    const bracket = generateTournamentBracket(tournament, {
        final: final('Final'),
        semifinal: semifinal('Semi-final'),
        expected: expected('To be decided'),
        bye: bye('Bye')
    });

    const updateArrowVisibility = useCallback(() => {
        if (bracketsRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = bracketsRef.current;

            setCanScrollLeft(Math.round(scrollLeft) > 0);

            const maxScroll = scrollWidth - clientWidth;
            setCanScrollRight(Math.round(scrollLeft) < Math.round(maxScroll));
        }
    }, []);

    useEffect(() => {
        const bracketElement = bracketsRef.current;
        if (bracketElement) {
            const checkInitialScroll = () => {
                const { scrollWidth, clientWidth } = bracketElement;
                setCanScrollRight(scrollWidth > clientWidth);
                setCanScrollLeft(false); 
            };

            setTimeout(checkInitialScroll, 0);

            const handleScroll = () => updateArrowVisibility();
            bracketElement.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", updateArrowVisibility);

            return () => {
                bracketElement.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", updateArrowVisibility);
            };
        }
    }, [updateArrowVisibility, bracket]);

    const scrollLeft = () => {
        if (bracketsRef.current) {
            const columnWidth = bracketsRef.current.clientWidth / bracket.length;
            bracketsRef.current.scrollBy({ left: -columnWidth, behavior: "smooth" });
            setTimeout(updateArrowVisibility, 300);
        }
    };

    const scrollRight = () => {
        if (bracketsRef.current) {
            const columnWidth = bracketsRef.current.clientWidth / bracket.length;
            bracketsRef.current.scrollBy({ left: columnWidth, behavior: "smooth" });
            setTimeout(updateArrowVisibility, 300);
        }
    };

    useEffect(() => {
        const loadTournament = async () => {
            try {
                setIsLoading(true);

                const tournament = tournamentsStore.tournaments.find(t => t._id === id);

                if (tournament) {                   
                    setTournament({
                        ...tournament,
                        pairs: tournament.pairs.map(pair => ({
                            ...pair,
                            firstWins: pair.firstWins ?? 0, 
                            secondWins: pair.secondWins ?? 0, 
                        })),
                    });

                    const maxStep = Math.max(...tournament.pairs.map(pair => pair.step), 0);
                    setCurrentStep(maxStep);
                } else {
                    console.warn("Tournament not found in tournamentsStore. Please check handleTournamentsFromLobby.");
                }
            } catch (error) {
                console.error("Error loading tournament from tournamentsStore:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadTournament();
    }, [id, tournamentsStore.tournaments]);


    useEffect(() => {
        if (tournament?.imageURL) {
            generalStore.setTournamentBackground(fileHelper.getMediaFileURL(tournament.imageURL));
        }

        return () => {
            generalStore.setTournamentBackground(null);
        }
    }, [tournament]);

    const gameStoreById = viewHistoryGameStore.gameId ? 
    //@ts-ignore
    gamesStore.getGameStoreById(viewHistoryGameStore.gameId) : null

        useLayoutEffect(() => {
      if (gameStoreById && gameStoreById?.currentGameNew?.type !== GameModeTypeNew.TOURNAMENT 
      ) {
        // viewHistoryGameStore.reset()
      }
    }, [gameStoreById, tournament])
  
    if (!tournament) {
        return (
            <div className={classes.errorContainer}>
                
            </div>
        );
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleBackClick = () => {
        history.push('/tournaments');
    };

    const gameSeries = tournament.gameSettings?.matchCount || 2;

    //console.log('Generated bracket:', {
    //    roundsCount: bracket.length,
    //    matchesInRounds: bracket.map(round => ({
    //        title: round.title,
    //        matches: round.matches.length,
    //        subMatches: round.matches.filter(m => m.sub).length
    //    }))
    //});

    const formatPrize = (prize: number) => {
        const [whole, decimal] = prize.toString().split('.');
        if (!decimal) return whole;
        return `${whole}.${decimal.padEnd(2, '0').slice(0, 2)}`;
    };

    return (
        <div className={classes.root}>
            <Box className={cn(classes.previewTourBox, {
              active: viewHistoryGameStore.gameId
            })}>

                <GamePreview
                    isPreviewGame={undefined}
                    isFriends={false}
                    showPreview={() => {}}
                    showFriends={() => {}}
                    togglePreview={() => {console.log('hello')}}
                    isFullScreen={null}
                    isOpenPreview={true}
                    componentPlace={PlayerInfoExistPosition.inBracketPreview}
                />


            </Box>

            <div
                className={classes.backgroundImage}
            />
            <div className={classes.gradientOverlay} />
            <div className={classes.content}>
                {isMobile ? (
                    <div className={classes.headerMobile}>
                        <button className={classes.backButtonMobile} onClick={handleBackClick}>
                            <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                            {back('Back')}
                        </button>
                        <div className={classes.titleMobile}>
                            {tournament.title}
                            <div className={classes.prizeInfoMobile}>
                                <img
                                    className={classes.coin}
                                    src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                    alt="coin"
                                />
                                <span className={classes.gradientSpan}>
                                    {formatPrize(tournament.totalPrize)}
                                </span>
                                <div className={classes.dropdownWrapper}>
                                    <img
                                        className={cn(classes.arrowDown, {
                                            [classes.arrowDownRotated]: isDropdownOpen,
                                        })}
                                        src={arrowIcon}
                                        alt="arrow"
                                        onClick={toggleDropdown}
                                    />
                                    {isDropdownOpen && (
                                        <div className={classes.dropdownContent}>
                                            <div className={classes.placeWrapper}>
                                                {tournament.prizes?.length > 0 ? (
                                                    tournament.prizes.slice().sort((prizeA, prizeB) => (prizeA.place || 0) - (prizeB.place || 0)).map((prize, index) => (
                                                        <div key={index} className={classes.placeRow}>
                                                            <span className={classes.placeText}>
                                                                {prize.place
                                                                    ? `${prize.place} ${placeTitle('Place')}`
                                                                    : `${prize.range?.[0]}-${prize.range?.[1]} ${placeTitle('Place')}`}
                                                            </span>
                                                            <div className={classes.rewardText}>
                                                                <img src={coinIcon} alt="Coin" className={classes.coinIcon} />
                                                                <span className={classes.gradientText}>{prize.amount}</span>
                                                                <span className={classes.regularText}>
                                                                    + {prize.rating} {ratTitle('RP')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className={classes.noPrizesText}>{noPrize('Призы отсутствуют')}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.dateInfoMobile}>
                            {upTitle('Up to')} <span style={{ fontWeight: 700 }}>{tournament.gameSettings.matchCount}</span> {wins('wins ')} • {" "}
                            <span style={{ fontWeight: 700 }}>
                                {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className={classes.header}>
                        <button className={classes.backButton} onClick={handleBackClick}>
                            <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                            {back('Back')}
                        </button>
                        <div className={classes.titleContainer}>
                            <div className={classes.title}>
                                <div className={classes.titleText}>
                                    {tournament.title}
                                        <img
                                            className={classes.coin}
                                            src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                            alt="coin"
                                        />
                                        <span className={classes.gradientSpan}>
                                            {formatPrize(tournament.totalPrize)}
                                        </span>
                                        <div className={classes.dropdownWrapper}>
                                        <img
                                            className={cn(classes.arrowDown, {
                                                [classes.arrowDownRotated]: isDropdownOpen,
                                            })}
                                            src={arrowIcon}
                                            alt="arrow"
                                            onClick={toggleDropdown}
                                        />
                                            {isDropdownOpen && (
                                                <div className={classes.dropdownContent}>
                                                    <div className={classes.placeWrapper}>
                                                        {tournament.prizes?.length > 0 ? (
                                                            tournament.prizes.slice().sort((prizeA, prizeB) => (prizeA.place || 0) - (prizeB.place || 0)).map((prize, index) => (
                                                                <div key={index} className={classes.placeRow}>
                                                                    <span className={classes.placeText}>
                                                                        {prize.place
                                                                            ? `${prize.place} ${placeTitle('Place')}`
                                                                            : `${prize.range?.[0]}-${prize.range?.[1]} ${placeTitle('Place')}`}
                                                                    </span>
                                                                    <div className={classes.rewardText}>
                                                                        <img
                                                                            src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                                                            alt="Coin"
                                                                            className={classes.coinIcon}
                                                                        />
                                                                        <span className={classes.gradientText}>{prize.amount}</span>
                                                                        <span className={classes.regularText}>
                                                                            + {prize.rating} {ratTitle('RP')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className={classes.noPrizesText}>{noPrize('Призы отсутствуют')}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.dateInfo}>
                                    {upTitle('Up to')} <span style={{ fontWeight: 700 }}>{tournament.gameSettings.matchCount}</span> {wins('wins ')} • {" "}
                                <span style={{ fontWeight: 700 }}>
                                    {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {isMobile ? (
                    <BracketCarousel bracket={bracket} classes={classes} />
                ) : (
                    <DesktopBracket
                        bracket={bracket}
                        classes={classes}
                        sub={Boolean(tournament?.pairs?.some(pair => pair.sub))}
                />
                )}
            </div>
        </div>
    );
});


export default BracketsContainerNew;