import { Match, Player, Round } from "./types";

interface LocaleKeys {
    final: string;
    semifinal: string;
    expected: string;
    bye: string;
}

function generateTournamentBracket(
    tournament: {
        players?: Array<{ nickname: string, _id: string, email: string, avatar?: string }>;
        pairs?: Array<{
            step: number,
            gameId: string | null,
            firstWins: number,
            secondWins: number,
            isFinished: boolean,
            player1: { nickname: string, _id: string, email: string, avatar?: string },
            player2?: { nickname: string, _id: string, email: string, avatar?: string }
        }>;
        maxPlayers?: number;
        prizes: any[];
    } | null,
    localeKeys: LocaleKeys
): Round[] {
    if (!tournament || !tournament.players || !tournament.players.length) {
        return [];
    }

    const getRoundTitle = (step: number, totalPlayers: number): string => {
        const playersInRound = totalPlayers / Math.pow(2, step - 1);
        if (playersInRound === 2) return localeKeys.final;
        if (playersInRound === 4) return localeKeys.semifinal;
        const denominator = Math.pow(2, Math.ceil(Math.log2(playersInRound / 2)));
        return `1/${denominator}`;
    };

    // Create TBD player for future rounds
    const createTBDPlayer = (index: number): Player => ({
        _id: `TBD_${index}`,
        name: localeKeys.expected,
        isExpected: true,
        avatar: undefined
    });

    // Create BYE player for missing opponents
    const createByePlayer = (index: number): Player => ({
        _id: `BYE_${index}`,
        name: localeKeys.bye,
        isBye: true,
        avatar: undefined
    });

    const maxPlayers = (tournament.pairs?.filter(item => item.step === 1).length || 0) * 2;
    const pairsByStep = new Map<number, Match[]>();
    let tbdCounter = 0;
    let byeCounter = 0;

    // Get the current maximum step from tournament pairs
    const currentMaxStep = Math.max(...(tournament.pairs?.map(p => p.step) || [0]));

    // First, organize all existing pairs by step
    tournament.pairs?.forEach(pair => {
        const match: Match = {
            player1: {
                _id: pair.player1._id,
                name: pair.player1.nickname,
                avatar: pair.player1.avatar,
            },
            // If player2 is missing in an existing pair, use BYE
            player2: pair.player2
                ? {
                    _id: pair.player2._id,
                    name: pair.player2.nickname,
                    avatar: pair.player2.avatar,
                }
                : createByePlayer(byeCounter++),
            gameId: pair.gameId || undefined,
            firstWins: pair.firstWins,
            secondWins: pair.secondWins,
            isFinished: pair.isFinished,
            step: pair.step
        };

        const pairs = pairsByStep.get(pair.step) || [];
        pairs.push(match);
        pairsByStep.set(pair.step, pairs);
    });

    const rounds: Round[] = [];
    const totalRounds = Math.ceil(Math.log2(maxPlayers));

    for (let step = 1; step <= totalRounds; step++) {
        const currentRoundPairs = pairsByStep.get(step) || [];
        let expectedPairsInRound = maxPlayers / Math.pow(2, step);

        // Add third place match if needed
        if (step === totalRounds && tournament.prizes.length > 2) {
            expectedPairsInRound++;
        }

        // Fill missing pairs
        while (currentRoundPairs.length < expectedPairsInRound) {
            // Use TBD for future rounds, BYE for current/past rounds with missing players
            const isFutureRound = step > currentMaxStep;
            const createPlayer = isFutureRound ? createTBDPlayer : createByePlayer;
            const counter = isFutureRound ? tbdCounter++ : byeCounter++;

            currentRoundPairs.push({
                player1: createPlayer(counter),
                player2: createPlayer(counter + 1),
                gameId: undefined,
                firstWins: 0,
                secondWins: 0,
                isFinished: false
            });
        }

        rounds.push({
            title: getRoundTitle(step, maxPlayers),
            matches: currentRoundPairs,
        });

        if (expectedPairsInRound <= 1) break;
    }

    return rounds;
}

export default generateTournamentBracket;