import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    modalContent: {
        width: 300,
        height: 200,
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        margin: "100px auto",
        textAlign: "center",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        color: '#fff',
    },
    jackpotBox: {
        width: '100%',
        marginBottom: '1vw',
    },
});