import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field, useField } from "formik";
import { useHistory, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import {
    createTheme,
    Grid,
    OutlinedInput,
    InputLabel,
    FormControl,
    Box,
    Dialog,
    TextField,
    Autocomplete,
    IconButton,
} from "@mui/material";
import classNames from "classnames";
import _get from "lodash/get";
import { observer } from "mobx-react";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";

import { ELanguages } from "src/store/models";
import paths from "src/consts/paths";
// import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import { useLocale } from "src/providers/LocaleProvider";
import googleLogo from "src/assets/icons/social/GoogleIcons.svg";
import appleLogo from "src/assets/icons/social/AppleIcons.svg";
import facebookLogo from "src/assets/icons/social/fbIcons.svg";
import closeIcon from "src/assets/icons/x.svg";
import CustomArrowIcon from "src/assets/icons/selectIcon.svg";

import { errorService } from "../../service/services";
import { ButtonType, GreenButton } from "../../components/buttons/greenButton";
import useStores from "../../hooks/useStores";
import { useModal } from "../landing/utils/context";
import LocaleText from "../../components/locale-text/locale.text";
import {
    getCountries,
    signInWithFacebook,
    signInWithGoogle,
} from "../../service/api/auth";
import phoneLogo from "../../assets/icons/social/phone.svg";

import { useStyles } from "./styles";
import styles from "./styles.module.scss";
import { MainActionButton } from "../../components/buttons/newDesignButtons/mainActionButton";
import eyeCloseIcon from "src/assets/icons/EyeClose.svg";
import eyeOpenIcon from "src/assets/icons/EyeOpen.svg";

const theme = createTheme({
    palette: {
        primary: {
            main: "#5B627E",
            dark: "#5B627E",
            contrastText: "#5B627E",
            light: "#5B627E",
        },
    },
});

const KAZAKHSTAN_ID = "6625f90831cf705fb3c3eda4";

export const RegisterForm = observer(() => {
    const { authStore, generalStore } = useStores();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const inputEl = useRef<any>(null);
    const muiStyles = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const handleSelectBlur = () => {
        setIsOpen(false);
    };
    const { closeModal, openModal } = useModal();
    // const fingerprintData = useVisitorData();

    const [eyeClosedIcon, setEyeClosedIcon] = useState(eyeCloseIcon);
    const [eyeOpenedIcon, setEyeOpenedIcon] = useState(eyeOpenIcon);

    useEffect(() => {
        setEyeClosedIcon(eyeCloseIcon);
        setEyeOpenedIcon(eyeOpenIcon);
    }, []);

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setIsPasswordHidden(!isPasswordHidden);
    };

    const btnStyle = classNames({
        hidePasswordBtn: true,
        hidePasswordBtnClosed: isPasswordHidden,
        hidePasswordBtnOpen: !isPasswordHidden,
    });

    const {
        register: {
            errors: {
                password,
                email,
                nickname,
                country,
                agreement,
                tooLongEmail,
                wrongFormatEmail,
                enterTheEmail,
                useJustEnglishLetters,
                tooLongPassword,
                tooShortPassword,
                enterPassword,
                chooseCountry,
            },
            form: {
                placeEmail,
                placePassword,
                placeNickname,
                placeCountry,
                nicknameLabel,
                countryLabel,
                emailLabel,
                passwordLabel,
            },
            registerButton,
            logIn,
            alreadyHaveAccount,
            subscribeToEmails,
            passwordHint,
            agreementText,
            rulesText,
            or,
        },
    } = useLocale();
    const locales = useLocale();
    //страны
    const [countries, setCountries] = useState<{ id: string; name: string }[]>(
        []
    );
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data.countries);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        fetchCountries();
    }, []);
    useEffect(() => {
        const fetchCountries = async () => {
            if (!generalStore.isHalyk) {
                try {
                    const response = await getCountries();
                    setCountries(response.data.countries);
                } catch (error) {
                    console.error("Error fetching countries:", error);
                }
            }
        };
        fetchCountries();
    }, [generalStore.isHalyk]);

    const validationSchema = Yup.object().shape({
        nickname: Yup.string()
            .trim()
            .matches(
                /^[a-zA-Z0-9_-]+$/,  
                useJustEnglishLetters('Please use only English letters, numbers, "_" and "-"')
            )
            .min(2, "Nickname must be at least 5 characters long")
            .max(25, "Nickname must not exceed 25 characters")
            .required("Nickname is required"),
        email: Yup.string()
            .trim()
            .matches(
                /^[^а-яА-Я]+$/,
                useJustEnglishLetters("Use only English letters")
            )
            .max(64, tooLongEmail("The email is too long"))
            .required(enterTheEmail("Enter your email address"))
            .email(
                wrongFormatEmail("The mailbox must be in the format name@domain.com")
            ),
        password: Yup.string()
            .trim()
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                useJustEnglishLetters("Use only English letters")
            )
            .min(
                8,
                tooShortPassword("Password must be at least 8 characters long")
            )
            .max(
                20,
                tooLongPassword("The password is very long (max. 20 characters)")
            )
            .required(enterPassword("Enter your password")),
        agreement: Yup.boolean().oneOf(
            [true],
            agreement("You must accept the terms and conditions")
        ),
        country: generalStore.isHalyk
            ? Yup.string()
            : Yup.string().required(
                chooseCountry("Please select your country")
            ),
    });

    const onSubmitForm = async (values) => {
        console.log("Starting form submission with values:", values);
        setLoading(true);

        try {
            console.log("Attempting to sign up user...");
            const signUpResponse = await authStore.signUp(
                values.email,
                generalStore.isHalyk ? undefined : values.nickname,
                values.password,
                values.agreement,
                ELanguages.EN,
                authStore.referralId,
                values.subscribeToEmails,
                values.country
            );
            console.log("Sign up response:", signUpResponse);

            console.log("Fetching user data...");
            const userData = await authStore.getMeNew();
            console.log("User data fetched:", userData);

            localStorage.removeItem("refId");
            console.log("Redirecting to lobby...");
            history.push(paths.lobby);
            console.log("Registration process completed successfully");

            // Закрываем модальное окно после успешной регистрации
            closeModal("register");

            return true; // Возвращаем true в случае успеха
        } catch (e) {
            console.error("Error during registration:", e);
            console.log("Error response:", e.response);

            const errors = e.response?.data?.errors;
            console.log("Errors from server:", errors);

            if (typeof errors === "string" || errors instanceof String) {
                console.log("Sending error:", errors);
                errorService.sendError(errors);
            } else {
                console.log("Processing multiple errors");
                for (const [key, value] of Object.entries(errors)) {
                    const errorMessage = _get(
                        locales,
                        (value as any).locale?.label
                    )((value as any).locale.defaultValue || "");
                    console.log(`Sending error for ${key}:`, errorMessage);
                    errorService.sendError(errorMessage);
                }
            }
            return false; // Возвращаем false в случае ошибки
        } finally {
            console.log(
                "Registration attempt finished, setting loading to false"
            );
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

    return (
        <Dialog
            open
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    closeModal("register");
                }
            }}
            disableEscapeKeyDown
            maxWidth={false}
            className={muiStyles.container}
            PaperProps={{ className: muiStyles.dialogPaper }}
        >
            <Box component="div" className={muiStyles.formContainer}>
                <ThemeProvider theme={theme}>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            agreement: true,
                            subscribeToEmails: true,
                            country: generalStore.isHalyk ? KAZAKHSTAN_ID : "",
                            nickname: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            const success = await onSubmitForm(values);
                            setSubmitting(false);
                            if (success) {
                                closeModal("register");
                            }
                        }}
                        render={({
                            values,
                            touched,
                            errors,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                        }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className={muiStyles.formWrapper}
                                >
                                    <Grid
                                        component="div"
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.titleBlock}
                                        >
                                            <div
                                                className={muiStyles.enterGame}
                                            >
                                                <LocaleText
                                                    label="register.title"
                                                    defaultValue="Sign Up"
                                                />
                                            </div>
                                            <img
                                                src={closeIcon}
                                                alt="Close"
                                                className={muiStyles.closeIcon}
                                                onClick={() =>
                                                    closeModal("register")
                                                }
                                            />
                                        </Grid>

                                        {!generalStore.isHalyk && (
                                            <Grid
                                                item
                                                xs={12}
                                                className={muiStyles.fieldGrid}
                                                >
                                                <div className={muiStyles.fieldHolder}>
                                                    <FormControl fullWidth variant="outlined" className={muiStyles.inputContainer}>
                                                        <label htmlFor="nickname" className={muiStyles.outlinedLabel}>
                                                            <LocaleText
                                                                label="register.form.nicknameLabel"
                                                                defaultValue={nicknameLabel("Nickname*")}
                                                            />
                                                        </label>
                                                        <input
                                                            id="nickname"
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            name="nickname"
                                                            value={values.nickname}
                                                            placeholder={placeNickname("Enter your nickname")}
                                                            className={`${muiStyles.outlinedInput} ${errors.nickname ? muiStyles.inputError : ''}`}
                                                            required
                                                        />
                                                        {touched.nickname && errors.nickname && (
                                                            <div className={muiStyles.error}>
                                                                {errors.nickname}
                                                            </div>
                                                        )}
                                                    </FormControl>
                                                </div>
                                                </Grid>
                                        )}

                                        {!generalStore.isHalyk && (
                                            <Grid
                                                item
                                                xs={12}
                                                className={muiStyles.fieldGrid}
                                            >
                                                <div className={muiStyles.fieldHolder}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        className={muiStyles.inputSelectContainer}
                                                    >
                                                        <label
                                                            htmlFor="country"
                                                            className={muiStyles.outlinedLabel}
                                                        >
                                                            <LocaleText
                                                                label="register.form.countryLabel"
                                                                defaultValue={countryLabel("Country*")}
                                                            />
                                                        </label>
                                                        <Autocomplete
                                                            id="country"
                                                            options={countries}
                                                            getOptionLabel={(option) => option.name}
                                                            renderInput={(params) => (
                                                                <div ref={params.InputProps.ref} className={muiStyles.inputWrapper}>
                                                                    <input
                                                                        {...params.inputProps}
                                                                        type="text"
                                                                        placeholder={placeCountry("Choose your country")}
                                                                        className={`${muiStyles.outlinedInput} ${errors.country && touched.country ? muiStyles.inputError : ''}`}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    <div className={muiStyles.arrowIconContainer}>
                                                                        <img
                                                                            src={CustomArrowIcon}
                                                                            alt="Arrow Icon"
                                                                            className={`${muiStyles.arrowIcon} ${isCountryDropdownOpen ? muiStyles.arrowIconOpen : ''}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            onChange={(event, value) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "country",
                                                                        value: value ? value.id : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={countries.find((country) => country.id === values.country) || null}
                                                            classes={{
                                                                option: muiStyles.dropdownOption,
                                                                noOptions: muiStyles.dropdownOptionDisabled,
                                                                paper: `${muiStyles.dropdownPaper} ${muiStyles.noScrollbar}`,
                                                                popupIndicator: muiStyles.popupIndicator,
                                                            }}
                                                            onOpen={() => setIsCountryDropdownOpen(true)}
                                                            onClose={() => setIsCountryDropdownOpen(false)}
                                                        />
                                                        {touched.country && errors.country && (
                                                            <div className={muiStyles.error}>
                                                                {errors.country as string}
                                                            </div>
                                                        )}
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.fieldGrid}
                                        >
                                            <div
                                                className={
                                                    muiStyles.fieldHolder
                                                }
                                            >
                                                <FormControl fullWidth variant="outlined" className={muiStyles.inputContainer}>
                                                    <label htmlFor="email" className={muiStyles.outlinedLabel}>
                                                        <LocaleText
                                                            label="register.emailLabel"
                                                            defaultValue={emailLabel("Email*")}
                                                        />
                                                    </label>
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="email"
                                                        value={values.email}
                                                        placeholder={placeEmail("Enter your email")}
                                                        className={`${muiStyles.outlinedInput} ${errors.email ? muiStyles.inputError : ''}`}
                                                        required
                                                    />
                                                    {touched.email && errors.email && (
                                                        <div className={muiStyles.error}>
                                                            {errors.email}
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.fieldGrid}
                                        >
                                            <div className="OutlinedInput">
                                                <FormControl fullWidth variant="outlined" className={muiStyles.inputContainer}>
                                                    <label htmlFor="password" className={muiStyles.outlinedLabel}>
                                                        <LocaleText
                                                            label="register.passwordLabel"
                                                            defaultValue={passwordLabel("Password*")}
                                                        />
                                                    </label>
                                                    <div className={muiStyles.passwordInputWrapper}>
                                                        <input
                                                            id="password"
                                                            type={isPasswordHidden ? "password" : "text"}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            name="password"
                                                            value={values.password}
                                                            placeholder={placePassword("Enter your password")}
                                                            className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride} ${errors.password ? muiStyles.inputError : ''}`}
                                                            required
                                                        />
                                                        <button
                                                            className={muiStyles.hidePasswordBtn}
                                                            onClick={togglePasswordVisibility}
                                                            type="button"
                                                        >
                                                            <img
                                                                src={isPasswordHidden ? eyeCloseIcon : eyeOpenIcon}
                                                                alt={isPasswordHidden ? "Show password" : "Hide password"}
                                                            />
                                                        </button>
                                                    </div>
                                                    <div className={muiStyles.passwordHint}>
                                                        <LocaleText
                                                            label="register.passwordHint"
                                                            defaultValue={passwordHint("Password must be at least 8 characters long.*")}
                                                        />
                                                    </div>
                                                    {errors.password && (
                                                        <div className={muiStyles.error}>
                                                            <LocaleText
                                                                label={`register.errors.password.${errors.password}`}
                                                                defaultValue={errors.password}
                                                            />
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                            <div className={muiStyles.checkBoxContainer}>
                                                <label className={muiStyles.checkBoxHolder} htmlFor="agreement">
                                                    <Field name="agreement">
                                                        {({ field }) => (
                                                            <div
                                                                className={
                                                                    muiStyles.checkBoxHolder
                                                                }
                                                            >
                                                                <input
                                                                    className={
                                                                        styles.input
                                                                    }
                                                                    {...field}
                                                                    type="checkbox"
                                                                    id="agreement"
                                                                    checked={
                                                                        values.agreement
                                                                    }
                                                                />
                                                                <span
                                                                    className={
                                                                        styles.checkbox
                                                                    }
                                                                />
                                                                <div className={muiStyles.checkBoxDescription}>
                                                                    <span
                                                                        className={
                                                                            muiStyles.checkBoxFieldText
                                                                        }
                                                                    >
                                                                        <LocaleText
                                                                            label="register.agreement"
                                                                            defaultValue="I am more than 18 years old and I agree with the"
                                                                        />
                                                                        &nbsp;
                                                                        <Link to="/privacy-policy">
                                                                            <LocaleText
                                                                                label="register.rules"
                                                                                defaultValue="rules"
                                                                                className={
                                                                                    muiStyles.rulesLink
                                                                                }
                                                                            />
                                                                        </Link>
                                                                    </span>
                                                                    {touched.agreement &&
                                                                        errors.agreement && (
                                                                            <div
                                                                                className={
                                                                                    muiStyles.error
                                                                                }
                                                                            >
                                                                                {
                                                                                    errors.agreement as string
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </label>
                                                <label
                                                    className={muiStyles.checkBoxHolder} 
                                                    htmlFor="subscribeToEmails"
                                                >
                                                    <Field name="subscribeToEmails">
                                                        {({ field }) => (
                                                            <div
                                                                className={
                                                                    muiStyles.checkBoxHolder
                                                                }
                                                            >
                                                                <input
                                                                    className={
                                                                        styles.input
                                                                    }
                                                                    {...field}
                                                                    type="checkbox"
                                                                    id="subscribeToEmails"
                                                                    checked={
                                                                        values.subscribeToEmails
                                                                    }
                                                                />
                                                                <span
                                                                    className={
                                                                        styles.checkbox
                                                                    }
                                                                />
                                                                <span
                                                                    className={
                                                                        muiStyles.checkBoxFieldText
                                                                    }
                                                                >
                                                                    <LocaleText
                                                                        label="register.subscribeToEmails"
                                                                        defaultValue={subscribeToEmails(
                                                                            "I want to receive newsletter"
                                                                        )}
                                                                    />
                                                                </span>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </label>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid container>
                                                <MainActionButton
                                                    loading={isLoading}
                                                    className={muiStyles.registerBtn}
                                                    text={registerButton(
                                                        "Create Account"
                                                    )}
                                                    buttonType={"submit"}
                                                    appearanceTheme={""} />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid
                                                container
                                                className={
                                                    muiStyles.lineContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        muiStyles.lineBox
                                                    }
                                                >
                                                    <LocaleText
                                                        label="register.or"
                                                        defaultValue={or("or")}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid
                                                container
                                                className={
                                                    muiStyles.socialRegister
                                                }
                                                style={{ display: "flex" }}
                                            >
                                                <div
                                                    className={
                                                        muiStyles.googleButtonWrapper
                                                    }
                                                >
                                                    <button
                                                        className={
                                                            muiStyles.socialRegisterBtn
                                                        }
                                                        style={{ zIndex: 1 }}
                                                    >
                                                        <img
                                                            src={googleLogo}
                                                            alt="Google"
                                                            className={
                                                                muiStyles.socialIcon
                                                            }
                                                        />
                                                    </button>
                                                    <div
                                                        className={
                                                            muiStyles.googleButtonOverlay
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                muiStyles.googleButtonInner
                                                            }
                                                        >
                                                            <GoogleLogin
                                                                onSuccess={async (
                                                                    credentialResponse
                                                                ) => {
                                                                    const {
                                                                        credential,
                                                                    } = credentialResponse;
                                                                    if (
                                                                        credential
                                                                    ) {
                                                                        await authStore.signInWithGoogle(
                                                                            credential
                                                                        );
                                                                    } else {
                                                                        console.error(
                                                                            "Google sign-in error: credential is undefined"
                                                                        );
                                                                    }
                                                                }}
                                                                onError={() => {
                                                                    console.log(
                                                                        "Login Failed"
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div*/}
                                                {/*    className={*/}
                                                {/*        muiStyles.appleButtonWrapper*/}
                                                {/*    }*/}
                                                {/*>*/}
                                                {/*    <button*/}
                                                {/*        className={*/}
                                                {/*            muiStyles.socialRegisterBtn*/}
                                                {/*        }*/}
                                                {/*    >*/}
                                                {/*        <img*/}
                                                {/*            src={appleLogo}*/}
                                                {/*            alt="Apple"*/}
                                                {/*            className={*/}
                                                {/*                muiStyles.socialIcon*/}
                                                {/*            }*/}
                                                {/*        />*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}
                                                {/*<div*/}
                                                {/*    className={*/}
                                                {/*        muiStyles.facebookButtonWrapper*/}
                                                {/*    }*/}
                                                {/*>*/}
                                                {/*    <FacebookLogin*/}
                                                {/*        appId={*/}
                                                {/*            process.env*/}
                                                {/*                .REACT_APP_FACEBOOK_APP_ID as string*/}
                                                {/*        }*/}
                                                {/*        onSuccess={async (*/}
                                                {/*            response*/}
                                                {/*        ) => {*/}
                                                {/*            const {*/}
                                                {/*                accessToken,*/}
                                                {/*            } = response;*/}
                                                {/*            await authStore.signInWithFacebook(*/}
                                                {/*                accessToken*/}
                                                {/*            );*/}
                                                {/*        }}*/}
                                                {/*        onFail={(error) => {*/}
                                                {/*            console.log(*/}
                                                {/*                "Вход не удался!",*/}
                                                {/*                error*/}
                                                {/*            );*/}
                                                {/*        }}*/}
                                                {/*        onProfileSuccess={(*/}
                                                {/*            response*/}
                                                {/*        ) => {*/}
                                                {/*            console.log(*/}
                                                {/*                "Получение профиля успешно!",*/}
                                                {/*                response*/}
                                                {/*            );*/}
                                                {/*        }}*/}
                                                {/*        className={*/}
                                                {/*            muiStyles.facebookLoginButton*/}
                                                {/*        }*/}
                                                {/*    />*/}
                                                {/*    <button*/}
                                                {/*        className={*/}
                                                {/*            muiStyles.socialRegisterBtn*/}
                                                {/*        }*/}
                                                {/*        style={{*/}
                                                {/*            position:*/}
                                                {/*                "relative",*/}
                                                {/*            zIndex: 1,*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <img*/}
                                                {/*            src={facebookLogo}*/}
                                                {/*            alt="Facebook"*/}
                                                {/*            className={*/}
                                                {/*                muiStyles.socialIcon*/}
                                                {/*            }*/}
                                                {/*        />*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}

                                                {generalStore.isHalyk && (
                                                    <div
                                                        className={
                                                            muiStyles.phoneButtonWrapper
                                                        }
                                                    >
                                                        <button
                                                            className={
                                                                muiStyles.socialRegisterBtn
                                                            }
                                                            onClick={() => {
                                                                closeModal(
                                                                    "register"
                                                                );
                                                                openModal(
                                                                    "phone-auth"
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={phoneLogo}
                                                                alt="Phone"
                                                                className={
                                                                    muiStyles.socialIcon
                                                                }
                                                            />
                                                        </button>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid
                                                container
                                                className={muiStyles.logInBlock}
                                            >
                                                <p>
                                                    <LocaleText
                                                        label="register.alreadyHaveAccount"
                                                        defaultValue={alreadyHaveAccount(
                                                            "Already have an account?"
                                                        )}
                                                    />
                                                </p>
                                                <button
                                                    className={muiStyles.logIn}
                                                    onClick={() => {
                                                        closeModal("register");
                                                        openModal("login");
                                                    }}
                                                >
                                                    <LocaleText
                                                        label="register.logIn"
                                                        defaultValue={logIn(
                                                            "Log In"
                                                        )}
                                                    />
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </ThemeProvider>
            </Box>
        </Dialog>
    );
});
