import { addFriend } from "./../../../service/api/friends/index";
import { useState, useEffect, useCallback, useMemo } from "react";

import { useLocale } from "src/providers/LocaleProvider";
import { useDebounce } from "src/hooks/use-debounce";
import useStores from "src/hooks/useStores";
import { errorService, infoService } from "src/service/services";
import { FriendTypeEnum, IFriend, ISearchedUser } from "src/store/models";
import { IFriendAdd } from "src/service/api/friends/requestResponses";
import { useModalControls } from "./useModalControls";

export const useFriends = () => {
    const { friendsStore } = useStores();
    const [isSearching, setSearching] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<ISearchedUser[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { changeModalState } = useModalControls();
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const {
        friends: {
            infoModals: { succesAddFriendRequest, errorAddFriendRequest },
        },
    } = useLocale();

    //const searchFriends = async (nickname: string) => {
    //    setSearching(true);
    //    setLoading(true);
    //    try {
    //        const { data } = await searchUsers(nickname);
    //        setLoading(false);
    //        setUsers(data);
    //    } catch (e) {
    //        setSearchQuery("");
    //        setSearching(false);
    //    }
    //};
    const handleRemoveFriend = useCallback(async (user: IFriend) => {
        friendsStore.setSelectedFriend(user);
        changeModalState("deleteFriend", true);
    }, []);

 
    //const handleAddFriend = useCallback(async (nickname: string) => {
    //    try {
    //        const addFriendParams: IFriendAdd = {
    //            playerTo: nickname
    //        };
    //        const result = await friendsStore.addFriend(addFriendParams);
    //        if (result.success) {
    //            infoService.sendInfo(
    //                succesAddFriendRequest("Friend added successfully")
    //            );
    //            await searchFriends(debouncedSearchQuery);
    //        } else {
    //            throw new Error(result.message || "Failed to add friend");
    //        }
    //    } catch (e) {
    //        setSearchQuery("");
    //        errorService.sendError(
    //            `Failed to add friend: ${e instanceof Error ? e.message : 'Unknown error'}`
    //        );
    //    }
    //}, [friendsStore, infoService, succesAddFriendRequest, searchFriends, debouncedSearchQuery, setSearchQuery, errorService]);

    const handleChangeSearchQuery = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (debouncedSearchQuery) {
            //searchFriends(debouncedSearchQuery);
            return;
        } else {
            setSearching(false);
        }
    }, [debouncedSearchQuery]);

    const requestsCount = useMemo(() => {
        return (friendsStore.friends || []).reduce((acc, el) => {
            return acc + (el.type === FriendTypeEnum.incoming ? 1 : 0);
        }, 0);
    }, [friendsStore.friends]);

    return {
        searchQuery,
        handleChangeSearchQuery,
        isSearching,
        isLoading,
        users,
        handleRemoveFriend,
        //handleAddFriend,
        requestsCount,
    };
};
