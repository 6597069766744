import { useCallback, useEffect, useMemo, useState } from "react";
import { useStyles } from "./styles";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg'; 
import { MainActionButton } from "../../../../components/buttons/newDesignButtons/mainActionButton";
import { INewsItem } from "../../../../service/api/news/requestResponses";
import { getNews } from "../../../../service/api/news";
import moment from "moment";
import { fileHelper } from "../../../../shared/helpers/FileHelper";
import { useHistory } from "react-router-dom";
import useStores from "../../../../hooks/useStores";
import { observer } from "mobx-react";
import { useLocale } from "../../../../providers/LocaleProvider";

interface NewsCardProps extends INewsItem {
    isActive: boolean;
}

const NewsCard = observer(({ id, imageURL, title, text, createdAt, isActive }: NewsCardProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        mainLanding: {
            readBtn,
            dateTitle,
        },
    } = useLocale();

    const handleReadMore = () => {
        history.push(`/news/${id}`);
    };

    const stripHTMLTags = (html: string): string => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    const MAX_TEXT_LENGTH = 400;
    const plainText = stripHTMLTags(text);

    return (
        <div className={`${classes.newsCard} ${isActive ? classes.activeCard : ''}`}>
            <div className={`${classes.cardContent} ${classes.horizontalLayout}`}>
                <div className={classes.leftSection}>
                    <div className={classes.imageWrapper}>
                        <img src={fileHelper.getMediaFileURL(imageURL) || ""} className={classes.image} />
                    </div>
                </div>
                <div className={classes.rightSection}>
                    <div className={classes.textContent}>
                        <p className={classes.title}>{title}</p>
                        <p className={classes.description}>
                            {plainText.length > MAX_TEXT_LENGTH
                                ? `${plainText.substring(0, MAX_TEXT_LENGTH)}...`
                                : plainText}
                        </p>
                    </div>
                    <div className={classes.footer}>
                        {isActive && (
                            <MainActionButton
                                appearanceTheme="primary"
                                text={readBtn("Read more")}
                                className={classes.readMoreBtn}
                                onClick={handleReadMore}
                            />
                        )}
                        <span className={classes.date}>
                            {dateTitle('Date')}: <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
});


const NewsCarousel = observer(() => {
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [news, setNews] = useState<INewsItem[]>([]);
    const { authStore } = useStores(); 

    const totalSlides = news.length;
    const isSingleNews = totalSlides === 1;

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const userLanguage = authStore.getLanguage() || 'en';
                const response = await getNews({
                    language: userLanguage, 
                    limit: 10,
                    offset: 0
                });
                setNews(response.data);
            } catch (error) {
                console.error("Error fetching news:", error);
            }
        };

        fetchNews();
    }, [authStore.getLanguage()]);

    const extendedNews = isSingleNews
        ? news
        : [
            ...news.slice(-1),
            ...news,
            ...news.slice(0, 1)
        ];

    const normalizeSlideIndex = (index: number) => {
        if (isSingleNews) return 0;
        if (index < 0) {
            return totalSlides - Math.abs(index % totalSlides);
        }
        return index % totalSlides;
    };

    const calculateTranslateX = () => {
        if (isSingleNews) return 0;

        const screenWidth = window.innerWidth;
        let slideWidth;
        let gap;

        if (screenWidth <= 400) {
            slideWidth = 100;
            gap = 0;
        } else if (screenWidth <= 600) {
            slideWidth = 60;
            gap = 0;
        } else if (screenWidth <= 768) {
            slideWidth = 50;
            gap = 0;
        } else if (screenWidth <= 960) {
            slideWidth = 90;
            gap = 0;
        } else if (screenWidth <= 1200) {
            slideWidth = 80;
            gap = 5;
        } else if (screenWidth <= 1440) {
            slideWidth = 90;
            gap = 0;
        } else {
            slideWidth = 90;
            gap = 0;
        }

        const totalSlideWidth = slideWidth + gap;
        const viewportWidth = 100;
        const offset = (viewportWidth - slideWidth) / 2;
        const activeSlide = currentSlide + 1;

        return -(activeSlide * totalSlideWidth - offset);
    };

    const getSlideClass = (index) => {
        if (isSingleNews) return '';

        const normalizedIndex = normalizeSlideIndex(index - 1);
        if (normalizedIndex === normalizeSlideIndex(currentSlide)) {
            return classes.activeSlide;
        } else if (
            normalizedIndex === normalizeSlideIndex(currentSlide - 1) ||
            normalizedIndex === normalizeSlideIndex(currentSlide + 1)
        ) {
            return classes.neighborSlide;
        }
        return classes.inactiveSlide;
    };

    const shouldShowNavigation = totalSlides > 1;

    const handlePrevSlide = () => {
        if (!isSingleNews) {
            setCurrentSlide((prev) => normalizeSlideIndex(prev - 1));
        }
    };

    const handleNextSlide = () => {
        if (!isSingleNews) {
            setCurrentSlide((prev) => normalizeSlideIndex(prev + 1));
        }
    };

    const handleTransitionEnd = () => {
        if (isSingleNews) return;

        if (currentSlide >= totalSlides) {
            setCurrentSlide(0);
        } else if (currentSlide < 0) {
            setCurrentSlide(totalSlides - 1);
        }
    };

    return (
        <div className={classes.carouselWrapper}>
            <div className={`${classes.carouselViewport} ${isSingleNews ? classes.singleNewsViewport : ''}`}>
                <div
                    className={`${classes.carouselTrack} ${isSingleNews ? classes.singleNewsTrack : ''}`}
                    style={{
                        transform: `translateX(${calculateTranslateX()}%)`,
                    }}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {extendedNews.map((item, index) => (
                        <div
                            key={`${item.id}-${index}`}
                            className={`${classes.slideWrapper} ${getSlideClass(index)} ${isSingleNews ? classes.singleNewsSlide : ''}`}
                        >
                            <NewsCard
                                {...item}
                                isActive={isSingleNews || normalizeSlideIndex(index - 1) === normalizeSlideIndex(currentSlide)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {shouldShowNavigation && (
                <div className={classes.controls}>
                    <button className={classes.arrowButton} onClick={handlePrevSlide}>
                        <img src={rightArrowIcon} alt="Previous" />
                    </button>
                    <div className={classes.dots}>
                        {news.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentSlide(index)}
                                className={`${classes.dot} ${index === currentSlide ? classes.activeDot : ''}`}
                            />
                        ))}
                    </div>
                    <button className={classes.arrowButton} onClick={handleNextSlide}>
                        <img src={rightArrowIcon} style={{ rotate: '180deg' }} alt="Next" />
                    </button>
                </div>
            )}
        </div>
    );
});

export default NewsCarousel;