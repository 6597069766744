import axios from 'axios';

import endpoints from 'src/core/endpoints';
import {
    ITournaments,
    ITopPlayers,
    IRequestTopPlayers,
    ITournament,
} from 'src/store/models';

import {
    ITournamentCreateRequest,
    ITournamentJoinRequest,
    IResponseTopPlayers,
    ITournamentRequest,
} from './requestResponses';

export const createTournaments = async (params: ITournamentCreateRequest) => {
    return await axios.post(endpoints.createTournament, params, {
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': 'true',
            'Content-Type': 'application/json',
        },
    });
};

export const getTournaments = async (): Promise<ITournament[]> => {
    const response = await axios.get<{ tournaments: ITournament[] }>(endpoints.getTournaments);

    if (response.data && Array.isArray(response.data.tournaments)) {
        return response.data.tournaments; 
    } else {
        console.error("Ошибка: структура данных не соответствует");
        return []; 
    }
};


export const getCurrentTournament = async (id: string) => {
    return await axios.get(`${endpoints.getCurrentTournament}/${id}`);
};

export const joinTournaments = async (params: ITournamentRequest) => {
    return await axios.post(endpoints.joinTournaments, params);
};

export const cancelJoinTournaments = async (params: ITournamentRequest) => {
    return await axios.post(endpoints.cancelJoinTournaments, params);
};

export const deleteAllTournaments = async () => {
    return await axios.delete(endpoints.removeAllTournaments);
};

//export const getTopPlayers = async (params: IResponseTopPlayers) => {
//    return await axios.get<IRequestTopPlayers>(endpoints.getTopPlayers, {
//        params,
//    });
//};
