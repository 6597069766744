import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useModalStyles = makeStyles({
    modalWrapper: {
        background: 'rgba(13, 14, 15, 0.3)',
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw',
            zIndex: 9999,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1vw', 
            zIndex: 9999, 
        },
    },
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 1050,
        width: '100%',
        background: 'rgba(245, 245, 245, 0.05)',
        border: '1px solid rgba(245, 245, 245, 1)',
        backdropFilter: 'blur(5px)',
        webkitBackdropFilter: 'blur(5px)',
        boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
        padding: 18,
        borderRadius: 24,
        outline: 'none', 
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100vw - 6vw)',
            height: 'calc(100vh - 6vw)',
            background: 'rgba(245, 245, 245, 0.05)',
            border: '1px solid rgba(245, 245, 245, 1)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 6vw)',
            height: 'calc(100vh - 6vw)',
            background: 'rgba(245, 245, 245, 0.05)',
            border: '1px solid rgba(245, 245, 245, 1)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 4vw)', 
            height: 'calc(100vh - 4vw)', 
            background: 'rgba(245, 245, 245, 0.05)',
            border: '1px solid rgba(245, 245, 245, 1)',
            backdropFilter: 'blur(5px)',
            webkitBackdropFilter: 'blur(5px)',
            boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
            padding: 18,
            borderRadius: 24,
            outline: 'none',
        },
    },

    modalNickname: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 600,
        width: '100%',
        maxHeight: '90vh', 
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(245, 245, 245, 0.05)',
        backdropFilter: 'blur(5px)',
        webkitBackdropFilter: 'blur(5px)',
        padding: 18,
        borderRadius: 24,
        outline: 'none',
        overflow: 'hidden',

        [theme.breakpoints.down('lg')]: {
            width: 'calc(100vw - 6vw)',
            height: 'auto',
            maxHeight: 'calc(90vh - 6vw)',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 6vw)',
            height: 'auto',
            maxHeight: 'calc(90vh - 6vw)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 4vw)',
            height: 'auto',
            maxHeight: 'calc(90vh - 4vw)',
        },
    },

    modalContent: {
        flexGrow: 1,
        overflowY: 'auto', 
        marginBottom: 18, 
    },

    buttonBoxNickname: {
        marginTop: 'auto', 
    },

    modalTopBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: 40,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: appColors.white,
        '&.isCustom': {
            [theme.breakpoints.up('lg')]: {
                marginBottom: 10,
            },
            [theme.breakpoints.down('lg')]: {
                margin: '0 auto',
                marginBottom: 0,
                marginTop: 20,
            },
        },
        '&.getOwn': {
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 24,
            },
            [theme.breakpoints.down('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 18,
            },
        },
    },
    titleNickname: {
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'Montserrat',
        color: 'rgba(245, 245, 245, 1)',
        '&.isCustom': {
            [theme.breakpoints.up('lg')]: {
                marginBottom: 10,
            },
            [theme.breakpoints.down('lg')]: {
                margin: '0 auto',
                marginBottom: 0,
                marginTop: 20,
            },
        },
        '&.getOwn': {
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 24,
            },
            [theme.breakpoints.down('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 18,
            },
        },
    },
    closeIcon: {
        width: 24,
        height: 24,
        objectFit: 'contain',
        cursor: 'pointer',
        position: 'absolute',
        top: '20px',
        right: '10px',
        [theme.breakpoints.up('md')]: {
            right: '20px',
        },
    },

    tournamentModalTimer: {
        [theme.breakpoints.up('xs')]: {
            color: appColors.white,
            margin: 0,
            fontWeight: 500,
            fontSize: '18px',
            opacity: 0.8,
        },
    },

    chooseAvatarWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100% - 50px)',
        maxWidth: '100%',
        padding: 0,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            '&.nonGrid': {
                justifyContent: 'center',
            },
        },
    },

    uploadAvatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
        padding: 0,
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: 8,
        transform: ` translate(0%, 50%) translateY(${currentHeight}px)`,
        opacity: 0,
        '&.darkBlue': {
            background: backgroundColors.darkBlue, border: "1px solid #343A59",
        },
        '&.darkGreen': {
          background: backgroundColors.darkGreen, border: "1px solid #004348",
        },
        '$.isInit': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            right: 0,
            borderRadius: 8,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            opacity: 1,
        },
        '&.isUpload': {
            opacity: 0,
            animationName: '$uploadAvatarWrapper',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1000ms',
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
        },
        '&.isUploadHide': {
            opacity: 1,
            animationName: '$uploadAvatarWrapperHide',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1000ms',
            transform: ` translate(0%, 0%) translateY(${0}px)`,
        },
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },

    '@keyframes uploadAvatarWrapperHide': {
        '0%': {
            opacity: 1,
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
        '100%': {
            opacity: 0,
            transform: `translate(0%, 0%) translateY(${currentHeight}px)`,
        },
    },

    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        justifyContent: 'flex-start',
    },
    input: {
        width: '100%',
        borderRadius: 12,
        height: 48,
        border: '1px solid rgba(255, 255, 255, 0.3)',
        '& .MuiInputBase-input': {
            color: 'rgba(255, 255, 255, 1)',
            padding: '14px 12px 0',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            transform: 'translate(12px, 13px) scale(1)',
            transition: 'transform 0.2s ease-out, font-size 0.2s ease-out',
            fontSize: 14,
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(12px, 2px) scale(0.75)', // Опускает лейбл чуть ниже при фокусе
            fontSize: 14, 
        },
        '&.focused .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 1)', 
        },
        '&.darkBlue, &.darkGreen': {
            '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontWeight: 400,
                fontSize: 14,
            },
            '& .MuiFilledInput-root': {
                border: '1px solid rgba(245, 245, 245, 0.3)',
                overflow: 'hidden',
                borderRadius: 12,
                color: 'rgba(245, 245, 245, 1)',
                background: 'transparent',
                fontSize: 16,
                fontWeight: 400,
                '&:before, &:after': {
                    display: 'none',
                },
            },
        },
    },
    changeButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        width: "100%",
        height: "2.5vw",
        color: "#11141C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        '@media (max-width: 1200px)': {
            fontSize: "1.5vw",
            height: "4vw",
            borderRadius: 4,
        },
        '@media (max-width: 768px)': {
            fontSize: "3vw",
            height: "7vw",
            borderRadius: 4,
        },
        '@media (max-width: 560px)': {
            fontSize: "5.5vw",
            height: "11vw",
            borderRadius: 4,

        },
        '@media (max-width: 376px)': {
            fontSize: "6vw",
            height: "12vw",
            borderRadius: 4,

        },
        '@media (max-width: 330px)': {
            fontSize: "7vw",
            height: "15vw",
            borderRadius: 4,

        },
    },
});
