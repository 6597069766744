import { Box, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useHistory } from 'react-router-dom';
import loadable from '@loadable/component';

import paths from 'src/consts/paths';
import useStores from 'src/hooks/useStores';
import { useFriends } from 'src/pages/profile/hooks/useFriends';
import { useLocale } from 'src/providers/LocaleProvider';

import RequestsCountBadge from '../RequestsCountBadge';

import { useStyles } from './styles';

const FriendsList = loadable(() => import('../FriendsList'));
interface IFriendsView {
    showFriends: (status: boolean) => void;
    showPreview: (status: boolean) => void;
    isMobileFriends?: boolean;
    createTitle?: () => string;
}

const FriendsView = ({
    showFriends,
    showPreview,
    isMobileFriends,
    createTitle,
}: IFriendsView) => {
    const styles = useStyles();
    const history = useHistory();

    const { authStore, lobbyStore, friendsStore } = useStores();
    const {
        profile: {
            friends: { title },
        },
        lobby: {
            friends: { addFriendMessage },
        },
    } = useLocale();
    const isVisible = authStore.isVisibleWebApp;

    const { requestsCount } = useFriends();

    useEffect(() => {
        isVisible && friendsStore.getFriends();
    }, [isVisible]);

    return (
        <Box
            className={cn(styles.wrapper, { isMobileFriends: isMobileFriends })}
        >
            {authStore.isAuthorized && (
                <>
                    <Typography className={styles.title}>
                        {title('Friends')}{' '}
                        {!!requestsCount && (
                            <RequestsCountBadge count={requestsCount} />
                        )}
                    </Typography>
                    <Typography className={cn(styles.title, { info: true })}>
                        <span onClick={() => history.push(paths.profile)}>
                            {addFriendMessage('You can add friends in profile')}
                        </span>
                    </Typography>
                    <FriendsList
                        showFriends={showFriends}
                        showPreview={showPreview}
                        isMobileFriends={isMobileFriends}
                    />
                </>
            )}
            {isMobileFriends && (
                <Box
                    className={cn(styles.returnToBox, {})}
                    onClick={() => lobbyStore.openmobileFriends(false)}
                >
                    <ArrowBackIosNewOutlinedIcon
                        sx={{
                            color: isMobileFriends ? '#F8BF30' : 'none',
                        }}
                    />
                    <p
                        className={cn(styles.returnToBox, {
                            isMobileFriends: isMobileFriends,
                        })}
                    >
                        {createTitle ? createTitle() : 'Back'}
                    </p>
                </Box>
            )}
        </Box>
    );
};

export default observer(FriendsView);