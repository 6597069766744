import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
    useLayoutEffect,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";

import { IPlayer } from "src/store/models";

import { useStyles } from "./styles";

interface IPlayerInfo {
    name: string;
    appearanceTheme: string;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    opponent?: IPlayer;
}

const NickPlayerComponent = observer((props: IPlayerInfo) => {
    const { name, appearanceTheme, isMultiTable, opponent, isTwoGameTable } =
        props;
    const [bias, setBiasValue] = useState<null | number>(null);

    const nicknameBiasCals = (
        name: string,
        divName: string,
        parName: string,
        delay: number,
        setFn: (params: null | number) => void
    ) => {
        if (!name) return;
        let timer;
        timer = setTimeout(() => {
            const offsetWidthD = document.getElementById(divName)?.clientWidth;
            //@ts-ignore
            const offsetWidthP = document.getElementById(parName)?.clientWidth;

            if (offsetWidthD && offsetWidthP && offsetWidthD < offsetWidthP) {
                return setFn(offsetWidthD - offsetWidthP);
            }
            return setFn(null);
        }, delay);
        return () => clearTimeout(timer);
    };

    useEffect(() => {
        nicknameBiasCals(
            name,
            `playerName_${name}`,
            `playerNameParagraph_${name}`,
            4000,
            setBiasValue
        );
    }, [name]);

    const classes = useStyles({ bias });

    return (
        <Box
            component="div"
            className={cn(classes.nicknameWrapper, "nickname-wrapper", {
                isMultiTable,
                isLargeNick: name.length >= 11 && !isMultiTable,
                isOpponent: opponent && !isMultiTable,
            })}
        >
            <div
                id={`playerName_${name}`}
                className={cn(classes.playerName, "player-name", {
                    isMultiTable,
                })}
            >
                <p
                    id={`playerNameParagraph_${name}`}
                    className={cn(
                        classes.playerNameParagraph,
                        appearanceTheme,
                        {
                            isMultiTable,
                            isTwoGameTable,
                            isLargeNick: name.length >= 10 && !isMultiTable,
                            isOpponent: opponent && !isMultiTable,
                            runAnimation: bias,
                        }
                    )}
                >
                    {name}
                    {/* {calcNameLength(name, currentWidth, 8)} */}
                </p>
            </div>
        </Box>
    );
});

export default NickPlayerComponent;
