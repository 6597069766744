import React from "react";
import { Modal, Box } from "@mui/material";
import { useStyles } from './styles';
import loadable from "@loadable/component";

const JackPotComponent = loadable(() => import("src/components/JackPot"), {
    fallback: <div>Loading...</div>
});

interface JackpotModalProps {
    open: boolean;
    onClose: () => void;
}

const JackpotModal: React.FC<JackpotModalProps> = ({ open, onClose }) => {
    const styles = useStyles();

    return (
        <Modal open={open} onClose={onClose}>
            <div className={styles.modalContent}>
                <div className={styles.jackpotBox}>
                    <JackPotComponent isFullAnimation={false} />
                </div>
                <button onClick={onClose}>Закрыть</button>
            </div>
        </Modal>
    );
};

export default JackpotModal;