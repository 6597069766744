import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    pageWrapper: { 
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    },
    container: {
        width: '100%',
        margin: '0 auto',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            gap: 20,
        }
    },
    newsCard: {
        display: 'flex',
        gap: 24,
        cursor: 'pointer',
        transition: 'opacity 0.2s ease',
        paddingBottom: 15, 
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', 
        '&:last-child': { 
            borderBottom: 'none',
            paddingBottom: 0
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 16,
            paddingBottom: 20, 
        }
    },
    imageWrapper: {
        width: 300,
        height: 200,
        flexShrink: 0,
        overflow: 'hidden',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 200,
        }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    descriptionText: {
        color: '#9a9a9a',
        fontSize: 14,
        lineHeight: '1.5',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    readMore: {
        fontSize: 16,
        fontWeight: 600,
        color: '#00BA6C',
        cursor: 'pointer',
        width: 'fit-content',
        transition: 'opacity 0.2s ease',
        '&:hover': {
            opacity: 0.8
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        flex: 1,
    },
    title: {
        fontFamily: 'Montserrat',
        fontSize: 24,
        fontWeight: 500,
        color: '#F5F5F5',
        margin: 0,
        lineHeight: 1.3,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        }
    },
    meta: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        color: 'rgb(156, 156, 156)',
        fontSize: 14,
        fontFamily: 'Montserrat',
    },
    date: {
        color: 'rgb(156, 156, 156)',
        fontFamily: 'Montserrat',
    },
    time: {
        color: 'rgb(156, 156, 156)',
        fontFamily: 'Montserrat',
    },
    dot: {
        color: 'rgb(156, 156, 156)',
    }
}));