import { IBaseErrorResponse } from "../auth/requestResponses";

export enum EGameChatTab {
    chat = "chat",
    move = "move",
}

export interface IGameChatTab {
    [key: string]: string;
}

export interface IStickerDetail {
    id: string;
    filename: string;
}
export interface IStickerPackList {
    stickerPackName: string;
    stickersDetails: IStickerDetail[];
}

export enum GameMessageType {
    one = 1,
    two = 2,
    three = 3,   
}

export interface ISendMessageInGame {
    gameId: string;
    message: string;
    type: GameMessageType;
    stickerId?: string; 
    pending?: boolean;
}

export interface ISuccessSentMessage {
    success: boolean;
    messageId?: string;
}

export type ISendMessageResponse = ISuccessSentMessage | IBaseErrorResponse;
