import { makeStyles } from "@mui/styles";

import theme from "src/theme";
// import '~react-gif-player/src/GifPlayer';

export const useStyles = makeStyles({
    "@global": {
        "@keyframes x2DepositLogoAnimateMobile": {
            "0%": {
                transform: "scale(1)",
            },
            "50%": {
                transform: "scale(0.9)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },
    },
    containerLobby: () => ({
        position: "relative",
        top: 0,
        width: "100%",
        height: "100vh",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        left: "50%",
        transform: "translate(-50%, 0%)",
    }),
    " *": {
        boxSizing: "border-box",
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
    logoIcon: {
        maxWidth: "30vw",
        width: "100%",
        objectFit: "contain",
        margin: "0 3vw",
        "@media (max-width: 520px)": {
            maxWidth: "30vw",
        },
        "@media (max-width: 440px)": {
            maxWidth: "27vw",
        },
    },
    jackPotComponentBox: {
        zIndex: 11,
        width: "70px",
        height: "auto",
        position: "absolute",
        top: "26%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    x2DepositLogo: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        animationName: "x2DepositLogoAnimateMobile",
        animationDelay: "0s",
        transform: "scale(1)",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "6500ms",
        cursor: "pointer",
    },

    divider: {
        width: "100%",
        height: "2px",
        background:
            "radial-gradient(circle, rgba(153,167,164,1) 0%, rgba(56,59,59,1) 90%)",
        margin: "0px 0 3px 0",
    },

    socialIcons: {
        display: "flex",
        flexDirection: "column",
    },

    footerContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#101211",
        padding: "5px 10px",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            padding: "5px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "5px 2px 2px 2px",
            minHeight: "auto",
        },
    },

    userActionLinksBox: {
        display: "flex",
        justifyContent: "space-around",
        gridGap: "5px",
        width: "100%",
        "@media (max-width: 840px)": {},
    },

    socialIconsContainer: {
        display: "flex",
        position: "relative",
    },
    socialIcon: {
        objectFit: "cover",
        width: 25,
        marginRight: 7,
    },
    main: {
        width: "100%",
        height: "100%",
    },

    backgroundContainer: {
        width: "100%",
    },

    background: {
        position: "absolute",
        width: "100%",
        height: "67%",
        objectFit: "cover",
    },
    header: {
        display: "flex",
        flexWrap: 'wrap',
        backgroundColor: "rgb(21, 23, 25)",
        width: "100%",
        zIndex: 10,
    },
    logoBalance: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // [theme.breakpoints.down('sm')]: {
        //     justifyContent: 'space-evenly'
        // }
    },

    dices: {
        display: "flex",
        width: "calc(40vw + 0px)",
        height: "100%",
        margin: "auto",
        position: "relative",
        "@media (max-width: 890px)": {
            width: "calc(40vw + 30px)",
        },
        "@media (max-width: 840px)": {
            width: "calc(40vw + 50px)",
        },
        "@media (max-width: 730px)": {
            width: "calc(40vw + 80px)",
        },
        "@media (max-width: 660px)": {
            width: "calc(40vw + 120px)",
        },
        "@media (max-width: 600px)": {
            width: "calc(40vw + 150px)",
        },
        "@media (max-width: 380px)": {
            width: "calc(40vw + 180px)",
        },
        "@media (min-height: 840px)": {
            marginTop: "calc(5% + 5px)",
        },
        "@media (min-height: 1045px) and (max-width: 450px)": {
            marginTop: "calc(5% + 30px)",
        },
        "@media (min-height: 1200px) and (max-width: 450px)": {
            marginTop: "calc(5% + 40px)",
        },
    },
    dice: {
        width: "170px",
        position: "absolute",
        top: "18%",
        left: "50%",
        transform: "translateX(-85px)",
        filter: "blur(1.5px)",
        "@media (max-width: 730px)": {
            scale: "0.8",
            top: "33%",
        },
        "@media (max-width: 500px)": {
            // top: '18%',
            top: "33%",
            left: "43%",
        },
        "@media (max-height: 690px)": {
            // top: '20%',
            top: "33%",
        },
        "@media (max-height: 660px)": {
            // top: '20%',
            top: "33%",
        },
        [theme.breakpoints.up(730)]: {
            top: "28%",
            left: "52%%",
        },
    },

    leftDice: {
        width: "160px",
        position: "absolute",
        // top: '2%',
        top: "5%",
        // right: '0',
        right: "-5%",
        filter: "blur(0.8px)",
        "@media (max-width: 730px)": {
            scale: "0.8",
        },
        "@media (max-height: 690px)": {
            top: "0%",
        },
        [theme.breakpoints.up(730)]: {
            right: "-5%",
            top: "5%",
        },
    },
    rightDice: {
        width: "125px",
        position: "absolute",
        top: "5%",
        // left: '5%',
        left: "0%",
        "@media (max-width: 730px)": {
            // top: '4%',
            top: "6%",
            left: "0%",
            scale: "0.7",
        },
        "@media (max-height: 690px)": {
            top: "2%",
        },
        [theme.breakpoints.up(730)]: {
            top: "6%",
            left: "0%",
        },
    },

    lobby: {
        position: "absolute",
        // bottom: '12%',
        bottom: "16%",
        left: 0,
        right: 0,
        "@media (max-height: 732px)": {
            // bottom: '11%',
            bottom: "17%",
        },
        "@media (min-height: 1300px) and (max-width: 1030px)": {
            bottom: "19%",
        },
        [theme.breakpoints.down("lg")]: {
            bottom: "180px",
        },
        [theme.breakpoints.down("md")]: {
            bottom: "175px",
        },
        [theme.breakpoints.down(550)]: {
            bottom: "195px",
        },
        [theme.breakpoints.down("sm")]: {
            bottom: "155px",
        },
    },

    lobbyTitle: {
        width: "100%",
        justifyContent: "center",
        color: "white",
        zIndex: 10,
        marginBottom: 0,
        "@media (max-height: 740px)": {
            marginBottom: -12,
        },
        [theme.breakpoints.down("md")]: {
            //display: 'none',
        },
    },
    firstTitle: {
        fontSize: "27px",
        fontWeight: 400,
        margin: 0,
        textShadow: "8.48px 5.299px 6.5px rgba(0,0,0,0.75)",
        "@media (max-width: 690px)": {
            fontSize: "23px",
        },
        "@media (max-width: 450px)": {
            textAlign: "center",
        },
        "@media (max-width: 410px)": {
            fontSize: "21px",
        },
    },
    chessTitle: {
        fontSize: "50px",
        fontWeight: 400,
        marginTop: -7,
        marginBottom: 0,
        textShadow: "8.48px 5.299px 28.5px rgba(0,0,0,0.75)",
    },
    joinApp: {
        color: "white",
        padding: "10px",
        fontSize: "12px",
        background:
            "linear-gradient(90deg, rgba(16,18,17,1) 1.44%, rgba(22,56,19,1) 98.62%)",
        display: "flex",
        alignItems: "center",
        "@media (max-width: 450px)": {
            width: "37%",
            fontSize: "8px",
            padding: "5px 0 5px 10px",
            right: 0,
            bottom: 20,
        },
        "@media (max-width: 410px)": {
            fontSize: "8px",
            bottom: 10,
        },
    },
    joinAppHeader: {
        display: "flex",
        color: "white",
        padding: "5px 5px 5px 0",
        fontSize: "10px",
        marginRight: 5,
    },

    phoneIcon: {
        width: 35,
    },

    advContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },

    join: {
        color: "white",
        margin: "0 0 10px 0",
        fontSize: "12px",
        textAlign: "center",
        "@media (max-width: 400px)": {
            fontSize: "8px",
        },
        "@media (max-width: 1000px)": {
            fontSize: "2vw",
        },
    },

    playButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1.2vw",
        fontWeight: 700,
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        padding: "0.7vw 0.9vw",
        color: "#11141C",
        cursor: "pointer",
        position: "absolute",
        marginBottom: "1.2vw",
        "&:hover": {
            opacity: 0.9,
        },

        [theme.breakpoints.down("lg")]: {
            fontSize: "3vw",
            padding: "3vw 0",
            width: "40%",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "3vw",
            width: "30%",
            margin: "2vw auto",
            padding: "3vw 0",
            borderRadius: "0.5vw",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "4vw",
            width: "80%",
            margin: "3vw auto",
            padding: "4vw 0",
            borderRadius: "1vw",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "4vw",
            width: "80%",
            margin: "3vw auto",
            padding: "4vw 0",
            borderRadius: "1vw",
        },
    },

    appBarElement: {
        marginLeft: "0%",
        width: "200px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
        "@media (max-width: 480px)": {
            width: "auto",
        },
        "@media (max-width: 330px)": {
            width: "auto",
        },
    },
    avatarWrapper: {
        position: "relative",
        display: "flex",
        padding: "10px",
        zIndex: 10,
        "@media (max-width: 330px)": {
            width: "auto",
        },
    },

    appBarElementReg: {
        fontFamily: "Montserrat",
        gap: "2vw",
        maxWidth: "50%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        fontSize: "1vw",
        zIndex: 11,
    },
    appBarElementRegLang: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        "@media (max-width: 500px)": {
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: "3px",
        },
    },
    languageWrapper: {
        marginLeft: 10,
        padding: "5px 15px",
        alignSelf: "center",
        "@media (max-width: 1000px)": {
            display: "block",
        },
        "@media (max-width: 800px)": {
            marginLeft: 5,
        },
        "@media (max-width: 700px)": {
            marginLeft: 0,
            marginRight: 5,
        },
        // '@media (max-width: 500px)': {
        //     alignSelf: 'end'
        // },
        "@media (max-width: 430px)": {
            marginRight: 10,
            padding: 3,
        },
    },

    balanceLang: {
        display: "flex",
        "@media (max-width: 370px)": {
            flexDirection: "column",
        },
    },
    app: {
        color: "white",
        fontSize: 12,
        padding: "5px 10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
    },

    downloadIcon: {
        width: 24,
        "@media (max-width: 400px)": {
            width: "8vw",
        },
    },
    business: {
        display: "flex",
        alignItems: "center",
        color: "white",
        border: "2px solid #ffffff",
        borderRadius: 20,
        fontSize: 12,
        padding: 10,
        // marginLeft: 20,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            height: "inherit",
            padding: "0px 5px",
            borderRadius: 10,
            justifyContent: "space-between",
        },
        [theme.breakpoints.down(375)]: {
            justifyContent: "center",
        },
    },

    businessTitle: {
        [theme.breakpoints.down(375)]: {
            display: "none",
        },
    },

    businessIcon: {
        width: 25,
        marginRight: 10,
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
        },
    },
    badgeNotifications: {
        "&.MuiBadge-root > span": {
            zIndex: "2",
            background:
                "linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)",
        },
        "&.emptyNote": {
            "&.MuiBadge-root > span": {
                display: "none",
            },
        },
    },

    content: {
        flexGrow: "1",
        overflowY: "auto",
        scrollbarWidth: "none",
    },

    heroSection: {
        width: "100%",
        display: "flex",
        position: "relative",
        background: "#151719",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 0,
    },

    underHeroContent: {
        background: "#151719",
        padding: "2vw 0",
        gap: "2vw",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center", 
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },

    underHeroContentHalyk: {
        background: "#151719",
        padding: "4vw 0",
        gap: "2vw",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "row",
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "row",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center", 
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    underHeroVideoHalyk: {
        background: "#151719",
        gap: 24,
        display: "flex",
        justifyContent: "center",
        //margin: '0 1vw',
    },
    underSportContentHalyk: {
        background: "#151719",
        padding: "4vw 2vw",
        gap: "2vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "stretch",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    heroSectionHalyk: {
        width: "100%",
        height: "100%",
        display: 'flex',
        position: "relative",
        background: "#151719",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", 
    },

    heroVideo: {
        width: "100%",
        height: 'auto',
        objectPosition: "center center",
    },

    pockerSlots: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#2E3136",
        padding: "1.5vw",
        borderRadius: 16,
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
        position: "relative",
        width: '48%',
        [theme.breakpoints.down("sm")]: {
            width: '95%', 
            margin: '1vw auto', 
            padding: "3vw",
        },
    },
    pockerSlotsHalyk: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2E3136",
        padding: 12,
        borderRadius: 16,
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
        width: "calc(100% - 1vw)",
        [theme.breakpoints.up(1200)]: {
            width: "calc(50% - 1vw)",
            height: "60vw",
        },
        [theme.breakpoints.between(950, 1200)]: {
            width: "calc(50% - 1vw)",
            height: "38vw",
        },
        [theme.breakpoints.between(800, 950)]: {
            width: "calc(50% - 1vw)",
            height: "38vw",
        },
        [theme.breakpoints.between(700, 800)]: {
            width: "calc(50% - 1vw)",
            height: "40vw",
        },
        [theme.breakpoints.between(600, 700)]: {
            width: "calc(50% - 1vw)",
            height: "43vw",
        },
        [theme.breakpoints.between(500, 600)]: {
            width: "100%",
            height: "60vw",
        },
        [theme.breakpoints.down(500)]: {
            width: "100%",
            height: "60vw",
            margin: "1vw 0",
        },
        [theme.breakpoints.down(450)]: {
            width: "100%",
            height: "auto",
            margin: "1vw 0",
        },
    },
    imageWrapper: {
        position: "relative",
        width: "100%",
    },

    pockerImage: {
        width: "100%",
        height: "42vw",
        objectFit: "cover",
        borderRadius: 4,
        marginBottom: "1.5vw",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "30vw",
            borderRadius: "1vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            height: "27vw",
            borderRadius: "1.5vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "50vw",
            marginBottom: "4vw",
        },
    },
    pockerImageHalyk: {
        width: "100%",
        height: "50vw",
        objectFit: "cover",
        borderRadius: 8,
        marginBottom: 12,
        [theme.breakpoints.between(800, 950)]: {
            height: "29vw",
        },
        [theme.breakpoints.between(600, 800)]: {
            height: "43vw",
        },
        [theme.breakpoints.down("md")]: {
            height: "45vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "50vw",
        },
    },
    arrow: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 3vw",
        pointerEvents: "none",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            padding: "0 3vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "0 3vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "0 3vw",
        },
    },

    leftArrow: {
        cursor: "pointer",
        pointerEvents: "auto",
        [theme.breakpoints.down("lg")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("md")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "10%",
            height: "10%",
        },
    },

    rightArrow: {
        cursor: "pointer",
        pointerEvents: "auto",
        transform: "rotate(180deg)",
        [theme.breakpoints.down("lg")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("md")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "10%",
            height: "10%",
        },
    },

    button: {
        background: "#2e3136",
        color: "#00BA6C",
        padding: "0.7vw 0.9vw",
        border: "2px solid #00BA6C",
        borderRadius: 4,
        cursor: "pointer",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "24.38px",
        width: "100%",
        height: "5vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("lg")]: {
            padding: "0",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0",
            height: '8vw',
        },
        [theme.breakpoints.down("sm")]: {
            height: 48,
            marginBottom: "1vw",
        },
    },
    buttonHalyk: {
        marginTop: "auto",
        width: "100%",
        backgroundColor: "transparent",
        color: "#00BA6C",
        fontSize: 20,
        fontWeight: 700,
        padding: "0.7vw",
        borderRadius: "1vw",
        border: "2px solid #00BA6C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 15,
            padding: "2vw",
            height: '13vw',
        },
    },
    slotsContainer: {
        backgroundColor: "#151719",
        padding: "3vw 2vw",
        [`@media (min-width: 760px) and (max-width: 1440px)`]: {
            padding: "2vw",
        },
    },

    slotsTitle: {
        color: "rgba(245, 245, 245, 1)",
        fontSize: 20,
        fontFamily: "Montserrat",
        fontWeight: 700,
        marginBottom: "0",
        padding: "0.5vw 0",
        [`@media (min-width: 1024px) and (max-width: 1250px)`]: {
            fontSize: 32,
            fontWeight: 600,
        },
        [`@media (min-width: 760px) and (max-width: 1022px)`]: {
            fontSize: 25,
            fontWeight: 600,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 20,

        },
    },

    slotsGrid: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        overflowX: 'auto',
        scrollbarWidth: 'none',
        gap: "16px", 
        padding: "0", 
        "& > *:nth-child(n+33)": {
            display: "none",
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down("sm")]: {
            gap: "12px", 
            padding: "0",
        },
    },
    slotImage: {
        width: "calc(25% - 12px)", 
        height: "27vw", 
        objectFit: "inherit",
        borderRadius: "4px",
        [theme.breakpoints.down("lg")]: {
            height: "12vw",

        },
        [theme.breakpoints.down("md")]: {
            height: "12.8vw", 

        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 6px)", 
            "&:nth-child(n+9)": {
                display: 'none'
            },
            height: "27vw", 
        },
    },

    slotsHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '4vw 0',
        [`@media (min-width: 1024px) and (max-width: 1250px)`]: {
            padding: '2vw 0',
        },
    },

    viewAllButton: {
        fontSize: 16,
        fontFamily: "Montserrat",
        fontWeight: 700,
        color: "#00BA6C",
        background: "transparent",
        border: "none",
        padding: 0,
        [`@media (min-width: 1024px) and (max-width: 1250px)`]: {
            fontSize: 23,
        },
    },

    line: {
        width: "100%",
        border: "0.1vw solid rgba(255, 255, 255, 0.1)",
    },

    liveCasinoBlock: {
        backgroundColor: "#151719",
        padding: "4vw 2vw",
        [`@media (min-width: 760px) and (max-width: 1440px)`]: {
            padding: "2vw",
        },
        [theme.breakpoints.down('md')]: {
            padding: "0 2vw",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "0 2vw",
        },
    },

    newsBlock: {
        backgroundColor: "#151719",
        padding: "4vw 2vw",
        [`@media (min-width: 760px) and (max-width: 1440px)`]: {
            padding: "2vw",
        },
        [theme.breakpoints.down('md')]: {
            padding: "0 2vw",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "0 2vw",
        },
    },

    streamChannelsBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
        //marginBottom: "1.2vw",
        width: "100%",
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            marginBottom: "0",

        },
    },

    streamGrid: {
        display: "flex",
        overflowX: "auto",
        gap: "3vw",
        width: "100%",
        marginBottom: '4vw',
        scrollbarWidth: "none",  // Для Firefox
        msOverflowStyle: "none",  // Для Internet Explorer и Edge
        "&::-webkit-scrollbar": {
            display: "none",  // Для Chrome, Safari и Opera
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            gap: "2vw",
        },
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            gap: "1.5vw",

        },
    },

    streamSlots: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(245, 245, 245, 0.05)",
        padding: 8,
        borderRadius: 12,
        boxShadow: "0 0.3vw 0.8vw rgba(0, 0, 0, 0.15)",
        width: "100%",
        //height: "auto",
        position: "relative",
    },
    bottomBlock: {
        marginTop: 'auto',
    },
    streamButtons: {
        display: "flex",
        gap: "8px",
        justifyContent: 'space-around',
    },
    streamButton: {
        width: "100%",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #00BA6C",
        borderRadius: "4px",
        transition: "background-color 0.3s",
        "&:hover": {
            backgroundColor: "#3A3C3E",
        },
    },

    streamIcon: {
        width: 18,
        height: 18,
        objectFit: "contain",
    },

    streamHeader: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },

    streamTitle: {
        color: "rgba(245, 245, 245, 1)",
        fontSize: 20,
        fontFamily: "Montserrat",
        fontWeight: "700",
        margin: "3vw 0",
        [`@media (min-width: 1024px) and (max-width: 1250px)`]: {
            fontSize: 32,
            fontWeight: 600,
            margin: "2vw 0",
        },
        [`@media (min-width: 760px) and (max-width: 1022px)`]: {
            fontSize: 25,
            fontWeight: 600,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 20,

        },
    },

    streamItem: {
        flex: "0 0 calc(16.666% - 0.833vw)",
        maxWidth: "calc(16.666% - 0.833vw)",
        marginBottom: "1vw",
    },

    streamImage: {
        width: "30vw",
        height: "15vw",
        objectFit: "cover",
        borderRadius: 8,
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: "18vw",
            height: "10vw",
        },
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            width: "13vw",
            height: "10vw",
        },
    },

    streamInfo: {
        marginTop: "1vw",
        height: "40%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "2vw",
        width: "100%",
        marginTop: "auto",
    },

    disabledButton: {
        backgroundColor: "#808080",
        cursor: "not-allowed",
    },

    streamName: {
        color: "#F5F5F5",
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginTop: "4vw",
        [`@media (min-width: 1024px) and (max-width: 1250px)`]: {
            fontSize: 32,
        },
        [`@media (min-width: 760px) and (max-width: 1022px)`]: {
            fontSize: 15,
            marginTop: '2vw',
        },
        [`@media (min-width: 300px) and (max-width: 380px)`]: {
            fontSize: 14,
        },
    },

    streamStatus: {
        fontSize: 12,
        fontFamily: "Montserrat",
        display: "flex",
        alignItems: "center",
        margin: "2vw 0",
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            margin: "2vw 0 1vw 0",

        },
    },

    online: {
        color: "#00BA6C",
        "&::before": {
            content: '""',
            display: "inline-block",
            width: "1.5vw",
            height: "1.5vw",
            backgroundColor: "#00BA6C",
            borderRadius: "50%",
            marginRight: "1vw",
            [`@media (min-width: 760px) and (max-width: 1024px)`]: {
                width: "1vw",
                height: "1vw",
            },
        },
    },

    offline: {
        color: "#808080",
        "&::before": {
            content: '""',
            display: "inline-block",
            width: "1.5vw",
            height: "1.5vw",
            backgroundColor: "#808080",
            borderRadius: "50%",
            marginRight: "1vw",
            [`@media (min-width: 760px) and (max-width: 1024px)`]: {
                width: "1vw",
                height: "1vw",
            },
        },
    },

    menuFooter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
    },

    iconStyle: {
        width: "2.5vw",
        height: "2.5vw",
        [theme.breakpoints.between("sm", "md")]: {
            width: "2vw",
            height: "2vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "2vw",
            height: "2vw",
        },
    },

    //Для блока спорт для халика 
    halykSportEventDashboard: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        backgroundColor: "rgba(245, 245, 245, 0.05)",
        padding: "1.5vw",
        borderRadius: 5,
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
        color: "white",
        flexGrow: 1,
        marginBottom: 12,
        [theme.breakpoints.down("md")]: {
            padding: "2vw",

        },
    },
    eventHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1.2vw 0",
        flexDirection: 'column',
    },


    eventDate: {
        fontSize: '14px',
        color: '#ffffff',
        opacity: '0.8',
        marginTop: '4px',
        fontWeight: 600,
    },

    eventIcon: {
        width: "3vw",
        height: "3vw",
        backgroundColor: "white",
        borderRadius: "0.3vw",
        margin: '0 1vw',
        [theme.breakpoints.down("lg")]: {
            width: "2vw",
            height: "2vw",

        },
    },
    eventName: {
        fontSize: 10,
        fontWeight: 500,
    },
    participants: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.2vw",
        [theme.breakpoints.down("lg")]: {
            marginTop: "3vw",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "0",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "5vw",

        },
    },
    participant: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: '0 2vw',
    },
    participantAvatar: {
        width: "8vw",
        height: "8vw",
        backgroundColor: "white",
        borderRadius: "50%",
        marginBottom: "0.6vw",
        padding: '1vw',
        [theme.breakpoints.down("md")]: {
            width: "7vw",
            height: "7vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "13vw",
            height: "13vw",
        },
    },
    participantName: {
        fontSize: 10,
        marginBottom: "0.3vw",
        textAlign: 'center',
    },
    participantPercentage: {
        fontSize: 10,
        fontWeight: 700,
    },
    versusContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: '0 4vw 0 4vw'
    },
    versusLine: {
        flex: 1,
        height: '0.15vw',
        backgroundColor: "rgba(32, 33, 45, 1)",
    },
    versus: {
        fontSize: 12,
        fontWeight: 700,
        color: "#FFFFFF",
        padding: "0 2vw",
        zIndex: 1,
    },

    odds: {
        display: "flex",
        justifyContent: "center",
        marginTop: "7vw",
        [theme.breakpoints.down("xl")]: {
            marginTop: "5vw",

        },
        [theme.breakpoints.down("md")]: {
            marginTop: "5vw",

        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "11vw",

        },
    },
    odd: {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "1vw",
        borderRadius: 8,
        margin: '0 2vw',
        border: '2px solid rgba(212, 216, 24, 0.6)',
        [theme.breakpoints.down("md")]: {
            marginTop: "1vw",
            margin: '0 0.5vw',
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1vw 2vw",

        },
    },
    oddNumber: {
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
        fontWeight: 700,
        marginRight: "5vw",
        fontSize: 12,
    },
    oddValue: {
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 12,
    },
    sportButton: {
        backgroundColor: "#00BA6C",
        color: "black",
        fontWeight: 700,
        padding: "0.7vw",
        borderRadius: "0.5vw",
        width: "100%",
        border: "none",
        cursor: "pointer",
        fontSize: "1vw",
        "&:hover": {
            opacity: 0.9,
        },
    },
    participantContainer: {
        width: '6vw',
        height: '6vw',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0', /* Фоновый цвет на случай, если изображение не загрузится */
        [theme.breakpoints.down("md")]: {
            width: "7vw",
            height: "7vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "13vw",
            height: "13vw",
        },
    },
    avatarImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
});
