import React, { useState } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';

import endpoints from 'src/core/endpoints';
import avatarPlaceholder from 'src/assets/images/defaultPlaceholder.png';
import LazyImages from 'src/components/lazyImages';

import { useStyles } from './styles';

interface IItemAvatar {
    item: {
        _id: string;
        url: string;
        pathCompressed?: string; 
    };
    isSelected: boolean;
    onSelect: (id: string) => void;
}

const ItemAvatar: React.FC<IItemAvatar> = ({ item, isSelected, onSelect }) => {
    const localStyles = useStyles();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleSelect = () => {
        onSelect(item._id);
    };

    return (
        <Box
            className={localStyles.gridAvatarItem}
            id={item._id}
        >
            <LazyImages
                className={cn(localStyles.gridAvatarImg, {
                    [localStyles.selectedAvatarImg]: isSelected,
                })}
                placeholderImg={
                    item?.pathCompressed
                        ? `${endpoints.avatarPath}${item.pathCompressed}`
                        : avatarPlaceholder
                }
                src={`${endpoints.avatarPath}${item.url}`}
                errorImg={avatarPlaceholder}
                id={item._id}
                onClick={handleSelect}
                setLoaded={setIsImageLoaded}
            />
        </Box>

    );
};



export default ItemAvatar;
