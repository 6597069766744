import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getNews } from 'src/service/api/news';
import { INewsItem } from 'src/service/api/news/requestResponses';

import moment from 'moment';
import { fileHelper } from '../../shared/helpers/FileHelper';
import { useStyles } from './styles';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import { useLocale } from '../../providers/LocaleProvider';

const NewsPage = observer(() => {
    const classes = useStyles();
    const history = useHistory();
    const [news, setNews] = useState<INewsItem[]>([]);
    const [loading, setLoading] = useState(true);
    const { authStore } = useStores();
    const {
        mainLanding: {
            readBtn,
        },
    } = useLocale();
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const userLanguage = authStore.getLanguage() || 'en';
                const response = await getNews({
                    language: userLanguage,
                    limit: 10,
                    offset: 0
                });
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [authStore.getLanguage()]);

    const handleReadMore = (e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        history.push(`/news/${id}`);
    };

    const stripHTMLTags = (html: string): string => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    const MAX_TEXT_LENGTH = 200;

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.pageWrapper}>
            <div className={classes.container}>
                {news.map((item) => {
                    const plainText = stripHTMLTags(item.text);
                    const truncatedText =
                        plainText.length > MAX_TEXT_LENGTH
                            ? `${plainText.substring(0, MAX_TEXT_LENGTH)}...`
                            : plainText;

                    return (
                        <div
                            key={item.id}
                            className={classes.newsCard}
                            onClick={() => history.push(`/news/${item.id}`)}
                        >
                            <div className={classes.imageWrapper}>
                                <img
                                    src={fileHelper.getMediaFileURL(item.imageURL) || ""}
                                    alt={item.title}
                                    className={classes.image}
                                />
                            </div>
                            <div className={classes.content}>
                                <div className={classes.contentInfo}>
                                    <h2 className={classes.title}>{item.title}</h2>
                                    <p className={classes.descriptionText}>
                                        {truncatedText}
                                    </p>
                                </div>
                                <div>
                                    <span
                                        className={classes.readMore}
                                        onClick={(e) => handleReadMore(e, item.id)}
                                    >
                                        {readBtn('Read more')}
                                    </span>
                                </div>
                                <div className={classes.meta}>
                                    <span className={classes.date}>
                                        {moment(item.createdAt).format('DD.MM.YYYY')}
                                    </span>
                                    <span className={classes.dot}>•</span>
                                    <span className={classes.time}>
                                        {moment(item.createdAt).format('HH:mm')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default NewsPage;