import React, { useState, useMemo, useCallback } from "react";
import cn from "classnames";
import { errorService } from "src/service/services";
import { animated, useTransition, config, useSpring } from "@react-spring/web";
import OutsideClickHandler from "react-outside-click-handler";
import { useModal } from "src/pages/landing/utils/context";
import { useHistory } from "react-router-dom";

import { Dialog, Grid, Box } from "@mui/material";
import _get from "lodash/get";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import { bets, freeBets, matches } from "src/store/gameStore/constants/bets";
import useWindowSize from "src/hooks/useWindowSize";
import paths from "src/consts/paths";
import {
    GameTypeNew,
    GameStatusLobbyFilter,
    GameTime,
    GameTimeValues,
} from "src/store/lobbyStore";
import {
    GameModeCreate,
    ICreateGameRequestNew,
} from "src/service/api/lobby/requestResponses";
import { GameCreateFen, UserBalanceType } from "src/store/models";

import { usePlayer } from "src/sounds/use-player";
import matchIcon from "src/assets/icons/matchIcon.svg";
import coinIcon from "src/assets/icons/coin.svg";
import freeIcon from "src/assets/icons/freeCoin.svg";

import { Button } from "src/shared/ui/Button";
import filterFilledIcon from "src/assets/icons/filterFilledIcon.svg";
import lightningIcon from "src/assets/icons/lightning.svg";
import rocketIcon from "src/assets/icons/rocket.svg";
import rabbitIcon from "src/assets/icons/rabbit.svg";
import snailIcon from "src/assets/icons/snail.svg";
import useStores from "src/hooks/useStores";

import { useStyles } from "./styles";

import { MIN_XL_TABLET } from "src/pages/game-new2/constants";

export enum ETypeFilter {
    creator = "creator",
    filter = "filter",
}

const MainLobbyFilter = () => {
    const { lobbyStore, authStore } = useStores();
    const [matchTogler, setMatchToggle] = useState<boolean>(false);
    const player = usePlayer();
    const { openModal } = useModal();
    const isOpen = lobbyStore.filterToggler.status;
    const typeFilter = lobbyStore.filterToggler.filterType;
    const isAuthorized = authStore.isAuthorized;
    const bonus = authStore?.currentUser?.balance.bonus;
    const play = authStore?.currentUser?.balance.play;
    const free = authStore?.currentUser?.balance.coins;
    const filters = lobbyStore.filters;
    const { width } = useWindowSize();

    const isFreePlay = document.location.href.indexOf("lobby-free-play") > 0;

    const {
        lobby: {
            createGame: {
                buttons: {
                    create,
                    filter,
                    resetFilter,
                    applyFilter,
                    min,
                    potLimit,
                    classic,
                    match,
                },
            },
        },
    } = useLocale();

    const history = useHistory();
    const classes = useStyles();

    // В MainLobbyFilter

    const handleCreateGame = useCallback(async () => {
        if (!isAuthorized) return openModal("login");
        if (isAuthorized  && (!play && !isFreePlay && !bonus || isFreePlay && !free)) {
            return history.push(paths.cashbox);
        }
        const baseGameParams: ICreateGameRequestNew = {
            bank: lobbyStore.betConfig.value,
            fenType: lobbyStore.gameModeConfig.value,
            freeGame: lobbyStore.lobbyMode === UserBalanceType.play ? 1 : 2,
            gameType: isFreePlay
                ? GameModeCreate.Free
                : lobbyStore.matchConfig.isMatchMode,
            gameCount: lobbyStore.matchConfig.value,
            time: lobbyStore.timeConfig.value,
            sendTo: lobbyStore.selectedFriend ?? undefined,
        };

        try {
            if (lobbyStore.selectedFriend) {
                await lobbyStore.createGameNew({
                    ...baseGameParams,
                    sendTo: lobbyStore.selectedFriend,
                });
            } else {
                // Создание обычной игры
                console.log('Creating regular game...');
                await lobbyStore.createGameNew({
                    ...baseGameParams,
                    // Не передаем sendTo для обычной игры
                });
            }

            lobbyStore.toggleFilter(false, typeFilter);

        } catch (e) {
            console.error('Error creating game:', e);
            errorService.sendError(e.response?.data?.message || "Create game error");
        }
    }, [
        lobbyStore.betConfig.value,
        lobbyStore.gameModeConfig.value,
        lobbyStore.lobbyMode,
        lobbyStore.matchConfig.isMatchMode,
        lobbyStore.matchConfig.value,
        lobbyStore.timeConfig.value,
        lobbyStore.selectedFriend,
        isAuthorized,
    ]);
    //

    const toggleBet = (bet: number) => {
        if (typeFilter === ETypeFilter.filter) {
            return lobbyStore.setGameFilterBet({
                [bet]: !filters.bet[bet],
            });
        }
        return lobbyStore.setBetConfigValue(bet);
    };
    const outLinedBetType = (bet: number) => {
        if (typeFilter === ETypeFilter.filter) {
            return !lobbyStore.filters.bet[bet];
        }
        return lobbyStore._betConfig.values[lobbyStore.lobbyMode] !== bet;
    };

    const toggleTime = (time) => {
        if (typeFilter === ETypeFilter.filter) {
            return lobbyStore.setGameFilterTime({
                [time]: !filters.time[time],
            });
        }
        lobbyStore.setTimeConfigValue(time);
    };
    const outLinedTimeType = (time) => {
        if (typeFilter === ETypeFilter.filter) {
            return !lobbyStore.filters.time[time];
        }
        return lobbyStore.timeConfig.value !== time;
    };

    const toggleStatus = (status: GameStatusLobbyFilter) => {
        if (typeFilter === ETypeFilter.filter) {
            return lobbyStore.setGameFilterStatus({
                [status]: !filters.status[status],
            });
        }
        const stat = status === GameStatusLobbyFilter.proLimit ? 1 : 2;
        lobbyStore.setMatchConfigValue(stat);
    };
    const outLinedLimitType = (limit: GameStatusLobbyFilter) => {
        if (typeFilter === ETypeFilter.filter) {
            return !lobbyStore.filters.status[limit];
        }
        const stat = limit === GameStatusLobbyFilter.proLimit ? 1 : 2;
        return lobbyStore.matchConfig.isMatchMode !== stat;
    };

    const toggleMatchesCount = (matches: number) => {
        if (typeFilter === ETypeFilter.filter) {
            lobbyStore.setGameFilterMatches({
                [matches]: !filters.matches[matches],
            });
        }
        return lobbyStore.setMatchConfigValue(matches);
    };
    const outLinedMatchType = (match) => {
        if (typeFilter === ETypeFilter.filter) {
            return !lobbyStore.filters.matches[match];
        }
        return lobbyStore.matchConfig.value !== match;
    };

    const matchesData = () => {
        if (!matches) {
            const matchesArr = [];
            for (let i = 0; i < 10; i++) {
                //@ts-ignore
                matchesArr.push({
                    value: i,
                    icon: matchIcon,
                });
            }
            return matchesArr;
        }
        const matchesData = matches.map((match, index) => {
            return {
                value: match,
                icon: matchIcon,
            };
        });
        return matchesData;
    };

    const toggleType = (type: GameTypeNew) => {
        if (typeFilter === ETypeFilter.creator) {
            const typeUpd =
                type === GameTypeNew.classic
                    ? GameCreateFen.classic
                    : GameCreateFen.nineHundredSixty;
            lobbyStore.setGameModeConfigValue(typeUpd);
        }
        lobbyStore.setGameFilterType({
            [type]: !filters.type[type],
        });
    };
    const outLinedGameType = (amount: GameTypeNew) => {
        const typeUpd =
            amount === GameTypeNew.classic
                ? GameCreateFen.classic
                : GameCreateFen.nineHundredSixty;
        if (typeFilter === ETypeFilter.filter) {
            return !lobbyStore.filters.type[amount];
        }
        return lobbyStore.gameModeConfig.value !== typeUpd;
    };

    const gameDurations = useMemo(() => {
        return [
            {
                value:
                    typeFilter === ETypeFilter.filter
                        ? GameTime["3m"]
                        : GameTimeValues["3m"],
                label: `3 ${min("min")}`,
                icon: rocketIcon,
            },
            {
                value:
                    typeFilter === ETypeFilter.filter
                        ? GameTime["5m"]
                        : GameTimeValues["5m"],
                label: `5 ${min("min")}`,
                icon: lightningIcon,
            },
            {
                value:
                    typeFilter === ETypeFilter.filter
                        ? GameTime["10m"]
                        : GameTimeValues["10m"],
                label: `10 ${min("min")}`,
                icon: rabbitIcon,
            },
            {
                value:
                    typeFilter === ETypeFilter.filter
                        ? GameTime["20m"]
                        : GameTimeValues["20m"],
                label: `20 ${min("min")}`,
                icon: snailIcon,
            },
        ];
    }, [typeFilter]);

    const AnimatedGrid = animated(Grid);
    const visibleMatches = useMemo(
        () => (matchTogler ? matchesData() : []),
        [matchTogler]
    );

    const getHeight = useMemo(() => {
        if (matchTogler) {
            return typeFilter === ETypeFilter.filter && width > MIN_XL_TABLET
                ? "20%"
                : "20%";
        }
        return "0px";
    }, [matchTogler, typeFilter]);

    const matchTransitions = useTransition(visibleMatches, {
        keys: (item) => item.value,
        from: { opacity: 0, transform: "translateY(-10px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: { opacity: 0, transform: "translateY(-10px)" },
        trail: 25,
        config: config.gentle,
    });
    const containerSpring = useSpring({
        from: { opacity: 0, height: "0px" },
        to: {
            opacity: matchTogler ? 1 : 0,
            height: getHeight,
            // overflow: "hidden",
        },
        config: { duration: 200 },
    });

    return (
        <>
            <Dialog
                open={isOpen}
                className={cn(classes.mainLobbyFilterContainer, typeFilter)}
                onClose={() => lobbyStore.toggleFilter(!isOpen, typeFilter)}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <OutsideClickHandler
                    display={"contents"}
                    onOutsideClick={() => {
                        // lobbyStore.toggleFilter(!isOpen, typeFilter);
                    }}
                >
                    <Box className={cn(classes.topTitleBox, typeFilter)}>
                        <div className={cn(classes.filterBox, typeFilter)}>
                            <>
                                {typeFilter === ETypeFilter.filter ? (
                                    <img
                                        src={filterFilledIcon}
                                        alt="filter-icon"
                                        loading="lazy"
                                    />
                                ) : null}
                            </>
                            <p>
                                {typeFilter === ETypeFilter.filter
                                    ? filter("Filter")
                                    : null}
                            </p>
                        </div>
                        <div
                            className={cn(classes.cross, typeFilter)}
                            onClick={() => {
                                lobbyStore.toggleFilter(false, typeFilter);
                            }}
                        />
                    </Box>
                    <Box
                        className={cn(
                            classes.boxGridWrapper,
                            isFreePlay ? "isFreePlay" : null,
                            typeFilter
                        )}
                    >
                        {
                            <Grid
                                container
                                spacing={1}
                                className={cn(
                                    classes.gridContainer,
                                    typeFilter,
                                    "amount"
                                )}
                            >
                                {(isFreePlay ? freeBets : bets).map(
                                    (amount) => (
                                        <Grid item xs={3} md={3} key={amount}>
                                            <Box
                                                component="div"
                                                className={cn(
                                                    classes.filterCell,
                                                    "amount",
                                                    {
                                                        outlined:
                                                            outLinedBetType(
                                                                amount
                                                            ),
                                                    }
                                                )}
                                                onClick={() => {
                                                    toggleBet(amount);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        isFreePlay
                                                            ? freeIcon
                                                            : coinIcon
                                                    }
                                                    alt="coin"
                                                    loading="lazy"
                                                    className={cn(
                                                        classes.coinIcon,
                                                        "amount"
                                                    )}
                                                />
                                                <p
                                                    className={cn(
                                                        classes.textArea,
                                                        typeFilter,
                                                        "amount",
                                                        {
                                                            contented:
                                                                !outLinedBetType(
                                                                    amount
                                                                ),
                                                            outlined:
                                                                outLinedBetType(
                                                                    amount
                                                                ),
                                                        }
                                                    )}
                                                >
                                                    {amount}
                                                </p>
                                            </Box>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        }
                        {!isFreePlay && (
                            <span
                                className={cn(classes.dividerSpan, typeFilter)}
                            ></span>
                        )}
                        <Grid
                            container
                            spacing={1}
                            className={cn(
                                classes.gridContainer,
                                typeFilter,
                                "duration"
                            )}
                        >
                            {gameDurations.map((duration) => (
                                <Grid item xs={6} md={6} key={duration.label}>
                                    <Box
                                        component="div"
                                        className={cn(
                                            classes.filterCell,
                                            "duration",
                                            {
                                                outlined: outLinedTimeType(
                                                    duration.value
                                                ),
                                            }
                                        )}
                                        onClick={() => {
                                            toggleTime(duration.value);
                                        }}
                                    >
                                        <img
                                            src={duration.icon}
                                            alt={duration.icon}
                                            loading="lazy"
                                            className={cn(
                                                classes.coinIcon,
                                                "duration",
                                                duration.icon
                                            )}
                                        />
                                        <p
                                            className={cn(
                                                classes.textArea,
                                                "duration",
                                                {
                                                    contented:
                                                        lobbyStore.filters.time[
                                                            duration.value
                                                        ],
                                                    outlined: outLinedTimeType(
                                                        duration.value
                                                    ),
                                                }
                                            )}
                                        >
                                            {duration.label}
                                        </p>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {!isFreePlay && (
                            <span
                                className={cn(classes.dividerSpan, typeFilter)}
                            ></span>
                        )}
                        {!isFreePlay && (
                            <Grid
                                container
                                spacing={1}
                                className={cn(
                                    classes.gridContainer,
                                    typeFilter,
                                    "match"
                                )}
                            >
                                <Grid item xs={6}>
                                    <Box
                                        component="div"
                                        className={cn(
                                            classes.filterCell,
                                            typeFilter,
                                            "status",
                                            {
                                                outlined: outLinedLimitType(
                                                    GameStatusLobbyFilter.proLimit
                                                ),
                                            }
                                        )}
                                        onClick={() => {
                                            lobbyStore.setMatchMode(1);

                                            setMatchToggle(false);
                                            toggleStatus(
                                                GameStatusLobbyFilter.proLimit
                                            );
                                        }}
                                    >
                                        {potLimit("Pot Limit")}
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box
                                        component="div"
                                        className={cn(
                                            classes.filterCell,
                                            typeFilter,
                                            "status",
                                            {
                                                outlined: outLinedLimitType(
                                                    GameStatusLobbyFilter.match
                                                ),
                                            }
                                        )}
                                        onClick={() => {
                                            setMatchToggle(true);

                                            lobbyStore.setMatchMode(2);

                                            typeFilter ===
                                                ETypeFilter.creator &&
                                                toggleStatus(
                                                    GameStatusLobbyFilter.match
                                                );
                                        }}
                                    >
                                        {match("Match")}
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                        <AnimatedGrid
                            container
                            spacing={1}
                            className={cn(
                                classes.gridContainer,
                                typeFilter,
                                "amount"
                            )}
                            style={containerSpring}
                        >
                            {matchTransitions((style, match) => (
                                <AnimatedGrid
                                    item
                                    xs={2.4}
                                    key={match.value}
                                    style={style}
                                >
                                    <Box
                                        component="div"
                                        className={cn(
                                            classes.filterCell,
                                            typeFilter,
                                            "match",
                                            {
                                                outlined: outLinedMatchType(
                                                    match.value
                                                ),
                                            }
                                        )}
                                        onClick={() => {
                                            toggleMatchesCount(match.value);
                                        }}
                                    >
                                        <img
                                            src={match.icon}
                                            alt={match.icon}
                                            loading="lazy"
                                            className={cn(
                                                classes.coinIcon,
                                                typeFilter,
                                                match.icon
                                            )}
                                        />
                                        <p
                                            className={cn(
                                                classes.textArea,
                                                typeFilter,
                                                "match",
                                                {
                                                    contented:
                                                        !outLinedMatchType(
                                                            match.value
                                                        ),
                                                    outlined: outLinedMatchType(
                                                        match.value
                                                    ),
                                                }
                                            )}
                                        >
                                            {match.value}
                                        </p>
                                    </Box>
                                </AnimatedGrid>
                            ))}
                        </AnimatedGrid>

                        <span
                            className={cn(classes.dividerSpan, typeFilter)}
                        ></span>
                        <Grid
                            container
                            spacing={1}
                            className={cn(
                                classes.gridContainer,
                                typeFilter,
                                "type"
                            )}
                        >
                            <Grid item xs={6}>
                                <Box
                                    className={cn(
                                        classes.filterCell,
                                        typeFilter,
                                        "type",
                                        {
                                            outlined: outLinedGameType(
                                                GameTypeNew.classic
                                            ),
                                        }
                                    )}
                                    onClick={() => {
                                        toggleType(GameTypeNew.classic);
                                    }}
                                >
                                    {classic("Classic")}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="div"
                                    className={cn(
                                        classes.filterCell,
                                        typeFilter,
                                        "type",
                                        {
                                            outlined: outLinedGameType(
                                                GameTypeNew.nineHundredSixty
                                            ),
                                        }
                                    )}
                                    onClick={() => {
                                        toggleType(
                                            GameTypeNew.nineHundredSixty
                                        );
                                    }}
                                >
                                    960
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {typeFilter === ETypeFilter.filter ? (
                        <Box
                            className={cn(
                                classes.gridContainer,
                                typeFilter,
                                "buttons"
                            )}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Button
                                text={resetFilter("Reset to Default")}
                                color="green"
                                variant="outlined"
                                onClick={() => {
                                    lobbyStore.resetAllFiltres();
                                }}
                                className={cn(
                                    classes.buttonStyles,
                                    typeFilter,
                                    "resetButton"
                                )}
                            />
                            <Button
                                text={applyFilter("Apply Changes")}
                                variant="contained"
                                className={cn(
                                    classes.buttonStyles,
                                    typeFilter,
                                    "applyButton"
                                )}
                                onClick={() => {
                                    lobbyStore.filterGamesNew();
                                }}
                            />
                        </Box>
                    ) : (
                        <Box
                            className={cn(
                                classes.createGameButtonBox,
                                typeFilter
                            )}
                        >
                            <Button
                                text={create("Create")}
                                onClick={() => {
                                    handleCreateGame();
                                    lobbyStore.toggleFilter(false, typeFilter);
                                }}
                                className={cn(
                                    classes.buttonStyles,
                                    typeFilter,
                                    "createButton"
                                )}
                            />
                        </Box>
                    )}
                </OutsideClickHandler>
            </Dialog>
        </>
    );
};

export default observer(MainLobbyFilter);
