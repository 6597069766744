import React, { useEffect, useState, memo, useMemo, useCallback, useRef } from "react";
import moment from "moment";
import cn from "classnames";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import useChangeTheme from "src/hooks/useChangeTheme";

import { IGameChatBody } from "../types/IChat";
import { fileHelper } from "../../../../../shared/helpers/FileHelper/index";

import { useStyles } from "./styles";

import stickerChtr from 'src/assets/icons/stickers/cheat.gif';
import stickerCry from 'src/assets/icons/stickers/cry.gif';
import stickerGG from 'src/assets/icons/stickers/GG_02.gif';
import stickerGL from 'src/assets/icons/stickers/Luck.gif';
import stickerHeart from 'src/assets/icons/stickers/love.gif';
import stickerRainbow from 'src/assets/icons/stickers/lgbt_03.gif';
import stickerWtf from 'src/assets/icons/stickers/wtf.gif';
import stickerDance from 'src/assets/icons/stickers/dance.gif';
import stickerThin from 'src/assets/icons/stickers/thin2.gif';
import stickerZZZ from 'src/assets/icons/stickers/zzz.gif';
import stickerLoL from 'src/assets/icons/stickers/lol.gif';
import stickerClown from 'src/assets/icons/stickers/clown.gif';
import stickerHmm from 'src/assets/icons/stickers/hmm.gif';
import stickerPensn from 'src/assets/icons/stickers/pensn.gif';
import stickerEye from 'src/assets/icons/stickers/sticker_eye.gif';
import stickerYawn from 'src/assets/icons/stickers/yawn.gif';

import stickerChtrSvg from 'src/assets/icons/stickers/cheat.png';
import stickerCrySvg from 'src/assets/icons/stickers/cry.png';
import stickerGGSvg from 'src/assets/icons/stickers/gg.png';
import stickerGLSvg from 'src/assets/icons/stickers/gl.png';
import stickerHeartSvg from 'src/assets/icons/stickers/heart.png';
import stickerRainbowSvg from 'src/assets/icons/stickers/lgbt.png';
import stickerWtfSvg from 'src/assets/icons/stickers/wtf.png';
import stickerDanceSvg from 'src/assets/icons/stickers/dance.png';
import stickerThinSvg from 'src/assets/icons/stickers/thin.png';
import stickerZZZSvg from 'src/assets/icons/stickers/zzz.png';
import stickerLoLSvg from 'src/assets/icons/stickers/lol.png';
import stickerClownSvg from 'src/assets/icons/stickers/clown.png';
import stickerHmmSvg from 'src/assets/icons/stickers/hmm.png';
import stickerPensnSvg from 'src/assets/icons/stickers/pensn.png';
import stickerEyeSvg from 'src/assets/icons/stickers/sticker_eye.png';
import stickerYawnSvg from 'src/assets/icons/stickers/yawn.png';

import { ClassNameMap } from "@mui/styles";

const stickers = [
    { id: 'sticker1', gif: stickerCry, svg: stickerCrySvg },
    { id: 'sticker2', gif: stickerRainbow, svg: stickerRainbowSvg },
    { id: 'sticker3', gif: stickerHeart, svg: stickerHeartSvg },
    { id: 'sticker4', gif: stickerDance, svg: stickerDanceSvg },
    { id: 'sticker4', gif: stickerThin, svg: stickerThinSvg },
    { id: 'sticker6', gif: stickerGG, svg: stickerGGSvg },
    { id: 'sticker7', gif: stickerGL, svg: stickerGLSvg },
    { id: 'sticker8', gif: stickerWtf, svg: stickerWtfSvg },
    { id: 'sticker9', gif: stickerChtr, svg: stickerChtrSvg },
    { id: 'sticker10', gif: stickerYawn, svg: stickerYawnSvg },
    { id: 'sticker11', gif: stickerLoL, svg: stickerLoLSvg },
    { id: 'sticker12', gif: stickerClown, svg: stickerClownSvg },
    { id: 'sticker13', gif: stickerHmm, svg: stickerHmmSvg },
    { id: 'sticker14', gif: stickerPensn, svg: stickerPensnSvg },
    { id: 'sticker15', gif: stickerEye, svg: stickerEyeSvg },
    { id: 'sticker16', gif: stickerZZZ, svg: stickerZZZSvg },
];

const GIF_DURATION = 2785;


interface Sticker {
    id: string;
    gif: string;
    svg: string;
}

interface StickeredMessageProps {
    sticker: Sticker;
    classes: ClassNameMap<string>;
    appearanceTheme: string;
}

const StickeredMessage: React.FC<StickeredMessageProps> = memo(({ sticker, classes, appearanceTheme }) => {
    const [animationState, setAnimationState] = useState({
        showGif: true,
        isLoaded: false,
        playKey: Date.now()
    });
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const mountedRef = useRef(true);

    const playGifAndSwitchToSvg = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        setAnimationState(prev => ({
            ...prev,
            showGif: true,
            isLoaded: false,
            playKey: Date.now()
        }));

        timerRef.current = setTimeout(() => {
            if (mountedRef.current) {
                setAnimationState(prev => ({
                    ...prev,
                    showGif: false
                }));
            }
        }, GIF_DURATION + 210);
    }, []);

    useEffect(() => {
        playGifAndSwitchToSvg();
        return () => {
            mountedRef.current = false;
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    const handleStickerClick = useCallback(() => {
        playGifAndSwitchToSvg();
    }, [playGifAndSwitchToSvg]);

    const { showGif, isLoaded, playKey } = animationState;

    return (
        <div
            onClick={handleStickerClick}
            className={classes.stickerContainer}
        >
            {showGif ? (
                <img
                    src={`${sticker.gif}?${playKey}`}
                    alt="sticker-gif"
                    className={cn(classes.gifImage, classes.stickerImageWrapper, appearanceTheme, {
                        isLoaded: isLoaded,
                        isAnimating: true
                    })}
                    onLoad={() => setAnimationState(prev => ({ ...prev, isLoaded: true }))}
                />
            ) : (
                <img
                    src={sticker.svg}
                    alt="sticker-svg"
                    className={cn(classes.svgImage, classes.stickerImageWrapper, appearanceTheme, {
                        isLoaded: true
                    })}
                />
            )}
        </div>
    );
});

StickeredMessage.displayName = 'StickeredMessage';


export const GameChatHistoryMessage = memo(({
    children,
    time,
    position,
    getStickerMessage,
    isLastElem,
}: IGameChatBody) => {
    const classes = useStyles({ position });
    const appearanceTheme = useChangeTheme();

    const extractedSticker = useMemo(() => {
        if (typeof children === 'string') {
            const match = children.match(/\[img sticker="(.*?)"\]/);
            return match ? match[1] : undefined;
        }
        return undefined;
    }, [children]);

    const stickerToUse = getStickerMessage || extractedSticker;
    const currentSticker = useMemo(() =>
        stickers.find(s => s.id === stickerToUse || s.gif === stickerToUse),
        [stickerToUse]
    );

    const formatMessage = useCallback((text: string) => {
        if (typeof text !== 'string') return null;

        const parts = text.split(/(\[img sticker=".*?"\])/g);

        return parts.map((part, index) => {
            const stickerMatch = part.match(/\[img sticker="(.*?)"\]/);
            if (stickerMatch) {
                const stickerSrc = stickerMatch[1];
                const sticker = stickers.find(s => s.gif === stickerSrc || s.id === stickerSrc);
                if (sticker) {
                    return (
                        <StickeredMessage
                            key={`${index}-${sticker.id}`}
                            sticker={sticker}
                            classes={classes}
                            appearanceTheme={appearanceTheme}
                        />
                    );
                }
            }
            return part ? <span key={index}>{part}</span> : null;
        });
    }, [classes, appearanceTheme]);

    const isStickerMessage = typeof children === 'string' && children.includes('[img sticker=');
    const isRightAligned = position === 'right' || position === 'rtl';

    const renderContent = () => {
        if (typeof children === 'string') {
            if (currentSticker) {
                return (
                    <StickeredMessage
                        sticker={currentSticker}
                        classes={classes}
                        appearanceTheme={appearanceTheme}
                    />
                );
            }
            return formatMessage(children);
        }
        return (
            <div className={classes.historyContainer}>
                {children}
            </div>
        );
    };

    return (
        <div className={cn(classes.messageRow, appearanceTheme)}>
            <div
                className={cn(
                    classes.messageBlock,
                    appearanceTheme,
                    {
                        [classes.isSticker]: isStickerMessage || !!currentSticker,
                        isLastElem: isLastElem,
                    },
                    (isStickerMessage || !!currentSticker) ?
                        (isRightAligned ? classes.stickerRight : classes.stickerLeft)
                        : null
                )}
            >
                <div
                    className={cn(
                        position === "center"
                            ? classes.infoMessageText
                            : classes.playerMessageText,
                        appearanceTheme,
                        {
                            isSticker: isStickerMessage || !!currentSticker,
                            stickerRight: (isStickerMessage || !!currentSticker) && isRightAligned,
                            stickerLeft: (isStickerMessage || !!currentSticker) && !isRightAligned
                        }
                    )}
                >
                    {renderContent()}
                </div>
            </div>
        </div>
    );
});