import React, { useEffect } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import cx from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import deleteIcon from "src/assets/icons/close.svg";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton";
import useStores from "src/hooks/useStores";
import { errorService, infoService } from "src/service/services";
import { getButtonColor } from "src/helpers";

import { useModalStyles } from "../styles";
import PasswordInput from "../PasswordInput";
import { MainActionButton } from "../../buttons/newDesignButtons/mainActionButton";
import { URLHelper } from "../../../shared/helpers/URLHelper";

type ChangePasswordModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const useStyles = makeStyles({
    buttonBox: {
        justifySelf: "flex-start",
        marginTop: 8,
        width: '100%',
    },
    form: {
        gridRowGap: 8,
    },
    
    errorText: {
        fontSize: 14,
        fontWeight: 400,
        color: "#eb5757",
        display: "flex",
        alignItems: "center",
    },
});

type FormValuesType = {
    currentPassword: string;
    password: string;
    password2: string;
};


const getValidationSchema = (params: {
    passwordErrors: any;
    isResetMode: boolean;
}) =>
    yup.object({
        ...(params.isResetMode ? {} : {
            currentPassword: yup
                .string()
                .trim()
                .matches(
                    /^[^а-яА-Я]+$/,
                    String(params.passwordErrors.unsupportedChars('Используйте только английские буквы!'))
                )
                .min(8, String(params.passwordErrors.tooShort('Пароль должен быть больше 8 символов!')))
                .max(16, String(params.passwordErrors.tooLong('Пароль очень длинный (макс. 16 символа)!')))
                .required(String(params.passwordErrors.enter('Введите текущий пароль'))),
        }),
        password: yup
            .string()
            .trim()
            .matches(
                /^[^а-яА-Я]+$/,
                String(params.passwordErrors.unsupportedChars('Используйте только английские буквы!'))
            )
            .min(
                8,
                String(params.passwordErrors.tooShort('Пароль должен быть больше 8 символов!'))
            )
            .max(
                16,
                String(params.passwordErrors.tooLong('Пароль очень длинный (макс. 16 символа)!'))
            )
            .required(String(params.passwordErrors.enter('Введите новый пароль'))),
        password2: yup
            .string()
            .oneOf(
                [yup.ref("password")],
                String(params.passwordErrors.secondNotMatch('Пароли не совпадают!'))
            )
            .required(String(params.passwordErrors.enterSecond('Введите еще раз пароль!'))),
    });

const ChangePasswordModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void; }) => {
    const {
        profile: {
            password: {
                errors: { password, unknownError },
                messages: { passwordChangedSuccessfully },
                form: {
                    placeCurrentPassword,
                    placeNewPassword,
                    placeSecondNewPassword,
                    currentPasswordInvalid,
                },
                changeButton,
                changePassword,
            },
        },
    } = useLocale();
    const { authStore } = useStores();
    const localStyles = useStyles();
    const styles = useModalStyles();
    const appearanceTheme = useChangeTheme();

    const resetToken = URLHelper.getSearchParam("token");
    const isResetMode = Boolean(resetToken);

    const initialValues: FormValuesType = {
        currentPassword: "",
        password: "",
        password2: "",
    };

    const handleSubmit = async (values: FormValuesType, actions: FormikHelpers<FormValuesType>) => {
        actions.setSubmitting(true);

        try {
            await authStore.changePassword({
                currentPassword: values.currentPassword,
                password: values.password,
                password2: values.password2,
            });

            infoService.sendInfo(passwordChangedSuccessfully("Пароль успешно изменен"));
            onClose();
        } catch (e: any) {
            console.error('Password change error:', e);
            if (e.response?.data?.error === "user_change_password_password") {
                actions.setFieldError(
                    'currentPassword',
                    currentPasswordInvalid("Неверный текущий пароль")
                );
            } else {
                errorService.sendError(unknownError);
            }
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} className={styles.modalWrapper}>
            <Box className={cx(styles.modalNickname, appearanceTheme, "changePasswordModal")}>
                <div className={styles.modalContent}>
                    <Box className={styles.modalTopBox}>
                        <Typography className={styles.title}>
                            {isResetMode ? "Сброс пароля" : changePassword("Change Password")}
                        </Typography>
                        <img
                            onClick={onClose}
                            src={deleteIcon}
                            className={styles.closeIcon}
                            alt="close"
                        />
                    </Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={getValidationSchema({
                            passwordErrors: password,
                            isResetMode
                        })}
                        onSubmit={handleSubmit}
                    >
                        {(formikProps) => (
                            <form className={cx(styles.form, localStyles.form)} onSubmit={formikProps.handleSubmit}>
                                {!isResetMode && (
                                    <>
                                        <PasswordInput
                                            name="currentPassword"
                                            label={placeCurrentPassword("Введите текущий пароль")}
                                        />
                                        {formikProps.touched.currentPassword && formikProps.errors.currentPassword && (
                                            <div className={localStyles.errorText}>
                                                {formikProps.errors.currentPassword}
                                            </div>
                                        )}
                                    </>
                                )}
                                <PasswordInput
                                    name="password"
                                    label={placeNewPassword("Введите новый пароль")}
                                />
                                {formikProps.touched.password && formikProps.errors.password && (
                                    <div className={localStyles.errorText}>
                                        {String(formikProps.errors.password).replace(/["']/g, '')}
                                    </div>
                                )}
                                <PasswordInput
                                    name="password2"
                                    label={placeSecondNewPassword("Введите еще раз новый пароль")}
                                />
                                {formikProps.touched.password2 && formikProps.errors.password2 && (
                                    <div className={localStyles.errorText}>
                                        {formikProps.errors.password2}
                                    </div>
                                )}
                                <div className={localStyles.buttonBox}>
                                    <MainActionButton
                                        text={isResetMode ? "Сбросить пароль" : changeButton("Изменить")}
                                        loading={formikProps.isSubmitting}
                                        className={styles.changeButton}
                                        appearanceTheme="primary"
                                        buttonType="submit"
                                        disabled={!formikProps.isValid || formikProps.isSubmitting}
                                    />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;
