import { makeStyles } from "@mui/styles";
import theme from "src/theme";

export const useStyles = makeStyles({
    wrapper: {
        borderRadius: "50%",
        overflow: "hidden",
    },
    defaultWrapper: {
        width: 60,
        height: 60,
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
});
