import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import { useLocale } from 'src/providers/LocaleProvider';

import { useStyles as useModalStyles } from './styles';
import { MainActionButton } from "src/components/buttons/newDesignButtons/mainActionButton";
import { errorService } from 'src/service/services';
import CloseIcon from 'src/assets/icons/close.svg';
import uploadIcon from 'src/assets/icons/Add image.svg';
import { ShowHideUpload, } from "./avatarTypes";

type SelectFileAreaProps = {
    goToNextStep: () => void;
    handleClose: () => void;
    showUpload: (status: string) => void;
};
const useStyles = makeStyles({
    selectFileArea: {
        width: 580,
        height: 300,
        border: '2px dashed rgba(0, 186, 108, 0.3)',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '@media (max-width: 520px)': {
            width: '100%',
            height: '250px',
            marginBottom: '4vw',
        },
        '@media (max-width: 350px)': {
            width: '100%',
            height: '220px',
            marginBottom: '4vw',
        },
        '@media (max-width: 330px)': {
            width: '100%',
            height: '200px',
            marginBottom: '4vw',
        },
    },
    fileInput: {
        display: 'none',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        width: '100%',
        margin: '1vw 0',
        '@media (max-width: 520px)': {
            flexDirection: 'column',
            gap: '15px',
        },
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        margin: '1vw 0',
        padding: '0 1vw',
        '@media (max-width: 520px)': {
            margin: '2vw 0',
        },
    },
    titleFileText: {
        fontSize: 20,
        fontWeight: 500,
        color: '#F5F5F5',
        margin: 0,
        '@media (max-width: 520px)': {
            fontSize: 18,
        },
        '@media (max-width: 350px)': {
            fontSize: 16,
        },
    },
    selectFileText: {
        textAlign: 'center',
        color: '#F5F5F5',
        fontSize: 28,
        fontWeight: 700,
        lineHeight: 1.2,
        '& span.highlight': {
            color: '#00BA6C',
            fontWeight: 700,
        },
        '& .browse-text': {
            fontSize: 16,
            fontWeight: 400,
            marginTop: '12px',
        },
        '@media (max-width: 520px)': {
            fontSize: 24,
        },
        '@media (max-width: 350px)': {
            fontSize: 20,
        },
        '@media (max-width: 330px)': {
            fontSize: 18,
        },
    },
    closeButton: {
        cursor: 'pointer',
        width: 24,
        height: 24,
        '@media (max-width: 350px)': {
            width: 20,
            height: 20,
        },
    },
    uploadIcon: {
        cursor: 'pointer',
        width: 66,
        height: 66,
        '@media (max-width: 350px)': {
            width: 50,
            height: 50,
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

const SelectFileArea = ({ goToNextStep, handleClose, showUpload }: SelectFileAreaProps) => {
    const styles = useStyles();
    const [isLoading, setLoading] = useState(false);
    const {
        profile: {
            changeAvatar: { uploadTitleAvatar, uploadButton, clearBotton, unknownError, dragAndDrop, your, avatar, as, image, or, browse, computer},
            gameSettings: {
                form: { hideTitle },
            },
        },
    } = useLocale();
    const changeAvatarModalStyles = useModalStyles();
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [, , avatarHelpers] = useField('avatar');
    const [, , base64AvatarHelpers] = useField('base64Avatar');


    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        setLoading(true);
        try {
            const base64Avatar = await imageToBase64(file);
            avatarHelpers.setValue(file);
            base64AvatarHelpers.setValue(base64Avatar);
            goToNextStep();
        } catch (e) {
            errorService.sendError(unknownError('Неизвестная ошибка'));
            setLoading(false);
        }
    };

    const imageToBase64 = (file: File) => {
        return new Promise<string | ArrayBuffer | null | undefined>(
            (resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onerror = () => {
                    reject();
                };
                reader.onload = (e) => {
                    resolve(e.target?.result);
                };
            }
        );
    };

    return (
        <Box className={changeAvatarModalStyles.stepAreaWrapper} style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
            <div className={styles.titleBox}>
                <p className={styles.titleFileText}>
                    {uploadTitleAvatar('Upload Avatar')}
                </p>
                <img
                    onClick={() => showUpload(ShowHideUpload.hide)}
                    src={CloseIcon}
                    className={styles.closeButton}
                />
            </div>
            <label className={styles.selectFileArea}>
                <input
                    accept='image/png, image/gif, image/jpeg'
                    name='avatar-size-image'
                    id='avatar-size-image'
                    type='file'
                    className={styles.fileInput}
                    onChange={onChange}
                    ref={inputFileRef}
                />
                <div className={styles.contentWrapper}>
                    <img src={uploadIcon} className={styles.uploadIcon}></img>
                    <div className={styles.selectFileText}>
                        <div>{dragAndDrop('Drag & drop')} <span className="highlight">{your('your')}</span></div>
                        <div><span className="highlight">{avatar('avatar')}</span> {as('as an')} <span className="highlight">{image('image')}</span></div>
                        <div className="browse-text">
                            {or('or')} <span className="highlight">{browse('browse files')}</span> {computer('in your computer')}
                        </div>
                    </div>
                </div>
            </label>
            <Box className={styles.buttonContainer}>
                {/*<MainActionButton*/}
                {/*    appearanceTheme="primary"*/}
                {/*    className={changeAvatarModalStyles.changeButton}*/}
                {/*    text={uploadButton('Upload avatar')}*/}
                {/*    fontSize={20}*/}
                {/*    loading={isLoading}*/}

                {/*/>*/}
                {/*<MainActionButton*/}
                {/*    appearanceTheme="primary"*/}
                {/*    className={changeAvatarModalStyles.clearButton}*/}
                {/*    text={clearBotton('Clear avatar')}*/}
                {/*    fontSize={20}*/}
                {/*    loading={isLoading}*/}
                {/*    onClick={() => inputFileRef.current?.click()}*/}
                {/*/>*/}
            </Box>
        </Box>
    );
};

export default SelectFileArea;
