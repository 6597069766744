import { makeStyles } from "@mui/styles";
import theme from "../../../theme";

export const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        placeItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        "&.disabled": {
            opacity: 0.5,
            cursor: "wait",
        },
    },

    primary: {
        background: "#00BA6C",
        borderRadius: 4,
        padding: "7px 10px",
        fontSize: 20,
        fontWeight: 700,
        color: "#000",
        cursor: 'pointer',
    },

    secondary: {
        borderRadius: 55,
        border: "2px solid #00BA6C",
        background: "rgba(0, 186, 108, 0.3)",
        padding: "8px 15px",
        color: "#00BA6C",
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            padding: "10px 12px",
        },
    },

    grey: {
        borderRadius: 55,
        border: "2px solid #727272",
        background: "rgba(255, 255, 255, 0.05)",
        padding: "7px 10px",
        color: "#727272",
        fontSize: 16,
        fontWeight: 700,
        cursor: 'not-allowed',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,

        },
    },

    blue: {
      border: "2px solid rgba(131, 181, 255, 1)",
      background: "rgba(255, 255, 255, 0.05)",
      padding: "7px 10px",
      color: "rgba(131, 181, 255, 1)",
      fontSize: 16,
      fontWeight: 700,
      cursor: 'pointer',
      [theme.breakpoints.down("sm")]: {
          fontSize: 14,

      },
    },

    offline: {
        borderRadius: 55,
        border: "none",
        background: "transparent",
        padding: "5px 7px",
        color: "#727272",
        fontSize: 16,
        fontWeight: 700,
        cursor: 'not-allowed',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,

        },
    },

    close: {
        borderRadius: 55,
        border: "2px solid #727272",
        background: "transparent",
        padding: "10px",
        color: "#727272",
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,

        },
    },

    statistic: {
        borderRadius: 55,
        border: "2px solid #83B5FF",
        background: "rgba(131, 181, 255, 0.1)",
        padding: "10px",
        color: "rgba(131, 181, 255, 0.1)",
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,

        },
    },

    waiting: {
        borderRadius: 55,
        border: "2px solid #027d4a",
        background: "#00BA6C1A",
        padding: "5px 7px",
        color: "#027d4a",
        fontSize: 16,
        wordBreak: 'break-word',
        fontWeight: 700,
        cursor: 'not-allowed',
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,

        },
    },

    contained: {},

    outlined: {
        background: "none",
        borderRadius: 4,
        padding: "10px 10px",
    },

    silver: {
        border: "2px solid #F5F5F599",
        color: "#F5F5F599",
    },

    green: {
        border: "2px solid #00BA6C",
        background: "#00BA6C4D",
        color: "#00BA6C",
    },
    red: {
        border: "2px solid #FF1C29",
        background: "rgba(255, 28, 41, 0.3)",
        color: "#FF1C29",
    },

    shadow: {
        border: "2px solid #F5F5F599",
        color: "#F5F5F599",
        background: "rgba(245, 245, 245, 0.1)",
    },

    borderNone: {
        border: "none",
    },

    textSpan: {
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    textInButton: {},

    primaryDisabled: {
        opacity: "0.5",
    },
});
