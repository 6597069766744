import { useCallback, useMemo, memo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { useLocale } from "src/providers/LocaleProvider";
import { Button } from "src/shared/ui/Button";
import paths from "src/consts/paths";
import useWindowSize from "src/hooks/useWindowSize";
import FadeInOutVertical from "src/components/Animated";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";

import useStores from "src/hooks/useStores";
import useStyles from "./style";
import endpoints from "src/core/endpoints";

import { EGameRematchStatus } from "src/store/models";

export interface IToBracketFromGame {
    randomValue: number;
    winner: boolean | null;
}

const ToBracketFromGameAlert = observer(
    ({ randomValue, winner }: IToBracketFromGame) => {
        const { gameStore } = useStores();
        const { width } = useWindowSize();
        const history = useHistory();
        const styles = useStyles();
        const MAX_XL_TABLET_SIZE =
            window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH &&
            window.innerWidth >= MAX_L_TABLET;
        const isMobileSize = width < MAX_XL_TABLET;

        const {
            lobby: {
                tournaments: { bracketTitle },
            },
            tournaments: {
                alerts,
                roundEndAlerts: { lossAlerts, winAlerts },
            },
        } = useLocale();

        const toBracket = useCallback(() => {
            const tournamentId = gameStore?.currentGameNew?.tournamentId;
            history.push(`${paths.tournaments}/${tournamentId}`);
        }, [gameStore?.currentGameNew?.tournamentId]);

        const lossTitle = lossAlerts[1](
            "Неплохо играл, но соперник слишком фартовый"
        );
        const lossTitle2 = lossAlerts[2](
            "Ну пусть порадуется, следующий раз ты его вынесешь"
        );
        const winTitle = "Ты победил!";

        const calcTitle = useMemo(() => {
            if (winner === null) return;
            return winner ? winTitle : lossTitle;
        }, [winner, randomValue]);

        return (
            <FadeInOutVertical
                stylesProp={{
                    alignItems: "center",
                }}
            >
                <div className={cn(styles.alertBox, "toBracketAlert")}>
                    <Button
                        text={bracketTitle("Bracket")}
                        color="blue"
                        variant="contained"
                        onClick={() => toBracket()}
                        className={cn(styles.buttonRequest, "toBracketAlert")}
                    />
                    <p className={cn(styles.title, "toBracketAlert")}>
                        {calcTitle}
                    </p>
                </div>
            </FadeInOutVertical>
        );
    }
);

export default memo(ToBracketFromGameAlert);
